<template>
  <div class="w-100">
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="12" md="6" class="crmDialogItem__title">
        <div class="d-flex align-center">
          <v-col cols="auto">
            <span class="font-20 text-uppercase font-weight-medium d-flex align-center py-0 pl-1">
              {{ typeDialog === "add" && !activeProduct ? $t("btn.addOrder") : $t("btn.update") }}
            </span>
          </v-col>
          <v-col cols="auto" v-if="typeDialog !== 'add'" class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <VIcon
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  :color="item.type === 'out' ? 'green' : 'yellow darken-1'"
                >
                  {{ item.type === "out" ? "mdi-truck-delivery" : "mdi-alarm" }}
                </VIcon>
              </template>
              <span>{{ item.type === "out" ? "Відправлено" : "Очікує &#9785;" }}</span>
            </v-tooltip>
          </v-col>
        </div>
      </v-col>
      <div class="d-flex">
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <VBtn
                  class="btn-edit mr-2"
                  :color="item.is_call_required === '1' ? 'primary' : 'secondary lighten-5'"
                  @click="$emit('callRequiredUpdate')"
                  :disabled="item.type === 'out' || !inputDelivery.phone"
                >
                  <VIcon>
                    {{ item.is_call_required === "1" ? "mdi-phone" : "mdi-phone-off" }}
                  </VIcon>
                </VBtn>
              </div>
            </template>
            <span>
              {{ getHintText }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="auto"
          md="auto"
          class="crmDialogItem__userState"
          v-if="list_states.length && activeProduct"
        >
          <VSelect
            :items="list_states"
            item-text="name"
            item-value="uuid"
            :placeholder="$t('form.choose')"
            v-model="state"
            :hide-details="true"
            @change="setState"
            dense
            outlined
          >
            <template slot="item" slot-scope="{ item }">
              <div class="user-state">
                {{ item?.name }}
                <span :style="`background-color: ${item.color};`"></span>
              </div>
            </template>
            <template slot="selection" slot-scope="{ item }">
              <div class="user-state">
                {{ item?.name }}
                <span :style="`background-color: ${item.color};`"></span>
              </div>
            </template>
          </VSelect>
        </v-col>
      </div>
    </v-row>
    <v-row no-gutters>
      <v-col class="py-0" cols="auto" xl="auto" md="auto" sm="auto">
        <v-card-subtitle class="pt-0  d-flex" v-if="typeDialog !== 'add'">
          {{ $t("supply.supply_number") }} –
          <div v-if="loading">
            <v-progress-circular indeterminate size="10" width="1" color="primary" />
          </div>
          <div v-else class="copyTxt ml-1" @click="copyToClipboard(item.number)">
            {{ item.number }}
          </div>
        </v-card-subtitle>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";

export default {
  name: "headerDialogCrm",
  data: () => ({
    state: "",
    isCallRequired: false
  }),
  mounted() {
    this.fetchData();
  },
  computed: {
    getHintText() {
      if (this.item.is_call_required === "1" && this.inputDelivery.phone) {
        return this.$t("crm.call_center_required");
      } else if (this.item.is_call_required === "0" && this.inputDelivery.phone) {
        return this.$t("crm.call_center_not_required");
      } else {
        return this.$t("sms.errors.phone");
      }
    }
  },
  methods: {
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    fetchData() {
      this.state = this.uuid_state;
    },
    setState() {
      console.log(this.state);
      this.$emit("change", this.state);
    }
  },
  props: {
    uuid_state: {
      type: String,
      require: true
    },
    item: {
      require: true
    },
    inputDelivery: {
      require: true
    },
    typeDialog: {
      require: true
    },
    loading: {
      require: true
    },
    activeProduct: {
      require: true
    },
    list_states: {
      type: Array,
      require: true
    }
  },
  mixins: [notifications],
  watch: {
    uuid_state: "fetchData"
  }
};
</script>

<style scoped lang="scss">
.user-state {
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #eeeeee;
  }
}
</style>
