<template>
  <!-- <VDialog
    v-model="visibility"
    max-width="1068px"
    :persistent="changed"
    @click:outside="showConfirmDialog"
    content-class="cross__dialog"
    scrollable
  > -->
  <div>
    <v-card
      :loading="isLoadingCard"
      :disabled="isLoadingCard"
      :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-5'"
      elevation="0"
    >
      <div class="cross__inner" v-if="!loadingChosenProducts && !loading && !noCross">
        <VBtn
          fab
          small
          color="white"
          elevation="0"
          @click="showConfirmDialog"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <v-card-title class="pt-7">
        <v-row>
          <headerDialogCrm
            v-if="settingDialog === 'crm'"
            :uuid_state="uuid_user_state"
            :list_states="user_states"
            @change="setUserState"
            :item="item"
            :typeDialog="typeDialog"
            :loading="loading"
            :activeProduct="activeProduct"
            @callRequiredUpdate="callRequiredUpdate"
            :inputDelivery="inputDelivery"
          />
          <headerDialogSupply
            v-else-if="settingDialog === 'supply'"
            :uuid_state="Number(inputCrmItem.id_state)"
            :list_states="filteredStates"
            :typeDialog="typeDialog"
            @change="setOrderState"
            :activeTab="activeTab"
            :itemNumber="item.number"
            :itemUuid="item.uuid"
            :loading="loading"
            :is_blocked="inputCrmItem.is_blocked"
            @blocked="requestBlocked"
            @onDeleteSupply="onDeleteSupply"
            @showConfirmDialog="showConfirmDialog"
            @onReturn="visibleReturnDialog = true"
            @onCancelReturn="cancelReturn = !cancelReturn"
            :activeProduct="activeProduct"
            :tabStep="tabStep"
            :cancelReturn="cancelReturn"
            :item="item"
          />
          <v-col cols="12" class="pt-0">
            <div class="orderDialog__nav ">
              <div>
                <VBtn
                  class="btn-tabNav"
                  @click="tabStep = 0"
                  :class="{ active: tabStep === 0, 'mr-0': $vuetify.breakpoint.smAndDown }"
                >
                  <div v-if="!$vuetify.breakpoint.smAndDown">
                    {{ $t("supply.supply_settings") }}
                  </div>
                  <VIcon v-else small>mdi-cog-transfer-outline</VIcon>
                </VBtn>

                <VBtn
                  class="btn-tabNav"
                  @click="tabStep = 1"
                  :class="{ active: tabStep === 1, 'mr-0': $vuetify.breakpoint.smAndDown }"
                  :disabled="!activeProduct"
                >
                  <VIcon small :left="!$vuetify.breakpoint.smAndDown"
                    >mdi-package-variant-closed</VIcon
                  >
                  <div v-if="!$vuetify.breakpoint.smAndDown">
                    {{ $t("supply.item") }}
                  </div>
                </VBtn>
                <VBtn
                  class="btn-tabNav"
                  @click="tabStep = 2"
                  :class="{ active: tabStep === 2, 'mr-0': $vuetify.breakpoint.smAndDown }"
                  :disabled="!activeProduct"
                >
                  <VIcon small :left="!$vuetify.breakpoint.smAndDown">mdi-face-agent</VIcon>
                  <div v-if="!$vuetify.breakpoint.smAndDown">
                    {{ $t("supply.chat") }}
                  </div>
                </VBtn>
                <VBtn
                  class="btn-tabNav"
                  v-if="settingDialog === 'crm'"
                  @click="tabStep = 3"
                  :class="{ active: tabStep === 3 }"
                >
                  {{ $t("supply.info") }}
                </VBtn>
              </div>
              <v-col
                cols="auto"
                class="pa-0"
                v-if="watchDeliveryItem() && tabStep === 1 && activeTab === 0"
              >
                <VBtn
                  dense
                  plain
                  class="text-transform-none px-2"
                  style="color: green"
                  @click="onClickAddNewProducts"
                >
                  <VIcon :left="!$vuetify.breakpoint.smAndDown">mdi-plus</VIcon>
                  <div v-if="!$vuetify.breakpoint.smAndDown">
                    {{ $t("supply.add_itemBtn") }}
                  </div>
                </VBtn>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text
        class="pb-0"
        :class="$vuetify.breakpoint.smAndDown ? 'pb-0' : ''"

        :style='$vuetify.breakpoint.smAndDown  ? "max-height:250px;overflow:scroll" : "max-height:450px;overflow:scroll" '
      >
        <v-tabs-items v-model="tabStep" :touchless="true">
          <v-tab-item>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="12" md="6" class="ma-0 pa-0">
                    <v-col
                      cols="12"
                      v-if="getUser !== null && getUser.is_np_apikey && permissions.client"
                    >
                      <div class="d-flex align-center">
                        <VSelect
                          dense
                          :label="$t('form.counterparty')"
                          outlined
                          :items="listCounterparty"
                          v-model="counterparty"
                          item-text="name"
                          return-object
                          class="br-10"
                          hide-details=""
                          @change="getPhoneNovaPochta()"
                          :disabled="
                            blockedItem ||
                              !statusCreateTTN ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder
                          "
                        />
                        <v-switch
                          class="ml-2"
                          color="success"
                          hide-details
                          :disabled="blockedItem || ttnCodeStatus || isSendedCrmOrder"
                          @change="switchStatusNP()"
                          v-model="statusCreateTTN"
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" v-if="!statusCreateTTN || !$vuetify.breakpoint.smAndDown">
                      <VTextField
                        v-model="inputSender.phone"
                        outlined
                        hide-details="auto"
                        dense
                        prefix="+38"
                        v-on:keypress="onlyNumber($event)"
                        :maxLength="20"
                        :error-messages="senderPhoneErrors"
                        class="br-10 inputPhone"
                        :label="$t('form.phone_sender')"
                        @paste="noEngTextPasted($event, true)"
                        :disabled="
                          blockedItem ||
                            (statusCreateTTN && permissions.client) ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        @focusin="
                          inputSender.phone == ''
                            ? (sender.phoneMenu = false)
                            : (sender.phoneMenu = true)
                        "
                        @input="onInputSenderValue('phone', $event)"
                      />

                      <div class="px-2 position-relative" v-if="sender.items && sender.phoneMenu">
                        <VList dense class="elevation-5 search-dropdown" v-if="sender.items.length">
                          <VListItem
                            class="py-0"
                            v-for="(item, index) in sender.items"
                            :key="index"
                            @click="
                              onChangeSenderAddress('phone', item);
                              sender.phoneMenu = false;
                            "
                          >
                            <VListItemTitle class="search-menu-item">
                              {{ item.name || "--" }} {{ item.surname || "--" }}
                            </VListItemTitle>
                          </VListItem>
                        </VList>
                      </div>
                    </v-col>
                    <v-col cols="12" v-if="!statusCreateTTN || !$vuetify.breakpoint.smAndDown">
                      <VTextField
                        v-model="inputSender.name"
                        @paste="noEngTextPasted($event)"
                        hide-details="auto"
                        outlined
                        dense
                        :label="$t('form.sender_name')"
                        class="br-10"
                        :disabled="
                          blockedItem ||
                            (statusCreateTTN && permissions.client) ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                        @input="noEngText($event, false)"
                        :error-messages="senderNameErrors"
                        v-on:keypress="noEngText($event)"
                      />
                    </v-col>
                    <v-col cols="12" v-if="!statusCreateTTN || !$vuetify.breakpoint.smAndDown">
                      <VTextField
                        language="ar"
                        v-model="inputSender.surname"
                        @paste="noEngTextPasted($event)"
                        hide-details="auto"
                        outlined
                        dense
                        class="br-10"
                        :label="$t('form.sender_surname')"
                        :disabled="
                          blockedItem ||
                            (statusCreateTTN && permissions.client) ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
                        @input="noEngText($event, false)"
                        :error-messages="senderSurnameErrors"
                        v-on:keypress="noEngText($event)"
                      />
                    </v-col>
                    <v-col cols="12" class="">
                      <VTextarea
                        height="104"
                        no-resize
                        v-on:keypress="noEngTextNumber($event)"
                        @paste="noEngTextPasted($event)"
                        rows="3"
                        color="green"
                        class="br-10"
                        outlined
                        dense
                        :label="$t('form.comment')"
                        v-model="inputCrmItem.description"
                        hide-details="auto"
                        required
                        :disabled="
                          blockedItem ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        :hint="onEngText ? '' : $t('form.hint.CommentNoEngText')"
                      ></VTextarea>
                    </v-col>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-0 ma-0 pl-3">
                    <v-col cols="12">
                      <VTextField
                        v-model="inputDelivery.phone"
                        outlined
                        dense
                        class="br-10 inputPhone"
                        :label="$t('form.phone_receiver')"
                        return-masked-value
                        prefix="+38"
                        v-on:keypress="onlyNumber($event)"
                        :maxLength="20"
                        @paste="noEngTextPasted($event, true)"
                        :hide-details="!deliveryPhoneErrors.length"
                        :error-messages="deliveryPhoneErrors"
                        :disabled="
                          blockedItem ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        @focusin="delivery.phoneMenu = true"
                        @input="onInputDeliveryValue('phone', $event)"
                      >
                        <template slot="append" v-if="permissions.can_see_sms_integrations">
                          <VMenu
                            bottom
                            origin="center center"
                            transition="slide-x-transition"
                            :close-on-content-click="false"
                            :offset-x="true"
                            z-index="202"
                            v-model="smsIntegrationMenu"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <VBadge
                                color="purple lighten-1"
                                content="new"
                                :dot="$vuetify.breakpoint.smAndDown"
                              >
                                <v-tooltip bottom open-on-click v-if="!delivery.phone">
                                  <template v-slot:activator="{ on, attrs }">
                                    <VIcon
                                      v-bind="attrs"
                                      v-on="on"
                                      class="pointer-events-auto"
                                      :disabled="!delivery.phone"
                                    >
                                      mdi-phone-message-outline
                                    </VIcon>
                                  </template>
                                  <span>{{ $t("sms.errors.phone") }}</span>
                                </v-tooltip>

                                <VIcon v-else v-bind="attrs" v-on="on">
                                  mdi-phone-message-outline
                                </VIcon>
                              </VBadge>
                            </template>
                            <ns-sms-integration
                              :item="inputDelivery"
                              :ttn="inputCrmItem.ttn"
                              v-if="smsIntegrationMenu"
                            />
                          </VMenu>
                        </template>
                      </VTextField>
                      <div
                        class="px-2 position-relative"
                        v-if="delivery.items.length && delivery.phoneMenu"
                      >
                        <VList dense class="elevation-5 search-dropdown">
                          <VListItem
                            class="py-0"
                            v-for="(item, index) in delivery.items"
                            :key="index"
                            @click="
                              onChangeDeliveryAddress('phone', item);
                              delivery.phoneMenu = false;
                            "
                          >
                            <VListItemTitle class="search-menu-item">
                              {{ item.name }} {{ item.surname }}
                            </VListItemTitle>
                          </VListItem>
                        </VList>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <VTextField
                        v-model="inputDelivery.name"
                        outlined
                        dense
                        class="br-10"
                        :label="$t('form.name_receiver')"
                        hide-details="auto"
                        :error-messages="deliveryNameErrors"
                        :disabled="
                          blockedItem ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        v-on:keypress="noEngText($event)"
                        @paste="noEngTextPasted($event)"
                        :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                        @input="noEngText($event, false)"
                      />
                    </v-col>
                    <v-col cols="12">
                      <VTextField
                        v-model="inputDelivery.surname"
                        outlined
                        dense
                        class="br-10"
                        :label="$t('form.surname_receiver')"
                        hide-details="auto"
                        :error-messages="deliverySurnameErrors"
                        :disabled="
                          blockedItem ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        v-on:keypress="noEngText($event)"
                        @paste="noEngTextPasted($event)"
                        :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
                        @input="noEngText($event, false)"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-radio-group :disabled="
                            blockedItem ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder"
                            dense hide-details :label="$t('orderDialog.delivery_on')" row mandatory v-model="radioServiceType">
                        <v-radio
                          :label="$t('orderDialog.warehouse')"
                          value="WarehouseWarehouse">
                        </v-radio>
                        <v-radio
                          :label="$t('orderDialog.address')"
                          value="WarehouseDoors"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <div v-if="radioServiceType == 'WarehouseWarehouse'">
                      <v-col cols="12">
                        <VTextField
                          @focus="onFocusCities"
                          :label="$t('form.city_receiver')"
                          outlined
                          dense
                          :hide-details="!deliveryCityErrors.length"
                          :error-messages="deliveryCityErrors"
                          class="br-10"
                          v-model="inputDelivery.name_city"
                          v-if="!isFocusCity"
                          :loading="isLoadingCity"
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder ||
                              isLoadingCity
                          "
                        />
                        <VAutocomplete
                          v-else
                          :items="activeCities"
                          :loading="regionLoading"
                          outlined
                          dense
                          class="br-10"
                          :label="$t('form.city_receiver')"
                          item-text="Description"
                          item-value="Ref"
                          :search-input.sync="searchCityQuery"
                          :filter="filterCities"
                          :hide-details="!deliveryCityErrors.length"
                          :error-messages="deliveryCityErrors"
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder
                          "
                          @change="onChangeCity(inputDelivery.city, 'delivery')"
                          v-model="inputDelivery.city"
                          ref="deliveryCity"
                        />
                      </v-col>
                      <v-col>
                        <VTextField
                          @focus="onFocusCities"
                          :label="$t('form.department')"
                          outlined
                          dense
                          :hide-details="!deliveryWarehouseErrors.length"
                          :error-messages="deliveryWarehouseErrors"
                          class="br-10"
                          v-model="inputDelivery.name_warehouse"
                          v-if="!isFocusCity"
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder ||
                              isLoadingCity
                          "
                        />
                        <VAutocomplete
                          v-else
                          :items="warehouses"
                          outlined
                          dense
                          class="br-10"
                          :label="$t('form.department')"
                          no-data-text="--"
                          item-text="Description"
                          item-value="Ref"
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              !inputDelivery.city ||
                              item.id_state === '440'
                          "
                          @change="onSelectWarehouse('delivery')"
                          v-model="inputDelivery.warehouse"
                          :hide-details="!deliveryWarehouseErrors.length"
                          :error-messages="deliveryWarehouseErrors"
                        />
                      </v-col>
                    </div>
                    <div v-if="radioServiceType == 'WarehouseDoors'">
                      <v-col cols="12">
                        <VAutocomplete
                          :items="settlementsList"
                          outlined
                          dense
                          class="br-10"
                          :label="$t('form.city_receiver')"
                          item-value="Ref"
                          :item-text="'Description'"
                          @change="getStreets(false, true)"
                          no-filter
                          :search-input.sync="searchAddressCityQuery"
                          :hide-details="!deliveryAddressCityErrors.length"
                          :error-messages="deliveryAddressCityErrors"
                          :loading="cityAddressLoading"
                          v-model="inputAddress.city_address"
                          loader-height="2"
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder
                          "
                        >
                          <template v-slot:item="{ item }">
                            <v-col class="col-12 pa-0 ma-0 mt-2">
                            <v-row no-gutters class="text-body-2">
                              {{item.Description}}
                            </v-row>
                            <v-row no-gutters class="text-caption">
                              {{item.RegionsDescription}} <span v-if="item.RegionsDescription">&nbsp;-&nbsp;</span> {{item.AreaDescription}}
                            </v-row>
                            </v-col>
                          </template>
                          <template v-slot:append-item>
                            <div v-if="!loadMoreCitiesDisabled" v-observer="{nextPage:nextPageCities}" />
                          </template> 
                        </VAutocomplete>
                      </v-col>
                      <v-col cols="12">
                        <VAutocomplete
                          :items="streetsList"
                          outlined
                          dense
                          class="br-10"
                          :label="$t('orderDialog.street')"
                          :item-value="'Ref'"
                          :item-text="'Description'"
                          :no-filter="true"
                          :hide-details="!deliveryAddressStreetErrors.length"
                          :error-messages="deliveryAddressStreetErrors"
                          v-model="inputAddress.city_street"
                          :search-input.sync="searchAddressStreetQuery"
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder ||
                              !inputAddress.city_address.length
                          "
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-body-2">
                              {{item.StreetsType}} {{item.Description}}
                            </div>
                          </template>
  
                          <template v-slot:append-item>
                            <div v-if="!loadMoreStreetsDisabled" v-observer="{nextPage:nextPageStreets}" />
                          </template> 
                        </VAutocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="6">
                            <VTextField
                              :label="$t('orderDialog.house')"
                              :disabled="
                                  blockedItem ||
                                  ttnCodeStatus ||
                                  item.id_state === '440' ||
                                  isSendedCrmOrder ||
                                  !inputAddress.city_street"
                              outlined
                              v-model="inputAddress.city_house"
                              dense
                              :hide-details="!deliveryAddressHouseErrors.length"
                              :error-messages="deliveryAddressHouseErrors"
                              class="br-10"
                            ></VTextField>
                          </v-col>
                          <v-col cols="6">
                            <VTextField
                              type="number"
                              :disabled="
                                  blockedItem ||
                                  ttnCodeStatus ||
                                  item.id_state === '440' ||
                                  isSendedCrmOrder ||
                                  !inputAddress.city_house.length"
                              :label="$t('orderDialog.flat')"
                              v-model="inputAddress.city_flat"
                              outlined
                              dense
                              hide-details="auto"
                              class="br-10"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="12">
                    <div class="orderDialog__barCodeSvg" :class="{ active: ttnCodeSvg.length > 0 }">
                      <barcode :value="ttnCodeSvg" tag="svg" v-if="ttnCodeSvg.length"></barcode>
                    </div>
                  </v-col>

                  <v-col cols="12" class="d-flex align-center">
                    <VTextField
                      dense
                      outlined
                      :label="$t('form.ttn')"
                      type="number"
                      class="br-10 inputNumber-off"
                      v-model="inputCrmItem.ttn"
                      @input="offCodeSvg"
                      :error-messages="ttnErrors"
                      :hide-details="!ttnErrors.length"
                      :disabled="
                        blockedItem || ttnCodeStatus || item.id_state === '440' || isSendedCrmOrder
                      "
                    />
                    <div class="ml-1">
                      <VIcon @click="delayTtn" v-if="ttnCodeStatus" :disabled="blockedItem"
                        >mdi-close
                      </VIcon>
                      <VIcon :disabled="blockedItem" @click="onSearchByTTN" v-else
                        >mdi-magnify
                      </VIcon>
                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <VIcon
                            :disabled="blockedItem"
                            @click="generateTtnVisible = true"
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-plus
                          </VIcon>
                        </template>
                        <span>{{ $t("btn.create") }}</span>
                      </v-tooltip> -->
                    </div>
                  </v-col>

                  <v-col cols="12" class="d-flex">
                    <v-row>
                      <v-col cols="6">
                        <VSelect
                          outlined
                          dense
                          :items="payTypes"
                          item-value="value"
                          item-text="value"
                          class="br-10"
                          :label="$t('payType.label')"
                          hide-details="auto"
                          @change="onChangePayType"
                          v-model="inputCrmItem.pay_type"
                          :disabled=" ttnCodeStatus || !watchDeliveryItem()"
                        >
                          <template slot="item" slot-scope="{ item }">
                            <span class="text-truncate">
                              {{ $t(`payType.${item.value}`) }}
                            </span>
                          </template>
                          <template v-slot:selection="{ item }">
                            <span class="text-truncate">
                              {{ $t(`payType.${item.value}`) }}
                            </span>
                          </template>
                        </VSelect>
                      </v-col>
                      <v-col cols="6">
                        <VTextField
                          dense
                          outlined
                          @paste="noEngTextPasted($event)"
                          @keypress="$checkNumberFloat(inputCrmItem.cash_on_delivery, $event)"
                          hide-details
                          :label="$t('form.payment_made')"
                          min="0"
                          class="br-10"
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder || 
                              inputCrmItem.pay_type != 'CASH_ON_DELIVERY'
                          "
                          v-model="inputCrmItem.cash_on_delivery"
                        />
                      </v-col>
           
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <v-row>
                      <v-col cols="4">
                        <VTextField
                          dense
                          outlined
                          @keypress="$checkNumberFloat(inputCrmItem.cost, $event)"
                          hide-details
                          :label="$t('form.price')"
                          min="0"
                          class="br-10"
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder
                          "
                          v-model="inputCrmItem.cost"
                        />
                      </v-col>
                      <v-col cols="4">
                        <VTextField
                          dense
                          outlined
                          hide-details
                          :label="$t('form.weight')"
                          readonly
                          disabled
                          class="br-10"
                          v-model="inputCrmItem.weight"
                        />
                      </v-col>
                      <v-col cols="4">
                        <VTextField
                          dense
                          outlined
                          type="number"
                          hide-details
                          readonly
                          disabled
                          :label="$t('form.shipping_cost')"
                          v-model="inputCrmItem.delivery_price"
                          class="br-10"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider v-if="item.id_state === '440' && returnedDeliveries.length" />
            <v-slide-x-reverse-transition>
              <v-row v-if="item.id_state === '440' && returnedDeliveries.length">
                <v-col cols="12">
                  <v-data-table
                    fixed-header
                    :headers="returnedDeliveries_headers"
                    :items="returnedDeliveries"
                    item-key="uuid"
                    hide-default-footer
                    :items-per-page="returnedDeliveries.length"
                    dense
                    class="my-table supply"
                    :no-data-text="$t('table.noData')"
                  >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.btn="{ item }">
                      <VBtn
                        class="btn-edit sm"
                        color="primary"
                        dark
                        depressed
                        small
                        @click="onSupplyInButton(item)"
                      >
                        <VIcon>
                          mdi-file-document-edit-outline
                        </VIcon>
                      </VBtn>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.state_details.uk_name="{ item }">
                      <div class="d-flex align-center justify-content-start ">
                        <StatusCircle :status-name="item.id_state" :type="'supply'" class="sm" />
                        <div class="elispsis">
                          {{ item.state_details[`${currentLanguage.key}_name`] }}
                        </div>
                      </div>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.date="{ item }">
                      {{ item.time_created | createdAt(item.time_created, currentLanguage.code) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-slide-x-reverse-transition>
          </v-tab-item>
          <v-tab-item>
            <v-tabs-items v-model="activeTab" :touchless="true">
              <v-tab-item>
                <div class="orderDialog__selectedProducts">
                  <v-row no-gutters>
                    <!-- <v-row align="center" justify="start">
                        <v-col v-if="chosenProducts.length" cols="auto">
                          <VSubheader class="px-0">
                            <VIcon dense left>mdi-cube-scan</VIcon>
                            <div v-if="!$vuetify.breakpoint.smAndDown" class="mr-1">
                             {{ $t('supply.total_weight') }}
                            </div>
                            {{ metricsValue.weight_gross || 0 }}
                          </VSubheader>
                        </v-col>
                      </v-row> -->
                    <v-row
                      class="pa-0 ma-0"
                      justify="end"
                      align="center"
                      v-if="
                        blockedItem ||
                          watchDeliveryItem() ||
                          (item.id_state >= 430 && tabItemsStep === 1)
                      "
                    >
                      <v-slide-x-transition>
                        <v-col
                          cols="auto"
                          class="px-0"
                          v-if="item.id_state >= 430 && tabItemsStep === 1"
                        >
                          <VBtn
                            color="primary"
                            class="br-10 text-transform-none"
                            plain
                            @click="onClickMore"
                          >
                            <VIcon :left="!$vuetify.breakpoint.smAndDown">
                              mdi-printer
                            </VIcon>
                            <div v-if="!$vuetify.breakpoint.smAndDown">
                              {{ $t("btn.print") }}
                            </div>
                          </VBtn>
                        </v-col>
                      </v-slide-x-transition>
                    </v-row>
                  </v-row>
                  <div
                    class="orderDialog__nav pa-0"
                    v-if="item.id_state >= 430 && !permissions.client"
                  >
                    <VBtn
                      class="btn-tabNav mx-0"
                      @click="tabItemsStep = 0"
                      :class="{ active: tabItemsStep === 0 }"
                    >
                      <VIcon small :left="!$vuetify.breakpoint.smAndDown"
                        >mdi-text-box-check-outline</VIcon
                      >
                      <div v-if="!$vuetify.breakpoint.smAndDown">
                        {{ $t("supply.chosen_items") }}
                      </div>
                    </VBtn>
                    <VBtn
                      class="btn-tabNav mx-0"
                      @click="tabItemsStep = 1"
                      :class="{ active: tabItemsStep === 1 }"
                    >
                      <VIcon small :left="!$vuetify.breakpoint.smAndDown"
                        >mdi-package-variant-closed</VIcon
                      >
                      <div v-if="!$vuetify.breakpoint.smAndDown">
                        {{ $t("supply.sended_items") }}
                      </div>
                    </VBtn>
                    <VBtn
                      v-if="!permissions.client"
                      class="btn-tabNav mx-0"
                      @click="tabItemsStep = 2"
                      :class="{ active: tabItemsStep === 2 }"
                    >
                      <VIcon small :left="!$vuetify.breakpoint.smAndDown"
                        >mdi-table-merge-cells</VIcon
                      >
                      <div v-if="!$vuetify.breakpoint.smAndDown">
                        {{ $t("supply.cell") }}
                      </div>
                    </VBtn>
                  </div>
                  <v-slide-x-reverse-transition hide-on-leave>
                    <items-view
                      type="chosenProducts"
                      v-if="(tabItemsStep === 0 && !permissions.client) || item.id_state < 430"
                      :items="chosenProducts"
                      :loadingItems="loadingItems"
                      @showImg="showImg"
                      :isImgLoaded="isImgLoaded"
                      :blockedItem="blockedItem"
                      @onClickDelete="onClickDelete"
                      :watchDelivery="watchDeliveryItem()"
                      :products="products"
                      :isChangeAmount="watchDeliveryItem()"
                      @onChangeCounter="onChangeCounter"
                      @onChangePrice="onChangePrice"
                      :settingDialog="settingDialog"
                      :resetCopyItems="resetCopyItems"
                      :isAccessDeportInfo="isAccessDeportInfo"
                      :resetEditPriceTable="resetEditPriceTable"
                      :totalChosenProducts="totalChosenProducts"
                    />
                  </v-slide-x-reverse-transition>
                  <v-slide-x-transition hide-on-leave>
                    <items-view-tree-view
                      type="withDetails"
                      v-if="tabItemsStep === 1 || (item.id_state >= 430 && permissions.client)"
                      :items="completedItems"
                      :loadingItems="loadingItems"
                      @showImg="showImg"
                      :isImgLoaded="isImgLoaded"
                      :blockedItem="blockedItem"
                      :watchDelivery="watchDeliveryItem()"
                    />
                  </v-slide-x-transition>
                  <v-slide-x-transition hide-on-leave>
                    <items-view-tree-view
                      type="withDetails"
                      v-if="tabItemsStep === 2 && !permissions.client"
                      :items="itemsInCell"
                      :loadingItems="loadingItems"
                      @showImg="showImg"
                      :isImgLoaded="isImgLoaded"
                      :blockedItem="blockedItem"
                      :watchDelivery="watchDeliveryItem()"
                    />
                  </v-slide-x-transition>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="orderDialog__availableProducts">
                  <v-row class="pa-0 ma-0" justify="space-between" align="center">
                    <v-col cols="2" class="pt-0 pl-0">
                      <VBtn depressed plain small color="primary" @click="activeTab = 0">
                        <VIcon left>mdi-arrow-left</VIcon>
                        {{ $t("btn.back") }}
                      </VBtn>
                    </v-col>
                    <v-col cols="auto" class="pt-0 ">
                      <v-row>
                        <v-col cols="auto" v-if="settingDialog === 'crm'">
                          <v-checkbox
                            v-model="isAdditionSell"
                            :label="$t('crm.additional_sales')"
                            color="success"
                            hide-details
                            dense
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="auto">
                          <VTextField
                            prepend-inner-icon="mdi-magnify"
                            dense
                            :label="$t('form.search')"
                            v-model="searchString"
                            @input="onSearchProduct"
                            hide-details="auto"
                            clearable
                            ref="searchField"
                            solo
                            :loading="isLoadingSearch"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <chip-tab
                    :items="chipTabsItems"
                    @onClickChipTab="onClickChipTab"
                    :loadingChips="loadingChips || Boolean(searchString)"
                    :resetChip="Boolean(searchString) || isChipReset"
                  />

                  <VSimpleTable
                    fixed-header
                    class="my-table supply"
                    style="min-height:300px"
                    dense
                    v-if="products.length && !isloadingProducts"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="x-sm"></th>
                          <th>
                            <v-row
                              align="center"
                              justify="start"
                              class="cursor-pointer"
                              @click="nameFilterA = !nameFilterA"
                              >Назва
                              <div class="d-flex flex-column" align="center" no-gutters>
                                <VIcon dense style="line-height:0.4" :color="getColorForUp">
                                  mdi-menu-up
                                </VIcon>
                                <VIcon dense style="line-height:0.4" :color="getColorForDown">
                                  mdi-menu-down
                                </VIcon>
                              </div>
                            </v-row>
                          </th>
                          <th>{{ $t("table.bar_code") }}</th>
                          <th>{{ $t("table.option") }}</th>
                          <th>{{ $t("table.amount") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="product in products" :key="product.uuid">
                          <td class="x-sm">
                            <img
                              v-if="!product.product_details.path_img_thumb && isImgLoaded"
                              :src="require('@/assets/images/supply-item.png')"
                              class="image-placeholder"
                            />
                            <v-img
                              v-else
                              :src="product.product_details.path_img_thumb"
                              class="image "
                              @click="showImgDialog(product)"
                              v-show="isImgLoaded"
                              @load="showImg"
                            >
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    :size="20"
                                    :width="2"
                                    color="green lighten-1"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </td>
                          <td>{{ product.product_details.name }}</td>
                          <td>{{ product.product_details.bar_code }}</td>
                          <td class="lg">
                            <VSelect
                              v-if="typeof product.options_details !== 'undefined'"
                              :items="product.options_details.filter(item => item.available > 0)"
                              item-text="name"
                              item-value="uuid"
                              :placeholder="$t('supply.choose_option')"
                              v-model="checkOption"
                              hide-details="auto"
                              dense
                              outlined
                            >
                              <template slot="item" slot-scope="{ item }">
                                {{ item.name }}
                                <span style="color: #4FAE3B;padding-left: 5px;">
                                  {{ isAccessDeportInfo ? `(${item.available})` : "" }}
                                </span>
                              </template>
                              <template slot="selection" slot-scope="{ item }">
                                {{ item.name }}
                                <span style="color: #4FAE3B;padding-left: 5px;">
                                  {{ isAccessDeportInfo ? `(${item.available})` : "" }}
                                </span>
                              </template>
                            </VSelect>
                            <span v-else>
                              {{ $t("supply.available") }}
                              {{ isAccessDeportInfo ? `(${product.available})` : "" }}
                            </span>
                          </td>
                          <td>
                            <!-- <VTextField type="number" dense outlined class="br-10" hide-details min="1" :max="product.available" v-model="product.count" /> -->
                            <ns-counter
                              v-model="product.count"
                              :min="1"
                              :max="
                                settingDialog == 'supply'
                                  ? typeof product.options_details !== 'undefined'
                                    ? checkOption
                                      ? Number(optionItemAvailable)
                                      : 0
                                    : Number(product.available)
                                  : 9999
                              "
                            />
                          </td>
                          <td>
                            <VBtn
                              :disabled="
                                product.uuid === isDisabledSend(product, true).uuid &&
                                  !isDisabledSend(product, true).hasOption
                              "
                              icon
                              v-if="typeof product.options_details !== 'undefined'"
                              @click="addProductOption($event, product, 'addProduct')"
                            >
                              <VIcon>mdi-send</VIcon>
                            </VBtn>
                            <VBtn
                              :disabled="isDisabledSend(product)"
                              icon
                              v-else
                              @click="onChoseByBarCode($event, product, 'addProduct')"
                            >
                              <VIcon>mdi-send</VIcon>
                            </VBtn>
                          </td>
                        </tr>
                        <tr>
                          <div
                            v-observer="{ nextPage: nextPage }"
                            class="observer my-3"
                            v-if="!loadMoreDisabled"
                          ></div>
                        </tr>
                      </tbody>
                    </template>
                  </VSimpleTable>
                  <div v-if="isloadingNextProducts">
                    <v-progress-linear indeterminate />
                  </div>

                  <div class="text-center mt-1" v-if="!products.length && !isloadingProducts">
                    {{ $t("table.noData") }}
                  </div>

                  <div class="products-loader__wrapper" v-if="isloadingProducts">
                    <div class="products-loader__inner"></div>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      :size="50"
                      :width="5"
                    ></v-progress-circular>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>product - 3</v-tab-item>
            </v-tabs-items>
          </v-tab-item>
          <v-tab-item>
            <DeliveryChatComponent v-if="tabStep === 2" :item="item" />
          </v-tab-item>
          <v-tab-item>
            <info-dialog-crm v-if="settingDialog === 'crm'" :data="inputCrmItem"></info-dialog-crm>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions :class="{ 'pa-0 pt-1': $vuetify.breakpoint.smAndDown }">
        <VContainer :class="{ 'py-0': $vuetify.breakpoint.smAndDown }" v-if="tabStep !== 2">
          <v-row class="my-0 py-0">
            <v-col cols="12" md="4" sm="12" class="my-0 py-0 mb-2" v-if="tabStep === 0">
              <VBtn
                @click="createNovaPochtaTTN"
                dark
                block
                class="w-100 br-10 text-transform-none"
                :disabled="
                  blockedItem || !statusCreateTTN || loadingCreateTTN || ttnCodeStatus || loading
                "
              >
                <VIcon left v-if="!$vuetify.breakpoint.smAndDown"> mdi-cube-send</VIcon>
                <span v-if="!$vuetify.breakpoint.smAndDown">
                  {{ $t("btn.generate") }}
                </span>
                {{ $t("form.ttn") }}
              </VBtn>
            </v-col>
            <v-col v-if="settingDialog === 'crm'" cols="12" md="4" sm="12" class="my-0 py-0 mb-2">
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                block
                v-if="
                  tabStep === 0 && !activeProduct && (permissions.client || permissions.crm_user)
                "
                @click="onCreate"
                :loading="loading"
                :disabled="!changed"
              >
                {{ $t("btn.create") }}
              </VBtn>
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                block
                v-else-if="activeProduct"
                @click="onUpdate('save')"
                :disabled="(!changed && watchDelivery()) || isSendedCrmOrder"
              >
                {{ $t("btn.update") }}
              </VBtn>
            </v-col>

            <v-col
              v-else-if="settingDialog === 'supply' && watchDelivery()"
              cols="6"
              md="4"
              sm="6"
              class="my-0 py-0 mb-2"
            >
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                block
                v-if="
                  tabStep === 0 &&
                    !activeProduct &&
                    (this.permissions.client || permissions.crm_user)
                "
                @click="onCreate"
                :loading="loading"
              >
                {{ $t("btn.create") }}
              </VBtn>
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                block
                v-else-if="tabStep === 0 && activeProduct"
                @click="onUpdate('save')"
                :loading="loading"
                :disabled="blockedItem || item.id_state === '450' || !changed"
              >
                {{ $t("btn.update") }}
              </VBtn>
            </v-col>
            <v-col
              cols="6"
              md="4"
              sm="6"
              class="my-0 py-0 mb-2"
              v-if="tabStep !== 2 && settingDialog === 'supply'"
            >
              <VBtn
                v-if="Number(item.id_state) < 401"
                class="text-white text-transform-none br-10 w-100"
                :class="{ 'success-bg': !sendButtonDisabled }"
                block
                @click="onSend()"
                :disabled="changed || blockedItem || sendButtonDisabled"
              >
                {{ $t("btn.send") }}
                <VIcon small class="ml-2">mdi-send</VIcon>
              </VBtn>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="12"
              class="my-0 py-0 mb-2"
              v-if="tabStep !== 2 && settingDialog === 'crm'"
            >
              <VBtn
                class="text-white text-transform-none br-10 w-100 success-bg"
                block
                @click="onSend()"
                :disabled="sendButtonDisabled || !item.ready_to_deliver || !isAllowSendOrder"
              >
                {{ $t("btn.send") }}
                <VIcon small class="ml-2">mdi-send</VIcon>
              </VBtn>
            </v-col>
          </v-row>
        </VContainer>
      </v-card-actions>
    </v-card>

    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
    <ReturnSuppply
      :visible="visibleReturnDialog"
      @close="visibleReturnDialog = false"
      :uuid_parent="item.uuid"
    />

    <ConfirmDialogSlots
      :visible="confirmDialog"
      @close="confirmDialog = false"
      @onConfirm="
        onRemoveItem(chosenItem);
        confirmDialog = false;
      "
      @onCancel="confirmDialog = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.accept_deleteTitle") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.accept_deleteDesc") }}
      </template>
    </ConfirmDialogSlots>

    <ConfirmDialogSlots
      :visible="showConfirmChangeStateDialog.visible"
      @close="
        showConfirmChangeStateDialog.visible = false;
        onCancelChangeState();
      "
      @onConfirm="onConfirmChangeState"
      @onCancel="
        showConfirmChangeStateDialog.visible = false;
        onCancelChangeState();
      "
      :typeAlert="'info'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.confirmChangeStatusTitle") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.confirmChangeStatusDesc") }}
      </template>
    </ConfirmDialogSlots>

    <EditSupplyInDialog
      v-if="visibleSupplyIn"
      :visible="visibleSupplyIn"
      @close="visibleSupplyIn = false"
      :supply="SupplyInItem"
      hide-parent-item
    />
    <imgDialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
    <CallCenterDismissDialogVue
      :visible="visibleCallCenterDismissDialogVue"
      @close="closecallCenterDismiss"
      :list_states="user_states"
      :uuid_state="uuid_user_state"
      @noChange="closecallCenterDismiss"
      @changeState="setUserState"
      v-if="visibleCallCenterDismissDialogVue"
    />
    <GenerateTTHDialogVue :visible="generateTtnVisible" @close="generateTtnVisible = false" />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/crm/types/getters";
import * as mutations from "@/store/modules/novaPochta/types/mutations";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import _ from "lodash";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import ConfirmDeleteDialog from "../dialog/ConfirmDeleteDialog.vue";
import headerDialogCrm from "./components/header/headerDialogCrm.vue";
import headerDialogSupply from "./components/header/headerDialogSupply.vue";
import infoDialogCrm from "./components/tabs/infoDialogCrm.vue";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import novaPochta from "../../mixins/novaPochta";
import user from "../../mixins/user";
import {
  noEngText,
  noEngTextNumber,
  noEngTextSymbols,
  onlyNumber,
  noEngTextPasted
} from "@/mixins/helpers";
import OrderMixin from "./OrderMixin";
import crmService from "../../services/request/crm/crmService";
import supplyService from "../../services/request/supply/supplyService";
import EventBus from "../../events/EventBus";
import depotService from "../../services/request/depot/depotService";
import novaPochtaService from "../../services/request/novaPochta/novaPochtaService";
import imgDialog from "@/components/products/imgDialog.vue";
import phoneMask from "@/validators/phoneMask";
import noEngTextValidator from "@/validators/noEngText";
import DeliveryChatComponent from "../chat/DeliveryChatComponent.vue";
import NsSmsIntegration from "../sms/ns-sms-integration.vue";
import chipTab from "@/components/common/chipTab.vue";
import ReturnSuppply from "./components/dialogs/ReturnSupply.vue";
import ConfirmDialogSlots from "../dialog/ConfirmDialog.vue";
import itemsView from "./components/tabs/ItemsView.vue";
import itemsViewTreeView from "@/components/supply/dialogs/itemViewTreeView.vue";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import EditSupplyInDialog from "@/components/supply/EditSupplyInDialog.vue";
import CallCenterDismissDialogVue from "./components/dialogs/CallCenterDismissDialog.vue";
import GenerateTTHDialogVue from "../dialog/GenerateTTHDialog.vue";

// const payTypes = [null,"CASH_ON_DELIVERY","PREPAY","PAYED","REJECT"]

export default {
  name: "OrderDialog",
  data: () => ({
    totalChosenProducts: "",
    resetEditPriceTable: false,
    smsIntegrationMenu: false,
    visibleCallCenterDismissDialogVue: false,
    tabItemsStep: 0,
    optionItemAvailable: "",
    onEngText: true,
    isloadingNextProducts: false,
    loadingChips: false,
    loadingChosenProducts: false,
    isLoadingSearch: false,
    categoryChipTab: null,
    tableAvaibleProductsFilter: {},
    chipTabsItems: {},
    oldSerach: "",
    metricsValue: {},
    chosenItem: "",
    statusCreateTTN: false,
    changedStatus: false,
    loadingCreateTTN: false,
    confirmDialog: false,
    changed: false,
    activeTab: 0,
    loading: true,
    generateDialogVisible: false,
    listCounterparty: [],
    item: {},
    counterparty: {},
    counterpartyPhone: "",
    products: [],
    chosenProducts: [],
    completedItems: [],
    checkOption: "",
    uuid_user_state: "",
    searchString: "",
    activeProduct: false,
    tabStep: 0,
    ttnCodeSvg: "",
    ttnCodeStatus: false,
    inputDelivery: {
      name: "",
      surname: "",
      phone: "",
      city: "",
      name_city: "",
      warehouse: "",
      name_warehouse: "",
      warehouse_id: "",
    },
    delivery: {
      phoneMenu: false,
      items: []
    },
    inputSender: {
      name: "",
      surname: "",
      phone: ""
    },
    sender: {
      phoneMenu: false,
      items: []
    },
    inputCrmItem: {
      description: "",
      ttn: "",
      cost: null,
      uuid: "",
      cash_on_delivery: null,
      is_blocked: false,
      id_state: "400",
      delivery_price: "",
      weight: "",
      pay_type: null
    },
    visibleSupplyIn: false,
    SupplyInItem: {},
    states: [],
    filteredStates: [],
    copySender: {},
    copyDelivery: {},
    copyAddress: {},
    copyCrmItem: {},
    visibleConfirm: false,
    imgpath: [],
    imgDialog: false,
    isImgLoaded: false,
    page: 1,
    perPage: 10,
    loadMoreDisabled: false,
    isloadingProducts: true,
    nameFilterA: null,
    visibleReturnDialog: false,
    cancelReturn: false,
    generateTtnVisible: false,
    showConfirmChangeStateDialog: {
      visible: false
    },
    loadingItems: false,
    itemsInCell: [],
    returnedDeliveries: [],
    returnedDeliveries_headers: [],
    isAdditionSell: false,
    resetCopyItems: false,
    isChipReset: false,
    isFocusCity: false,
    isLoadingCity: false,
    changedSender: false,
    changedDelivery: false,
    changedCrmItem: false,
    changedAddress: false,
    radioServiceType: 'WarehouseWarehouse',
    copyRadioServiceType: '',
    searchCityQuery: '',
    cityAddressLoading: false,
    pagination:{
      page:1,
      perPage:50,
    },
    loadMoreCitiesDisabled:false,
    searchAddressCityQuery: null,
    settlementsList: [],
    streetsList: [],
    inputAddress: {
      check: true,
      city_address: "",
      city_street: "",
      city_house: "",
      city_flat: ""
    },
    paginationStreets:{
      page:1,
      perPage:50,
    },
    loadMoreStreetsDisabled:false,
    searchAddressStreetQuery: null,
    payTypes: [
      {
        value: null
      },
      {
        value: "CASH_ON_DELIVERY"
      },
      {
        value: "PREPAY"
      },
      {
        value: "PAYED"
      },
      {
        value: "REJECT"
      }
    ]
  }),
  async mounted() {
    this.getSettlements(false)
    EventBus.$on("new-ttn", data => {
      // orderDialog('ttn', data);
      this.inputCrmItem.ttn = data.IntDocNumber;
      if (this.typeDialog === "edit") {
        this.onTTN();
      }
      this.onSearchByTTN();
    });
    EventBus.$on("on-returned", () => {
      EventBus.$emit("supply-out-modified");
      this.visibility = false;
    });
    if (this.getUser?.is_np_apikey) {
      this.counterpartyList();
      this.statusCreateTTN = true;
    }

    try {
      await this.fetchData();
      if (this.settingDialog === "crm" && this.item.is_reviewed === "1" && !this.isCallCenter) {
        EventBus.$emit("crm-items-reset");
      }
    } catch (e) {
      this.setErrorNotification(e);
    }
    this.returnedDeliveries_headers = [
      {
        text: "",
        align: "center",
        sortable: false,
        value: "btn"
      },
      {
        text: "#",
        align: "center",
        sortable: false,
        value: "number"
      },
      {
        text: "ТТН",
        sortable: false,
        value: "ttn"
      },
      {
        text: this.$t("table.status"),
        sortable: false,
        value: "state_details.uk_name"
      },
      {
        text: this.$t("table.date"),
        sortable: false,
        value: "date"
      }
    ];
  },
  created() {
    window.addEventListener("keydown", this.keyDownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  methods: {
    onChangeCity(city, type){
      this.onSelectCity(city, type)
      this.searchAddressCityQuery = ''
      this.searchAddressStreetQuery = ''
      this.inputAddress.city_address = ''
      this.inputAddress.city_street = ''
      this.inputAddress.city_house = ''
      this.inputAddress.city_flat = ''
    },
    onChangePayType(e) {
      if (!e) {
        this.inputCrmItem.pay_type = null;
      }
      if (e != 'CASH_ON_DELIVERY') {
        this.inputCrmItem.cash_on_delivery = null
      }
    },
    filterCities(item, queryText, itemText) {
      let searchArray = itemText.split("(")

      if (searchArray.length == 1) {
        searchArray = itemText.split(",")
      }
      return searchArray[0].toLowerCase().includes(queryText.toLowerCase())
    },
    async onFocusCities() {
      if (this.isFocusCity) return;
      this.isLoadingCity = true;
      await this.fetchNovaPochtaCities();
      this.isFocusCity = true;
      this.activeCities = this.cities;
      this.isLoadingCity = false;
    },
    async sendMSG(message) {
      try {
        if (message) {
          this.tabStep = 2;
          const formDataMSG = new FormData();
          formDataMSG.append("uuid_object", this.inputCrmItem.uuid);
          formDataMSG.append("message", message);
          await supplyService.chatMessage(formDataMSG);
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    closecallCenterDismiss(message) {
      this.sendMSG(message);
      this.visibleCallCenterDismissDialogVue = false;
      this.item.is_call_required = "0";

      this.onChangeCallCenter();
    },
    callRequiredUpdate() {
      if ((!this.changed && !this.watchDelivery()) || this.isSendedCrmOrder) return;
      if (this.item.is_call_required === "0") {
        this.item.is_call_required = "1";
      } else {
        this.visibleCallCenterDismissDialogVue = true;
        return;
      }

      this.onChangeCallCenter();
    },
    isDisabledSend(product, isOptions = false) {
      if (this.settingDialog === "crm") return false;
      const availableNum = Number(product.available);
      const amountNum = Number(product.count);
      if (!isOptions) {
        return amountNum > availableNum;
      } else {
        const productOption = product.options_details.filter(
          item => item.uuid === this.checkOption
        );
        const result = {
          uuid: product.uuid,
          hasOption: Boolean(productOption.length)
        };
        return result;
      }
    },
    onChangeCounter: _.debounce(async function({ item, amountBeforeChange = 0 }) {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("uuid", item.uuid);
        formData.append("items_amount", item.items_amount);

        if (this.settingDialog === "crm") {
          let additionalAmount = 0;
          const diff = item.items_amount - amountBeforeChange;
          if (amountBeforeChange !== item.items_amount) {
            if (diff > 0) {
              additionalAmount = Number(item.additional_sell_amount) + diff;
            } else if (diff <= 0 || item.items_amount <= Number(item.additional_sell_amount)) {
              additionalAmount = item.items_amount;
            }
            formData.append("additional_sell_amount", additionalAmount);
          }
          await crmService.ChangeItemValue(formData);
          const res = await crmService.isReadyToDeliver(this.item.uuid);
          this.item.ready_to_deliver = res.ready_to_deliver;
          EventBus.$emit("supply-out-modified");
        } else {
          await supplyService.ChangeItemValue(formData);
        }
        this.chosenProducts = [];
        this.getChosenProducts(this.item.uuid);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(e);
      }
    }, 500),
    async onChangePrice({ item, priceBeforeChange = 0 }) {
      try {
        const formData = new FormData();
        formData.append("uuid", item.uuid);
        formData.append("price", item.item_price || "0.00");
        await crmService.ChangeItemPrice(formData);
        EventBus.$emit("supply-out-modified");
        this.chosenProducts = [];
        await this.getChosenProducts(this.item.uuid);
        this.resetEditPriceTable = !this.resetEditPriceTable;
        this.resetCopyItems = !this.resetCopyItems;
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(e);
        this.resetEditPriceTable = !this.resetEditPriceTable;
        this.resetCopyItems = !this.resetCopyItems;
      }
    },

    keyDownHandler(e) {
      if (!this.activeProduct) return;
      if (e.shiftKey && e.keyCode === 49) {
        this.tabStep = 0;
      }
      if (e.shiftKey && e.keyCode === 50) {
        this.tabStep = 1;
      }
      if (e.shiftKey && e.keyCode === 51) {
        this.tabStep = 2;
      }
    },
    onSupplyInButton(item) {
      this.SupplyInItem = item;
      this.visibleSupplyIn = true;
    },
    onClickAddNewProducts() {
      this.isAdditionSell = false;
      this.activeTab = 1;
      this.searchString = "";
    },
    async onClickChipTab(e) {
      this.categoryChipTab = e;
      this.isloadingProducts = true;
      this.loadingChips = true;
      this.tableAvaibleProductsFilter = {};
      console.log(e);

      if (e === this.$t("btn.all")) {
        if (this.searchString) {
          this.searchString = "";
        }
        await this.getProducts();
      } else {
        if (this.searchString) {
          this.searchString = "";
        }
        await this.getProducts({
          product_details__name: e,
          uselike: true
        });
      }
      this.nameFilterA = null;
      this.isloadingProducts = false;
      this.loadingChips = false;
    },
    async getChipTabsItems() {
      try {
        const params = {};
        if (!this.permissions.client) {
          params.uuid_user = this.item.uuid_user;
        }
        let response = null;
        if (this.settingDialog === "crm") {
          response = await crmService.getItemsChipList(this.uuid_board);
        } else {
          response = await depotService.getItemsChipList(params);
        }
        const chipItems = response;
        this.chipTabsItems = chipItems;
        console.log(this.chipTabsItems);
        if (this.chipTabsItems) {
          this.chipTabsItems = this.chipTabsItems.sort((a, b) => b.count - a.count);
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    onClickMore() {
      const route = this.$router.resolve({
        name: "supply-details",
        params: {
          id: this.item.uuid,
          type: "supply",
          lang: this.currentLanguage.key
        }
      });
      window.open(route.href, "_blank");
    },
    onDeleteSupply() {
      EventBus.$emit("supply-out-modified");
      this.visibility = false;
    },
    noEngText,
    noEngTextSymbols,
    noEngTextNumber,
    onlyNumber,
    noEngTextPasted,
    async nextPage() {
      this.page += 1;
      const params = {};
      if (this.categoryChipTab || this.searchString !== "") {
        if (this.categoryChipTab && this.categoryChipTab !== this.$t("btn.all")) {
          params.product_details__name = this.categoryChipTab;
          params.uselike = true;
        }
        if (this.searchString !== "") {
          params.product_details__name = this.searchString;
          params.uselike = true;
        }
      }
      await this.getProducts(params, true);
    },
    switchStatusNP() {
      this.inputSender = {
        name: "",
        surname: "",
        phone: ""
      };
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    showImgDialog(product) {
      this.imgpath = product.product_details.path_img;
      this.imgDialog = true;
    },
    onCancelChangeState() {
      this.cancelReturn = true;
    },
    onConfirmChangeState() {
      this.inputCrmItem.id_state = this.showConfirmChangeStateDialog.nextState.id;
      this.showConfirmChangeStateDialog.visible = false;
      this.onUpdateState(true);
    },
    async setOrderState(state) {
      console.log("state", state);
      if (state.confirm_required) {
        this.showConfirmChangeStateDialog = {
          nextState: state,
          visible: true
        };
        return;
      }
      this.inputCrmItem.id_state = state.id;
      this.onUpdateState(true);
    },
    async setUserState(uuid, message) {
      if (this.settingDialog === "crm") {
        try {
          if (this.uuid_user_state != uuid) {
            this.uuid_user_state = uuid;
            // orderDialog('setUserState');
            const formData = new FormData();
            formData.append("uuid_order", this.inputCrmItem.uuid);
            formData.append("uuid_state", this.uuid_user_state);
            this.loading = true;
            await crmService.setUserState(formData);
            this.loading = false;
            this.sendMSG(message);
            // EventBus.$emit("crm-items-reset");
          } else {
            this.closecallCenterDismiss(message);
          }
          // orderDialog('newState', newState);
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e);
        }
      }
    },
    checkChanged(type) {
      if (this.changedStatus) {
        if (
          this.permissions.client &&
          Number(this.inputCrmItem.id_state) !== 400 && this.settingDialog === "supply"
        ) {
          this.changed = false;
        } else {
          if (type === "sender") {
            this.changedSender = !_.isEqual(this.inputSender, this.copySender);
          }
          if (type === "delivery") {
            this.changedDelivery = !_.isEqual(this.inputDelivery, this.copyDelivery);
          }
          if (type === "crm_item") {
            this.changedCrmItem = !_.isEqual(this.inputCrmItem, this.copyCrmItem);
          }
          if (type === 'address') {
            this.changedAddress = !_.isEqual(this.inputAddress, this.copyAddress);
          }
          this.changed = this.changedSender || this.changedDelivery || this.changedCrmItem || this.changedAddress;
        }
      } else {
        this.changed = false;
      }
    },
    delayTtn() {
      this.inputCrmItem.ttn = "";
      this.ttnCodeStatus = false;
      this.changedStatus = true;
    },
    onClose() {
      EventBus.$emit("close-dialog");
      this.$v.$reset();
      this.visibility = false;
      this.dataReset();
    },
    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    async counterpartyList() {
      try {
        const response = await novaPochtaService.getCounterpartyList();
        // eslint-disable-next-line prefer-destructuring
        const defaultCounterparty = response.filter(item => Number(item.is_default) === 1);
        this.counterparty = defaultCounterparty[0];
        this.listCounterparty = response;
        this.getPhoneNovaPochta();
      } catch (e) {
        console.error(e);
      }
    },
    watchDelivery() {
      // orderDialog('this.permissions.client', this.permissions.client);
      // orderDialog('this.permissions.client', this.inputCrmItem.id_state);
      if (
        (this.settingDialog === "supply" &&
          this.permissions.client &&
          Number(this.inputCrmItem.id_state) !== 400) ||
        (this.settingDialog === "supply" &&
          !this.permissions.client &&
          Number(this.inputCrmItem.id_state) >= 430)
      ) {
        return false;
      }
      // if (
      // this.settingDialog === "crm" &&
      // this.permissions.client &&
      // Number(this.inputCrmItem.id_state) > 0
      // ) {
      //  return false;
      // }
      return true;
    },
    watchDeliveryItem() {
      // orderDialog('this.permissions.client', this.permissions.client);
      // orderDialog('this.permissions.client', this.inputCrmItem.id_state);
      if (
        (this.settingDialog === "supply" &&
          this.permissions.client &&
          Number(this.inputCrmItem.id_state) !== 400) ||
        (this.settingDialog === "supply" &&
          !this.permissions.client &&
          Number(this.inputCrmItem.id_state) >= 410)
      ) {
        return false;
      }
      if (this.settingDialog === "crm") {
        return this.item.type !== "out";
      }
      return true;
    },
    async onRemoveItem(uuid) {
      // orderDialog(uuid);set
      const status = await this.onDelayProduct(uuid);
      if (status) {
        this.chosenProducts = this.chosenProducts.filter(item => item.uuid !== uuid);
        const res = await crmService.isReadyToDeliver(this.item.uuid);
        this.item.ready_to_deliver = res.ready_to_deliver;
        EventBus.$emit("supply-out-modified");
      }
    },
    onClickDelete(uuid) {
      this.chosenItem = uuid;
      this.confirmDialog = true;
    },
    async onDelayProduct(uuid) {
      try {
        if (this.settingDialog === "supply") {
          await supplyService.deleteDeliveryItem(uuid);
          this.setSuccessNotification(this.$t("orderDialog.delayProduct_success"));
          this.getProducts();
          this.getMetrics();
        } else if (this.settingDialog === "crm") {
          this.loadingChosenProducts = true;
          await crmService.deleteCrmItem(uuid);
          await this.getChosenProducts(this.item.uuid);
          this.loadingChosenProducts = false;
          this.resetCopyItems = !this.resetCopyItems;

          this.setSuccessNotification(this.$t("orderDialog.delayProduct_success"));
        }
        return true;
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(this.$t("orderDialog.delayProduct_error"));
        console.error("fail-delayProduct", e);
        return false;
      }
    },
    async fetchData() {
      this.dataReset();
      try {
        if (this.typeDialog === "edit") {
          // // orderDialog('this.typeDialog = ', this.typeDialog);
          let response = {};
          if (this.settingDialog === "crm") {
            response = await crmService.getCrmOrderItem(this.uuidItem);
          } else if (this.settingDialog === "supply") {
            response = await supplyService.getSupplyItem(this.uuidItem);
          }

          if (response) {
            this.item = response;
            this.dataInsertion(this.item);
            if (this.item.id_state === "440") {
              this.returnedDeliveries = await supplyService.getSupplyList({
                uuid_parent: this.item.uuid
              });
            }
            if (this.item.id_state >= 430) {
              this.getCompletedProducts(this.item.uuid);
            }
            if (this.item.id_state < 430 || !this.permissions.client) {
              this.getChosenProducts(this.item.uuid);
            }
            if (this.inputDelivery.phone) {
              this.inputDelivery.phone = this.inputDelivery.phone.replace("+38", "");
            }
            if (this.inputSender.phone) {
              this.inputSender.phone = this.inputSender.phone.replace("+38", "");
            }
          }
        } else if (this.typeDialog === "add") {
          this.loading = false;
          await this.$store.commit(`novaPochta/${mutations.DELAY_WAREHOUSES}`);
          this.changedStatus = true;
          // // orderDialog('this.typeDialog = ', this.typeDialog);
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async onCreate() {
      this.$v.$touch();
      console.log(this.$v)
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          // formData.append('name', `Вихід ${moment.now()}`);
          formData.append("description", this.inputCrmItem.description);

          console.log(this.uuid_tab);
          if (this.uuid_tab.length) {
            formData.append("uuid_user_state", this.uuid_tab);
          }

          formData.append("delivery_address_details__name", this.inputDelivery.name);
          formData.append("delivery_address_details__surname", this.inputDelivery.surname);
          if (this.inputDelivery.phone) {
            formData.append("delivery_address_details__phone", `+38${this.inputDelivery.phone}`);
          } else {
            formData.append("delivery_address_details__phone", "");
          }

          if (this.radioServiceType == 'WarehouseWarehouse') {         
            formData.append("delivery_address_details__service_type", this.radioServiceType);
            formData.append("delivery_address_details__uuid_ref_warehouse", this.inputDelivery.warehouse);
            formData.append("delivery_address_details__uuid_ref_city", this.inputDelivery.city);
            formData.append("delivery_address_details__name_city", this.inputDelivery.name_city);
            formData.append("delivery_address_details__name_warehouse", this.inputDelivery.name_warehouse);
          }
          else {
            formData.append("delivery_address_details__uuid_ref_street", this.inputAddress.city_street);
            formData.append("delivery_address_details__uuid_ref_city", this.inputAddress.city_address);
            formData.append("delivery_address_details__name_city", this.searchAddressCityQuery);
            formData.append("delivery_address_details__name_street", this.searchAddressStreetQuery);
            formData.append("delivery_address_details__service_type", this.radioServiceType);
            formData.append("delivery_address_details__number_flat", this.inputAddress.city_flat);
            formData.append("delivery_address_details__number_house", this.inputAddress.city_house);
          }
          formData.append("sender_address_details__name", this.inputSender.name);
          formData.append("sender_address_details__surname", this.inputSender.surname);
          if (this.inputSender.phone) {
            formData.append("sender_address_details__phone", `+38${this.inputSender.phone}`);
          } else {
            formData.append("sender_address_details__phone", "");
          }

          formData.append("sender_address_details__email", this.inputSender.email);

          if (this.inputCrmItem.ttn) {
            formData.append(
              "ttn",
              this.inputCrmItem.ttn == 0 || this.inputCrmItem.ttn == ""
                ? null
                : this.inputCrmItem.ttn
            );
          } else {
            formData.append("ttn", null);
          }
          if (this.inputCrmItem.cost) {
            formData.append("cost", this.inputCrmItem.cost);
          }
          if (this.inputCrmItem.cash_on_delivery) {
            formData.append("cash_on_delivery", this.inputCrmItem.cash_on_delivery);
          }
          formData.append("is_counterparty_used", this.statusCreateTTN ? 1 : 0);

          let response;
          if (this.settingDialog === "supply") {
            formData.append("type", "out");
            formData.append("uuid_user", this.getUser.uuid);
            response = await supplyService.addSupplyItem(formData);
            EventBus.$emit("supply-out-modified");
          } else if (this.settingDialog === "crm") {
            formData.append("uuid_board", this.uuid_board);
            formData.append("type", "crm");
            response = await crmService.addCrmOrder(formData);
            EventBus.$emit("crm-items-reset");
          }

          this.dataInsertion(response);

          this.setSuccessNotification(this.$t("supply.successfully_created"));
          this.loading = false;
          this.$v.$reset();
        } catch (e) {
          console.log(e);
          this.loading = false;
          this.setErrorNotification(e);
        }
      }
    },
    async onChangeCallCenter() {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("uuid", this.inputCrmItem.uuid);
        formData.append("is_call_required", this.item.is_call_required);
        let response;
        if (this.settingDialog === "crm") {
          response = await crmService.updateCrmOrder(formData);
          this.dataInsertion(response);
          // EventBus.$emit("crm-items-reset");
        }
        this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));
        this.loading = false;
        this.$v.$reset();
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(e);
      }
    },
    async onUpdate(mode) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();

          formData.append("uuid", this.inputCrmItem.uuid);
          formData.append("uuid_deport", this.inputCrmItem.uuid_deport);

          const changedDataSender = this.$getChangedData(this.inputSender, this.copySender);
          changedDataSender.forEach(data => {
            let prefix = "sender_address_details__";
            if (data === "description") {
              prefix = "";
            }
            formData.append(`${prefix}${data.field}`, data.value);
          });

          const changedDataCrmItem = this.$getChangedData(this.inputCrmItem, this.copyCrmItem);
          changedDataCrmItem.forEach(data => {
            formData.append(`${data.field}`, data.value);
          });

          const changedDataDelivery = this.$getChangedData(this.inputDelivery, this.copyDelivery);
          console.log(changedDataDelivery)
          changedDataDelivery.forEach(data => {
            let prefix = "";
            switch (data.field) {
              case "name":
              case "surname":
              case "phone":
                prefix = "delivery_address_details__";
                break;
              case "name_city":
              case "name_warehouse":
                prefix = "delivery_address_details__";
                break;
              case "city":
                if (this.radioServiceType == 'WarehouseWarehouse') {
                  formData.append('delivery_address_details__uuid_ref_city', data.value);
                }
                break;
              case "warehouse":
                formData.append('delivery_address_details__uuid_ref_warehouse', data.value ? data.value : '00000000-0000-0000-0000-000000000000');
                break;
              default:
                prefix = "";
            }

            if (data.field != 'city' && data.field != 'warehouse') {
              formData.append(`${prefix}${data.field}`, data.value);
            }
          });

          const changedDataAddress = this.$getChangedData(this.inputAddress, this.copyAddress);
          changedDataAddress.forEach(data => {
            switch (data.field) {
              case "city_address":
                if (this.radioServiceType == 'WarehouseDoors') {
                  formData.append('delivery_address_details__uuid_ref_city', data.value);
                  formData.append("delivery_address_details__name_city", this.searchAddressCityQuery);
                }
                break;
              case "city_street":
                formData.append('delivery_address_details__uuid_ref_street', data.value ? data.value : '00000000-0000-0000-0000-000000000000');
                formData.append("delivery_address_details__name_street", this.searchAddressStreetQuery);
                break;
              case "city_house":
                formData.append('delivery_address_details__number_house', data.value);
                break;
              case "city_flat":
                formData.append('delivery_address_details__number_flat', data.value);
                break;
              default: 
                break
            }
          });

          formData.append("is_counterparty_used", this.statusCreateTTN ? 1 : 0);

          if (this.copyRadioServiceType != this.radioServiceType) {
            formData.append("delivery_address_details__service_type", this.radioServiceType);
          }

          let response;
          if (this.settingDialog === "supply") {
            // console.log("test", mode);
            if (mode === "send") {
              formData.append("id_state", 401);
            }
            if (
              this.item.id_state !== this.inputCrmItem.id_state &&
              Number(this.inputCrmItem.id_state) !== 400
            ) {
              formData.append("id_state", this.inputCrmItem.id_state);
            }
            response = await supplyService.updateSupplyItem(formData);

            // console.log("update", response);
            if (response.data.status === "success") {
              if (mode === "send") {
                this.onClose();
              } else {
                this.dataInsertion(response.data.object);
              }
            }
            EventBus.$emit("supply-out-modified");
          } else if (this.settingDialog === "crm") {
            response = await crmService.updateCrmOrder(formData);
            this.dataInsertion(response);
            EventBus.$emit("crm-items-reset");
          }

          this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));
          this.loading = false;
          this.$v.$reset();
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e);
        }
      }
    },
    async onTTN() {
      try {
        this.loading = true;
        const formData = new FormData();
        // console.log("this.inputCrmItem", this.inputCrmItem);
        formData.append("uuid", this.inputCrmItem.uuid);

        formData.append("ttn", this.inputCrmItem.ttn);
        formData.append("cost", this.inputCrmItem.cost);
        formData.append("cash_on_delivery", this.inputCrmItem.cash_on_delivery);

        let response;
        if (this.settingDialog === "supply") {
          response = await supplyService.updateSupplyItem(formData);
          EventBus.$emit("supply-out-modified");
        } else if (this.settingDialog === "crm") {
          response = await crmService.updateCrmOrder(formData);
          EventBus.$emit("crm-items-reset");
        }

        this.dataInsertion(response.data.object);

        this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));
        this.loading = false;
        this.$v.$reset();
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(e);
      }
    },
    async onSend() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;

          let response;
          if (this.settingDialog === "supply") {
            this.onUpdate("send");
          } else if (this.settingDialog === "crm") {
            response = await crmService.startCrmOrderDelivery(this.inputCrmItem.uuid);
            // orderDialog('response', response);
            if (response.status === "success") {
              this.inputCrmItem.id_state = 401;
              this.onClose();
            }

            this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));
            this.loading = false;
            EventBus.$emit("crm-items-reset");
            this.$v.$reset();
          }
          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e);
        }
      }
    },
    async getChosenProducts(uuid) {
      try {
        this.loadingItems = true;
        this.loadingChosenProducts = true;
        // // orderDialog('this.item.uuid', this.item.uuid);
        if (this.settingDialog === "crm") {
          const res = await crmService.getCrmItemDetailsUuid(uuid);
          this.chosenProducts = res.object;
          this.totalChosenProducts = res.message;
        } else if (this.settingDialog === "supply") {
          this.chosenProducts = await supplyService.getDeliveryItemDetailsByDeliveryUuid(uuid);
        }
        if (!this.chosenProducts) {
          this.chosenProducts = [];
        }
        this.loadingItems = false;
        this.loadingChosenProducts = false;
      } catch (e) {
        this.loadingItems = false;
        this.loadingChosenProducts = false;
        console.error("error", e);
      }
    },
    async getCompletedProducts(uuid) {
      try {
        this.loadingItems = true;
        this.completedItems = await supplyService.getCompletedItemDetailsByDeliveryUuid(uuid, {
          details: true
        }); // remove details
        this.loadingItems = false;
      } catch (e) {
        this.loadingItems = false;
        console.log(e);
      }
    },
    async getItemsInCell() {
      try {
        this.loadingItems = true;
        this.itemsInCell = await depotService.getMyProducts({
          uuid_cell: this.item.uuid_cell
        });
        this.loadingItems = false;
      } catch (e) {
        this.loadingItems = false;
        console.log(e);
      }
    },
    dataReset() {
      this.activeProduct = false;
      this.tabStep = 0;
      this.activeTab = 0;
      this.uuid_user_state = "";
      this.searchString = "";
      this.ttnCodeSvg = "";
      this.ttnCodeStatus = false;
      this.chosenProducts = [];
      this.inputCrmItem = {
        description: "",
        ttn: "",
        cost: "",
        is_blocked: false,
        uuid: "",
        cash_on_delivery: "",
        id_state: 400
      };
      this.inputSender = {
        name: "",
        surname: "",
        phone: ""
      };
      this.sender = {
        phoneMenu: false
      };
      this.inputDelivery = {
        name: "",
        surname: "",
        phone: "",
        city: "",
        name_city: "",
        warehouse: "",
        name_warehouse: "",
        warehouse_id: ""
      };
      this.delivery = {
        phoneMenu: false,
        items: []
      };
      this.changed = false;
      this.changedStatus = false;
      this.$v.$reset();
    },

    onSearchProduct: _.debounce(async function() {
      if (!this.searchString) {
        this.categoryChipTab = null;
      }
      if (this.searchString) {
        this.isLoadingSearch = true;
        await this.getProducts({
          product_details__name: this.searchString,
          uselike: true
        });
        this.oldSerach = this.searchString;
        setTimeout(() => {
          this.isLoadingSearch = false;
        }, 300);
      } else {
        await this.getProducts();
      }
      this.$refs.searchField.focus();
    }, 500),
    async getMetrics() {
      try {
        if (this.settingDialog === "supply") {
          const metricsItem = await supplyService.getSupplyMetrics(this.item.uuid);
          this.metricsValue = metricsItem;
          // console.log(metricsItem)
        }
      } catch (e) {
        this.setErrorNotification(e.error.description);
      }
    },
    async getProducts(params = {}, next = false) {
      try {
        if (!next) {
          this.page = 1;
        }
        if (next && this.tableAvaibleProductsFilter) {
          params.order = this.tableAvaibleProductsFilter.order;
          params.orderby = this.tableAvaibleProductsFilter.orderby;
        }

        if (next) {
          this.isloadingNextProducts = true;
        } else {
          this.isloadingProducts = true;
        }
        let response = null;
        if (this.settingDialog === "crm") {
          response = await crmService.crmProductList({
            ...params,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
            uuid_board: this.uuid_board
          });
        } else {
          response = await depotService.getMyProducts({
            groupby: "uuid_product",
            // groupby: 'product_details.bar_code',
            id_state: 810,
            ...params,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
            uuid_user: this.item.uuid_user
          });
        }
        const newItems = response;
        if (next) {
          this.products.push(...newItems);
        } else {
          this.products = newItems;
        }

        // // orderDialog('products', this.products);
        this.products.forEach(product => {
          // eslint-disable-next-line no-param-reassign
          product.amount = product.count;
          // eslint-disable-next-line no-param-reassign
          product.add_uuid_option = null;
          if (product.count < 1) {
            // eslint-disable-next-line no-param-reassign
            product.count = 0;
          } else {
            // eslint-disable-next-line no-param-reassign
            product.count = 1;
          }
        });

        this.loadMoreDisabled = newItems.length < this.perPage;

        if (next) {
          this.isloadingNextProducts = false;
        } else {
          this.isloadingProducts = false;
        }
      } catch (e) {
        if (next) {
          this.isloadingNextProducts = false;
        } else {
          this.isloadingProducts = false;
        }
        this.isloadingProducts = false;
        this.setErrorNotification(e);
      }
    },
    offCodeSvg() {
      this.ttnCodeSvg = "";
    },
    addProductOption(checked, product, type) {
      const productOption = product.options_details.filter(item => item.uuid === this.checkOption);
      if (productOption.length) {
        if (product.count <= productOption[0].available || this.settingDialog === "crm") {
          // eslint-disable-next-line no-param-reassign
          product.uuid_option = this.checkOption;
          product = {
            ...product,
            mode: type
          };
          this.chosenProducts = [];
          if (product.mode !== "addProduct") {
            // eslint-disable-next-line no-param-reassign
            product.count = 1;
          }
          this.onAddProduct(product, product.mode);
          this.getProducts();
          this.searchString = "";
          this.checkOption = "";
        } else {
          this.setErrorNotification("Кількість товарів перевищена");
        }
      } else {
        this.setErrorNotification(this.$t("supply.choose_option"));
      }
    },
    async onChoseByBarCode(checked, product, type) {
      console.log(product);
      if (product.count > product.available && this.settingDialog === "supply") {
        this.setErrorNotification("Кількість товарів перевищена");
      } else {
        // eslint-disable-next-line no-param-reassign
        product = {
          ...product,
          mode: type
        };
        this.chosenProducts = [];
        if (product.mode !== "addProduct") {
          // eslint-disable-next-line no-param-reassign
          product.count = 1;
        }
        this.onAddProduct(product, product.mode);
        this.getProducts();
        this.searchString = "";
      }
    }
  },
  computed: {
    ...mapGetters("crm", {
      uuid_board: getterTypes.GET_UUID_BOARD,
      uuid_tab: getterTypes.GET_UUID_TAB
    }),
    ...mapGetters(["currentLanguage"]),
    blockedItem() {
      if (
        (this.permissions.client || this.permissions.employees) &&
        this.inputCrmItem.is_blocked &&
        this.inputCrmItem.type === "out"
      ) {
        return true;
      }
      return false;
    },
    isLoadingCard() {
      if (this.settingDialog === "crm") {
        return this.loadingChosenProducts || this.loading;
      } else {
        return this.loadingChosenProducts || this.loading;
      }
    },
    isSendedCrmOrder() {
      if (this.settingDialog === "crm" && this.typeDialog === "edit") {
        return this.item.type === "out";
      } else {
        return false;
      }
    },
    ttnCode() {
      // orderDialog('this.inputCrmItem.ttn', this.inputCrmItem.ttn);
      return !(this.inputCrmItem.ttn === "" || this.inputCrmItem.ttn == 0);
    },
    sendButtonDisabled() {
      // // orderDialog('this.inputCrmItem.id_state', this.inputCrmItem.id_state);
      if (this.activeTab === 0) {
        if (this.settingDialog === "supply") {
          return !this.inputDelivery.warehouse || !this.chosenProducts.length;
        } else {
          return !this.chosenProducts.length || Number(this.inputCrmItem.id_state) >= "401";
        }
      }
      return true;
    },
    visibility: {
      get() {
        if (this.settingDialog === "crm") {
          this.fetchData();
        }
        return this.visible;
      },
      set() {
        this.$emit("close");
        if (this.typeDialog === "add") {
          this.dataReset();
        }
        // this.$destroy();
      }
    },
    deliveryNameErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.name.$dirty) {
        return errors;
      }
      if (!this.$v.inputDelivery.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    deliverySurnameErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.surname.$dirty) {
        return errors;
      }
      if (!this.$v.inputDelivery.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.surname.required &&
        errors.push(this.$t("form.errors.SurnameRequired"));
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.inputDelivery.surname.validText
      // && errors.push('Некоректо введено Пріщвише');
      return errors;
    },
    senderNameErrors() {
      const errors = [];
      if (!this.$v.inputSender.name.$dirty) {
        return errors;
      }
      if (!this.$v.inputSender.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      return errors;
    },
    senderSurnameErrors() {
      const errors = [];
      if (!this.$v.inputSender.surname.$dirty) {
        return errors;
      }
      if (!this.$v.inputSender.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      return errors;
    },
    deliveryPhoneErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.phone.required &&
        errors.push(this.$t("form.errors.PhoneNumberRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.phone.phoneMask &&
        errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    },
    senderPhoneErrors() {
      const errors = [];
      if (!this.$v.inputSender.phone.$dirty) {
        return errors;
      }
      !this.$v.inputSender.phone.phoneMask &&
        errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    },
    deliveryCityErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.city.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.city.required && errors.push(this.$t("form.errors.CityRequired"));
      return errors;
    },
    deliveryWarehouseErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.warehouse.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.warehouse.required && errors.push(this.$t("form.errors.DepartmentRequired"));
      return errors;
    },
    deliveryAddressCityErrors() {
      const errors = [];
      if (!this.$v.inputAddress.city_address.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputAddress.city_address.required && errors.push(this.$t("form.errors.CityRequired"));
      return errors;
    },
    deliveryAddressStreetErrors() {
      const errors = [];
      if (!this.$v.inputAddress.city_street.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputAddress.city_street.required && errors.push(this.$t("form.errors.StreetRequired"));
      return errors;
    },
    deliveryAddressHouseErrors() {
      const errors = [];
      if (!this.$v.inputAddress.city_house.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputAddress.city_house.required && errors.push(this.$t("form.errors.HouseRequired"));
      return errors;
    },
    ttnErrors() {
      const errors = [];
      // if (!this.$v.supplyItem.ttn.$dirty) {
      //   return errors;
      // }
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.supplyItem.ttn.required
      // && errors.push(this.language.isRequired('ТТН'));
      return errors;
    },
    getColorForUp() {
      if (this.nameFilterA !== null) {
        if (this.nameFilterA === true) {
          return "grey darken-2";
        } else {
          return "grey lighten-1";
        }
      } else {
        return "grey lighten-1";
      }
    },
    getColorForDown() {
      if (this.nameFilterA !== null) {
        if (this.nameFilterA === true) {
          return "grey lighten-1";
        } else {
          return "grey darken-2";
        }
      } else {
        return "grey lighten-1";
      }
    }
  },
  validations() {
    return {
      inputDelivery: {
        name: {
          required: requiredIf(function() {
            return this.statusCreateTTN;
          }),
          noEngTextValidator: noEngTextValidator
        },
        surname: {
          required: requiredIf(function() {
            return this.statusCreateTTN;
          }),
          noEngTextValidator: noEngTextValidator
        },
        phone: {
          required: requiredIf(function() {
            return this.statusCreateTTN;
          }),
          phoneMask
        },
        city: {
          required: requiredIf(() => {
            return this.radioServiceType == 'WarehouseWarehouse';
          }) 
        },
        warehouse: {
          required: requiredIf(() => {
            return this.radioServiceType == 'WarehouseWarehouse';
          }) 
        },
      },
      inputAddress: {
        city_address: {
          required: requiredIf(() => {
            return this.radioServiceType == 'WarehouseDoors';
          }) 
        },
        city_street: {
          required: requiredIf(() => {
            return this.radioServiceType == 'WarehouseDoors';
          }) 
        },
        city_house: {
          required: requiredIf(() => {
            return this.radioServiceType == 'WarehouseDoors';
          }) 
        },
      },
      inputSender: {
        phone: { phoneMask },
        name: {
          noEngTextValidator: noEngTextValidator
        },
        surname: {
          noEngTextValidator: noEngTextValidator
        }
      }
    }
  },
  props: {
    // item: {
    //   required: true
    // },
    isCallCenter:{
      required:false,
      default:false
    },
    isAllowSendOrder: {
      type: Boolean,
      default: true
    },
    "no-cross": {
      type: Boolean,
      default: false
    },
    isAccessDeportInfo: {
      type: Boolean,
      default: true
    },
    user_states: {
      type: Array
    },
    typeDialog: String,
    uuidItem: String,
    settingDialog: String,
    visible: {
      required: false,
      type: Boolean
    },
    onlyContent: Boolean
  },
  components: {
    ConfirmDeleteDialog,
    ConfirmLeftDialog,
    headerDialogSupply,
    headerDialogCrm,
    infoDialogCrm,
    imgDialog,
    DeliveryChatComponent,
    NsSmsIntegration,
    chipTab,
    ReturnSuppply,
    ConfirmDialogSlots,
    itemsViewTreeView,
    itemsView,
    StatusCircle,
    EditSupplyInDialog,
    CallCenterDismissDialogVue,
    GenerateTTHDialogVue
  },
  mixins: [validationMixin, notifications, novaPochta, user, language, OrderMixin],
  watch: {
    searchAddressCityQuery(val) {
      if (val || val == '') {
        this.pagination.page = 1
        this.getSettlements(false)
      }
    },
    searchAddressStreetQuery(val) {
      this.paginationStreets.page = 1
      this.getStreets(false, false)
    },
    searchCityQuery: {
      handler(e) {
        if (e && this.$refs.deliveryCity.filteredItems.length != this.activeCities.length) {
          this.$refs.deliveryCity.filteredItems.sort(function(a, b){
            return b.Description.toLowerCase().startsWith(e.toLowerCase()) - a.Description.toLowerCase().startsWith(e.toLowerCase())
          });
        }
      }
    },
    activeTab(e) {
      this.isChipReset = !this.isChipReset;
      if (e === 1) {
        this.getProducts();
      }
    },
    tabItemsStep: {
      deep: true,
      handler(e) {
        if (e === 2 && !this.itemsInCell.length) {
          this.getItemsInCell();
        }
      }
    },
    counterparty: {
      deep: true,
      handler(e) {
        console.log(e);
      }
    },
    checkOption(chosenOption) {
      this.products.forEach(products => {
        if (products.options_details) {
          const option = products.options_details.filter(option => option.uuid === chosenOption);
          if (option.length > 0) {
            this.optionItemAvailable = option[0].available;
          }
        }
      });
    },
    nameFilterA: {
      deep: true,
      handler(e) {
        this.tableAvaibleProductsFilter = {};
        if (this.categoryChipTab && !this.searchString) {
          if (this.categoryChipTab !== this.$t("btn.all")) {
            this.tableAvaibleProductsFilter.product_details__name = this.categoryChipTab;
            this.tableAvaibleProductsFilter.uselike = true;
          }
        } else {
          this.tableAvaibleProductsFilter.product_details__name = this.searchString;
          this.tableAvaibleProductsFilter.uselike = true;
        }
        if (e === true) {
          this.tableAvaibleProductsFilter.order = "ASC";
          this.tableAvaibleProductsFilter.orderby = "product_details.name";

          this.getProducts(this.tableAvaibleProductsFilter, false);
        } else {
          this.tableAvaibleProductsFilter.order = "DESC";
          this.tableAvaibleProductsFilter.orderby = "product_details.name";

          this.getProducts(this.tableAvaibleProductsFilter, false);
        }
      }
    },
    searchString(e) {
      if (!e) {
        this.getProducts();
      }
    },
    inputSender: {
      deep: true,
      handler(e) {
        this.checkChanged("sender");
      }
    },
    inputDelivery: {
      deep: true,
      handler(e) {
        console.log(e)
        this.checkChanged("delivery");
      }
    },
    inputAddress: {
      deep: true,
      handler(e) {
        this.checkChanged("address");
      }
    },
    inputCrmItem: {
      deep: true,
      handler(e) {
        this.checkChanged("crm_item");
      }
    }
  }
};
</script>

<style lang="scss">
@import "orderDialog";
</style>
