import crmService from '../../services/request/crm/crmService';
import novaPochtaService from '../../services/request/novaPochta/novaPochtaService';
import user from '../../mixins/user';
import EventBus from '../../events/EventBus';
import supplyService from '../../services/request/supply/supplyService';
import depotService from '../../services/request/depot/depotService';
import notifications from '../../mixins/notifications';
import addressService from "../../services/request/address/addressService";

export default {
  mixins: [user, notifications],
  methods: {
    nextPageStreets(){
      this.paginationStreets.page += 1;
      this.getStreets(true, false)
    },
    async getStreets(nextPage = false, change = false) {
      try {
        // this.cityAddressLoading = true
        if (change) {
          this.inputAddress.city_flat = ''
          this.inputAddress.city_street = ''
          this.inputAddress.city_house = ''
          this.inputAddress.city_flat = ''

          this.inputDelivery.city = ''
          this.inputDelivery.name_city = ''
          this.inputDelivery.warehouse = ''
          this.inputDelivery.warehouse_id = ''
          this.inputDelivery.name_warehouse = ''

          this.paginationStreets.page = 1
          this.streetsList = []
        }
        const res = await novaPochtaService.getAllStreets(this.inputAddress.city_address, this.searchAddressStreetQuery, this.paginationStreets.page ,this.paginationStreets.perPage );
        if(nextPage){
          this.streetsList.push(...res)
          // this.$forceUpdate()
        } else{
          this.streetsList = res
          // this.$forceUpdate()
        }
        // this.cityAddressLoading = false
        this.loadMoreStreetsDisabled = res.length < this.pagination.perPage;

      } catch (e) {
        this.setErrorNotification(e)
      }
    },
    nextPageCities(){
      this.pagination.page += 1;
      this.getSettlements(true)
    },
    async getSettlements(nextPage = false) {
      try {
        this.cityAddressLoading = true

        const res = await novaPochtaService.getAllSettlements(this.searchAddressCityQuery, this.pagination.page ,this.pagination.perPage );

        // console.log(this.searchAddressCityQuery)
        if(nextPage){
          this.settlementsList.push(...res)
          // this.$forceUpdate()
        } else{
          this.settlementsList = res
          // this.$forceUpdate()
        }
        this.cityAddressLoading = false
        this.loadMoreCitiesDisabled = res.length < this.pagination.perPage;

      } catch (e) {
        this.setErrorNotification(e)
      }
    },
    async requestBlocked (message) {
      try {

        let response = {}
        if (this.inputCrmItem.is_blocked) {
          response = await supplyService.unblockSupplyItem(this.inputCrmItem.uuid);
        } else {
          response = await supplyService.blockSupplyItem(this.inputCrmItem.uuid);

          this.tabStep = 2
            try {
              const formData = new FormData();
              formData.append("uuid_object", this.inputCrmItem.uuid);
              formData.append("message", message);
              await supplyService.chatMessage(formData);
            } catch (e) {
              this.setErrorNotification(e);
            }
        }
        
        EventBus.$emit('supply-out-modified');
      } catch (e) {
        console.error(e)
      }
      this.inputCrmItem.is_blocked = !this.inputCrmItem.is_blocked
    },
    async createNovaPochtaTTN() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loadingCreateTTN = true;
          const formData = {
            apiKey: this.getUser.np_apikey,
            modelName: 'InternetDocument',
            calledMethod: 'save',
            methodProperties: {
              NewAddress: '1',
              PayerType: 'Sender',
              PaymentMethod: 'Cash',
              CargoType: 'Cargo',
              VolumeGeneral: '0.1',
              Weight: '30',
              ServiceType: 'WarehouseWarehouse',
              SeatsAmount: '1',
              Description: this.inputCrmItem.description.length > 1 ? this.inputCrmItem.description : '-----',
              Cost: Number(this.inputCrmItem.cost),
              CitySender: this.counterparty.uuid_ref_city,
              SenderAddress: this.counterparty.uuid_ref_warehouse,
              Sender: this.counterparty.uuid_ref,
              ContactSender: this.counterparty.uuid_ref_sender,
              SendersPhone: this.counterpartyPhone,
              RecipientCityName: this.inputDelivery.name_city,
              RecipientArea: '',
              RecipientAreaRegions: '',
              RecipientAddressName: this.inputDelivery.warehouse_id,
              RecipientHouse: '',
              RecipientFlat: '',
              RecipientName: `${this.inputDelivery.name} ${this.inputDelivery.surname}`,
              RecipientType: 'PrivatePerson',
              RecipientsPhone: this.inputDelivery.phone.replace(/[^+\d]+/g, ''),
              DateTime: (new Date()).toLocaleDateString('ru-Ru'),
            },
          };
          // console.log('data-generateTTN:', formData)
          // orderDialog('formData', formData);
          const response = await novaPochtaService.postCreateTTN(formData);
          // orderDialog('createNovaPochtaTTN', response);
          if (response.data.success) {
            // orderDialog('success', response.data.success);
            // EventBus.$emit('new-ttn', response.data.data[0]);
            this.inputCrmItem.ttn = response.data.data[0].IntDocNumber;
            if (this.typeDialog === 'edit') {
              this.onTTN();

            }
            this.onSearchByTTN();
            // this.$emit('close');
            this.setSuccessNotification(this.$t('supply.successfully_created'));
          } else {
            response.data.errors.forEach((item) => {
              this.setErrorNotification(item);
            });
          }
          this.loadingCreateTTN = false;
        } catch (e) {
          console.error(e);
          this.loadingCreateTTN = false;
        }
      }
    },
    async getPhoneNovaPochta() {
      // // orderDialog('counterparty', this.counterparty);
      try {
        const formData = {
          apiKey: this.getUser.np_apikey,
          modelName: 'Counterparty',
          calledMethod: 'getCounterpartyContactPersons',
          methodProperties: {
            Ref: this.counterparty.uuid_ref,
            Page: '1',
          },
        };
        const response = await novaPochtaService.postCreateTTN(formData);
        // // orderDialog('getPhoneNovaPochta', response);
        const data = response.data.data.filter((item) => item.Ref === this.counterparty.uuid_ref_sender);
        this.counterpartyPhone = data[0].Phones;
      } catch (e) {
        console.warn(e);
      }
    },
    async onAddProduct(product, mode) {
      try {
        // orderDialog('onAddProduct', product);
        if (this.inputCrmItem.uuid) {
          // orderDialog('onAddProduct__uuid', this.inputCrmItem.uuid);
        } else {
          // orderDialog('onAddProduct__uuid', 'none!!!!');
        }
        const productData = new FormData();
        productData.append('uuid_delivery', this.inputCrmItem.uuid);
        if (mode === 'addProduct') {
          productData.append('uuid_product', product?.uuid_product);
          productData.append('items_amount', product?.count);
          productData.append('description', product?.product_details?.description);
          if(this.settingDialog === 'crm' && this.isAdditionSell){
            productData.append('is_additional_sell', 1);
          }
          if (product.uuid_option) {
            productData.append('uuid_option', product?.uuid_option);
          }
          if (this.settingDialog === "supply" && this.permissions.more_than_client) {
            productData.append('uuid_user', product?.uuid_user);
          }
          // orderDialog('productData', productData);
          await crmService.addCrmItemAddProduct(productData);
          this.setSuccessNotification(this.$t('orderDialog.addProduct_success'));
          await this.getChosenProducts(this.inputCrmItem.uuid);
          this.getMetrics()
          this.activeTab = 0;
          this.chipTabsItems = {}
          this.getChipTabsItems()
          this.resetCopyItems = !this.resetCopyItems 
          EventBus.$emit('supply-out-modified');
          if(this.isAdditionSell){
            this.isAdditionSell = false
          }

        } else if (mode === 'add') {
          productData.append('uuid_item', product.uuid);
          await crmService.addCrmItemProduct(productData);
          this.setSuccessNotification(this.$t('orderDialog.addProduct_success'));
          if(this.isAdditionSell){
            this.isAdditionSell = false
          }
          this.getChosenProducts(this.inputCrmItem.uuid);
       
        }
        if(this.settingDialog === 'crm'){
          const res = await crmService.isReadyToDeliver(this.item.uuid)
          this.item.ready_to_deliver = res.ready_to_deliver
        }
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(this.$t('orderDialog.addProduct_error'));
        console.error('fail-addProduct', e)
      }
    },
    async onSearchByTTN(checkUpdate) {
      if(!this.inputCrmItem.ttn || this.inputCrmItem.ttn === 0 || !this.inputCrmItem.ttn.length) {
        this.setErrorNotification(this.$t('form.errors.EnterTTN'));
      } else {
        try {
          this.ttnCodeSvg = this.inputCrmItem.ttn;
          const params = {
            ttn: this.inputCrmItem.ttn,
          };
          if (this.inputSender.phone) {
            params.phone = this.inputSender.phone.replace(/[^+\d]+/g, '');
          }
          if (this.inputDelivery.phone) {
            params.phone = this.inputDelivery.phone.replace(/[^+\d]+/g, '');
          }

          const documents = await novaPochtaService.searchByTTN(params);
          if (documents.length) {
            const document = documents[0];
            // console.log('documents', document)
            if (Number(document.StatusCode) === 3) {
              this.setErrorNotification(this.$t('form.errors.NonExistentTtn'));

              if (checkUpdate) {
                this.changedStatus = true
              }
              this.ttnCodeStatus = false;
            } else {
              this.ttnCodeStatus = true;
              this.inputDelivery.city = document?.RefCityRecipient;
              if (document.SenderFullNameEW) {
                // eslint-disable-next-line prefer-destructuring
                this.inputSender.surname = document.SenderFullNameEW.split(' ')[0];
                // eslint-disable-next-line prefer-destructuring
                this.inputSender.name = document.SenderFullNameEW.split(' ')[1];
              }
              if (document.RecipientFullName.length) {
                // eslint-disable-next-line prefer-destructuring
                this.inputDelivery.surname = document.RecipientFullName.split(' ')[0];
                // eslint-disable-next-line prefer-destructuring
                this.inputDelivery.name = document.RecipientFullName.split(' ')[1];
              }
              if (document.PhoneSender) {
                this.inputSender.phone = document.PhoneSender.replace('38','');
              }
              if (this.inputDelivery.city) {
                await this.onSelectCity(this.inputDelivery.city, 'delivery');
              }
              // if (document.AnnouncedPrice) {
              //   this.inputCrmItem.cost = document.AnnouncedPrice;
              // }
              if (document.FactualWeight) {
                this.inputCrmItem.weight = document.FactualWeight;
              }
              if (document.DocumentCost) {
                this.inputCrmItem.delivery_price = document.DocumentCost;
              }
              if (document.AdditionalInformationEW) {
                this.inputCrmItem.description = document.AdditionalInformationEW;
              }
              // const city = this.cities.find((cityItem) => cityItem.Ref === this.inputDelivery.city);
              if (document.WarehouseRecipientInternetAddressRef) {
                this.inputDelivery.warehouse = document.WarehouseRecipientInternetAddressRef;
                this.inputDelivery.name_warehouse = document.WarehouseRecipient;
              }
              if(document.PaymentMethod){
                console.log(document)
                switch(document.PaymentMethod){
                  case "Cash": this.inputCrmItem.pay_type  = 'CASH_ON_DELIVERY'
                  break;
                  default: this.inputCrmItem.pay_type = ''
                }
                console.log(this.inputCrmItem.pay_type)
              }
              this.$forceUpdate();
              // this.inputCrmItem.cost = document?.DocumentCost;
            }
          }
        } catch (e) {
          this.setErrorNotification(e);
        }
      }
    },
    async onUpdateState() {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append('uuid', this.inputCrmItem.uuid);
        formData.append('id_state', this.inputCrmItem.id_state);
        formData.append('uuid_deport', this.inputCrmItem.uuid_deport);

        const response = await supplyService.updateSupplyItem(formData);
        this.dataInsertion(response.data.object);

        EventBus.$emit('supply-out-modified');
        this.$root.$emit('updated-notify');
        this.setSuccessNotification(this.$t('supplly.delivery_successfully_update'));
        this.loading = false;
        this.visibility = false;
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(e);
      }
    },
    async dataInsertion(item) {

      this.loading = true;
      this.getProducts();
      this.getChipTabsItems()

      // // orderDialog('dataInsertion', item);
      this.activeProduct = true;
      this.statusCreateTTN = Number(item.is_counterparty_used)
      this.item.number = item.number;
      this.item.uuid = item.uuid;
      this.item.state_details = item.state_details
      this.item.id_state = item.id_state;
      this.uuid_user_state = item.uuid_user_state;
      this.ttnCodeSvg = item.ttn > 0 ? item.ttn : '';
      this.ttnCodeStatus = item.ttn > 0 ? true : false;
      this.inputCrmItem.description = item.cell_details?.description;
      this.inputCrmItem.pay_type = item.pay_type;
      this.inputCrmItem.uuid = item.uuid || ' ';
      this.inputCrmItem = {
        ...this.inputCrmItem,
        ...item,
      };
      this.inputCrmItem.is_blocked = !(item.is_blocked === null || Number(item.is_blocked) === 0)

      this.inputSender = {
        ...this.inputSender,
        name: item.sender_address_details?.name || '',
        surname: item.sender_address_details?.surname || '',
      };

      if(item.sender_address_details?.phone){
        this.inputSender.phone = item.sender_address_details?.phone.replace('+38','') || ''
      }

      this.radioServiceType = item.delivery_address_details?.service_type

      this.inputDelivery = {
        ...this.inputDelivery,
        name: item.delivery_address_details?.name || '',
        surname: item.delivery_address_details?.surname || '',
        city: item.delivery_address_details?.uuid_ref_city || '',
        warehouse: item.delivery_address_details?.uuid_ref_warehouse || '',
        name_warehouse: item.delivery_address_details?.name_warehouse || '',
        name_city: item.delivery_address_details?.name_city || '',
      };

      if (this.radioServiceType == 'WarehouseDoors') {
        this.inputDelivery.city = ''
        this.inputDelivery.name_city = ''
        this.inputDelivery.name_warehouse = ''
        this.inputDelivery.warehouse = ''

        this.searchAddressCityQuery = item.delivery_address_details?.name_city

        console.log(this.searchAddressCityQuery)
        this.inputAddress = {
          city_address: item.delivery_address_details?.uuid_ref_city,
          city_street: item.delivery_address_details?.uuid_ref_street,
          city_house: item.delivery_address_details?.number_house,
          city_flat: item.delivery_address_details?.number_flat,
        }

        this.searchAddressCityQuery = item.delivery_address_details?.name_city
        this.searchAddressStreetQuery = item.delivery_address_details?.name_street
      }

      if(item.delivery_address_details?.phone){
        this.inputDelivery.phone = item.delivery_address_details?.phone.replace('+38','') || ''
      }
      // // orderDialog('this.activeCities', this.activeCities);
      await this.fetchWarehouseByRef(this.inputDelivery.warehouse);
      this.updateNameAddress();
      // orderDialog('item.ttn', item);
      // orderDialog('item.ttn', Number(item.ttn));
      if (item.ttn > 0) {
        this.onSearchByTTN(true);
        this.changedStatus = false;
      } else {
        this.changedStatus = true;
      }
      if (this.settingDialog === 'supply') {
        this.states = await depotService.getAllStates({
          type: 'DELIVERY_OUT',
        });
        // orderDialog('item.id_state', item);
        const nextStates = await supplyService.getNextStatusId(item.id_state);
        const nextStateIds = nextStates.map((state) => state.id_state_next);
        if (nextStateIds.length) {
          this.filteredStates = this.states.map(
            // eslint-disable-next-line array-callback-return,consistent-return
            (state) => {
              if (nextStateIds.includes(state.id.toString())) {
                return state;
              }
              if (item.id_state === state.id.toString()) {
                return state;
              }
            },
          );
          this.filteredStates = this.filteredStates.filter((item) => item);
        }
        // orderDialog('filteredStates', this.filteredStates);
      }
      this.copySender = {
        ...this.inputSender
     }

     this.copyAddress = {
      ...this.inputAddress
     }
     console.log(this.inputDelivery)
     this.copyDelivery = {
        ...this.inputDelivery
     }
     this.copyCrmItem = {
        ...this.inputCrmItem
     }

     this.copyRadioServiceType = this.radioServiceType

      this.changed = false;
      this.loading = false;

      // this.getMetrics();
      return true
    },
    async onInputDeliveryValue(property) {
      try {
        if (property === 'phone') {
          if (this.inputDelivery.phone) {
            this.delivery.items = await addressService.getAddressesList({
              phone: this.inputDelivery.phone,
              uselike: true,
            });
            if (this.delivery.items) {
              this.delivery.phoneMenu = true;
            }
          }
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async onChangeDeliveryAddress(property, addressObject) {
      if (typeof addressObject === 'object') {
        this.deliveryAddress = {
          ...addressObject,
        };
        if (this.deliveryAddress.name) {
          this.inputDelivery.name = this.deliveryAddress.name;
        }
        if (this.deliveryAddress.surname) {
          this.inputDelivery.surname = this.deliveryAddress.surname;
        }
        if (this.deliveryAddress.phone) {
          this.inputDelivery.phone = this.deliveryAddress.phone.replace('+38', '');
        }
        this.inputDelivery.city = this.deliveryAddress.uuid_ref_city;

        await this.onSelectCity(this.inputDelivery.city, 'delivery');
        this.inputDelivery.warehouse = this.deliveryAddress.uuid_ref_warehouse;
        // if (this.deliveryAddress.uuid_ref_city) {
        //   this.inputDelivery.city = this.deliveryAddress.uuid_ref_city;
        // }
        // if (this.deliveryAddress.uuid_ref_warehouse) {
        //   this.inputDelivery.warehouse = this.deliveryAddress.uuid_ref_warehouse;
        // }
        this.inputDelivery.email = this.deliveryAddress.email;
        if (this.deliveryAddress.uuid) {
          this.inputDelivery.uuid = this.deliveryAddress.uuid;
        }
      } else if (typeof addressObject === 'string') {
        if (property === 'phone') {
          this.inputDelivery.phone = addressObject;
        }
        this.inputDelivery.uuid = '';
      } else if (addressObject === null) {
        this.inputDelivery[property] = null;
      }
    },
    async onInputSenderValue(property) {
      try {
        if (property === 'phone') {
          if (this.inputSender.phone) {
            this.sender.items = await addressService.getAddressesList({
              phone: this.sender.phone,
              uselike: true,
            });
            if (this.sender.items) {
              this.sender.phoneMenu = true;
            }
          }
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    onChangeSenderAddress(property, addressObject) {
      if (typeof addressObject === 'object') {
        this.senderAddress = {
          ...addressObject,
        };
        if (this.senderAddress.name) {
          this.inputSender.name = this.senderAddress.name;
        }
        if (this.senderAddress.surname) {
          this.inputSender.surname = this.senderAddress.surname;
        }
        if (this.senderAddress.phone) {
          this.inputSender.phone = this.senderAddress.phone.replace('+38', '');
        }
        this.inputSender.warehouse = this.senderAddress.uuid_ref_warehouse;
        this.inputSender.city = this.senderAddress.uuid_ref_city;

        if (this.senderAddress.uuid) {
          this.inputSender.uuid = this.senderAddress.uuid;
        }
      } else if (typeof addressObject === 'string') {
        if (property === 'phone') {
          this.inputSender.phone = addressObject;
        }
        this.inputSender.uuid = '';
      } else if (addressObject === null) {
        this.inputSender[property] = null;
      }
    },
  },
};
