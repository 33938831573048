<template>
  <div style="z-index:100">
    <VCard width="400">
      <VList dense class="py-2">
        <VCardSubtitle class="pb-1 pt-0">{{ this.language.SMS_MESSAGE }} {{ `(${item.name || '--'} – ${item.phone || '--'})`}} </VCardSubtitle>
        <VListItem>
          <VTextarea
            solo
            hide-details="auto"
            :label="this.language.WRITE_MSG"
            counter="160"
            maxLength="160"
            v-model="smsQuery"
            v-on:keyup="onKeyEnter"
            :error-messages="SMSErrors"
            @input="$v.$touch()"
            rows="3"
          >
            <template slot="append">
              <v-slide-x-transition>
                <VIcon
                  v-if="smsQuery.length > 0"
                  @click="showConfirmedDialog"
                  :disabled="loading || ($v.$invalid || !!SMSErrors.length)"
                >
                  mdi-email-fast-outline
                </VIcon>
              </v-slide-x-transition>
            </template>
          </VTextarea>
        </VListItem>
        <VDivider />
        <VCardSubtitle class="pb-0 pt-1">{{ this.language.CHOOSE_FROM_SMS_LAYOUT }}</VCardSubtitle>
        <div v-if="layout.length > 0 && !loadingLayoutList" class="template-list">
          <VListItem
            ripple
            class="cursor-pointer"
            @click="onChooseLayout(item)"
            v-for="item in layout"
            :key="item.uuid"
            :class="{ active: chosenLayout?.uuid === item.uuid }"
            v-on:dblclick="onEditLayout(item)"
          >
            <VRow align="center" justify="space-between">
              <div class="d-flex">
              <VCol cols="auto">
                <VBtn
                  class="btn-edit sm"
                  color="primary"
                  dark
                  depressed
                  small
                  @click.stop="onEditLayout(item)"
                >
                  <VIcon>
                    mdi-file-document-edit-outline
                  </VIcon>
                </VBtn>
              </VCol>
              <VCol cols="auto">
                {{ item.name }}
              </VCol>
              </div>
              <VCol cols="auto">
                <VBtn
                  color="error"
                  dark
                  depressed
                  small
                  @click.stop="onShowDeleteDialog(item)"
                  plain
                  :disabled="deleteLayout.show"
                  :loading="deleteLayout.show && deleteLayout.uuid === item.uuid"
                >
                  <VIcon>
                    mdi-close
                  </VIcon>
                </VBtn>
              </VCol>
            </VRow>
          </VListItem>
        </div>
        <VRow v-if="layout.length <= 0 && !loadingLayoutList" class="template-list" align="center">
          <VCol class="text-caption text-center error--text">
            {{ this.language.EMPTY_SMS_LAYOUT }}
          </VCol>
        </VRow>
        <VRow v-if="loadingLayoutList" class="template-list" align="center">
          <VCol class="text-caption text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </VCol>
        </VRow>
        <VDivider />
        <VListItem @click="onAddNewLayout" >
          <v-list-item-icon>
            <VIcon color="#808080">mdi-plus</VIcon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="color:#808080">{{
              this.language.ADD_SMS_LAYOUT
            }}</v-list-item-title>
          </v-list-item-content>
        </VListItem>
      </VList>
    </VCard>
    <AddNewLayout
      v-if="visibleLayout"
      :visible="visibleLayout"
      @close="visibleLayout = false"
      :orderData="item"
      :chosenLayout="chosenLayout"
      :type="layoutDialogType"
      :ttn="ttn"
    />
    <ConfirmDialogSlots
      :visible="showConfirmChangeStateDialog"
      @close="showConfirmChangeStateDialog = false"
      @onConfirm="onSendSMS"
      @onCancel="showConfirmChangeStateDialog = false"
      :typeAlert="'success'"
    >
      <template v-slot:header>
        {{ $t("sms.confirmDialog.sent.title") }}
      </template>
      <template v-slot:description>
        {{ $t("sms.confirmDialog.sent.desc") }}
      </template>
    </ConfirmDialogSlots>

    <ConfirmDialogSlots
      :visible="showConfirmDeleteLayout"
      @close="showConfirmChangeStateDialog = false"
      @onConfirm="onDeleteLayout"
      @onCancel="showConfirmDeleteLayout = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("sms.confirmDialog.delete_layout.title") }}
      </template>
      <template v-slot:description>
        {{ $t("sms.confirmDialog.delete_layout.desc") }}
      </template>
    </ConfirmDialogSlots>
  </div>
</template>

<script>
import SMSservice from "@/services/request/turboSMS/SMSservice";
import AddNewLayout from "./AddNewLayout.vue";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import EventBus from "../../events/EventBus";
import ConfirmDialogSlots from "@/components/dialog/ConfirmDialog.vue";

export default {
  mixins: [validationMixin, language, notifications],
  components: {
    AddNewLayout,
    ConfirmDialogSlots
  },
  props: {
    item: {
      require: false
    },
    ttn: {
      require: false
    }
  },
  validations: {
    smsQuery: { required },
    phoneNumber: { required }
  },

  data: () => ({
    deleteLayout: {
          show:false,
          uuid:null
        },
    disableSend:false,
    showConfirmChangeStateDialog: false,
    showConfirmDeleteLayout:false,
    smsQuery: "",
    visibleLayout: false,
    loading: false,
    loadingLayoutList: false,
    chosenLayout: {
      uuid: null
    },
    queryParams: [],
    layoutDialogType: "add",
    layout: [],
    deletedUUID:null
  }),
  mounted() {
    EventBus.$on("add-template", () => {
      this.visibleLayout = false;
      this.layout = [];
      this.getLayoutList();
    });
    EventBus.$on("edit-template", () => {
      this.visibleLayout = false;
      this.layout = [];
      this.getLayoutList();
      this.chosenLayout = {};
      this.layoutDialogType = "add";
    });
    this.getLayoutList();
  },
  watch: {
    visibleLayout: {
      deep: true,
      handler(e) {
        if (!e) {
          this.layoutDialogType = "add";
        }
      }
    },
    smsQuery: {
      deep: true,
      handler(smsQuery) {
        // if (e.includes("%%ttn%%") && this.DeliveryTTN !== null) {
        //   this.smsQuery = e.replace("%%ttn%%", this.ttn);
        // }
        if (smsQuery !== this.chosenLayout.template) {
          this.chosenLayout = {
            uuid: null
          };
        }
      }
    }
  },
  computed: {
    Values(){
      return [
        { ttn: this.ttn },
        { name: this.item.name },
        { surname: this.item.surname },
        { phone: this.item.phone },
        { city: this.item.city_name },
        { warehouse: this.item.warehouse_name }
      ];
    },
    SMSErrors() {
      const errors = [];
      if (!this.$v.smsQuery.$dirty) {
        return errors;
      }
      // if (!this.$v.phoneNumber.$dirty) {
      //   return errors;
      // }      

      this.Values.forEach(value => {
        const valueKey = Object.keys(value)[0];
        if(this.smsQuery.includes(`{${valueKey}}`)){
          if(!value[valueKey]){
           errors.push(this.$t(`sms.errors.${valueKey}`));
          return errors;
          }
        }

      })


      // if (this.smsQuery.includes("%%ttn%%")) {
      //   if (this.DeliveryTTN === null) {
      //     errors.push(this.language.DELIVERY_CONTAIN_TTN);
      //     return errors;
      //   }
      // }

      if (!this.$v.phoneNumber.required) {
        errors.push(this.language.isRequired(this.language.DELIVERY_PHONE));
        return errors;
      }

      // eslint-disable-next-line no-unused-expressions
      !this.$v.smsQuery.required &&
        errors.push(this.language.isRequired(this.language.SMS_MESSAGE));
      return errors;
    },
    phoneNumber() {
      return this.item.phone;
    },
    DeliveryTTN() {
      return this.ttn;
    }
  },
  methods: {

    showConfirmedDialog(){
      if(!this.$v.$invalid && this.SMSErrors.length === 0){
        this.showConfirmChangeStateDialog = true
      }
    },
    onKeyEnter(e) {
    },
    onEditLayout(item) {
      this.layoutDialogType = "edit";
      this.visibleLayout = true;
      this.chosenLayout = item;
    },
    async getLayoutList() {
      try {
        this.loadingLayoutList = true;
        const response = await SMSservice.getLayoutList();
        this.layout = response;
      } catch (e) {
        this.setErrorNotification(e);
      } finally {
        this.loadingLayoutList = false;
      }
    },
    checkValidations(){
      
    },
    findTemplateInSms() {
      // this.this.queryParams = []

      this.Values.forEach(value => {
        const valueKey = Object.keys(value)[0];
        if (this.smsQuery.includes(`{${valueKey}}`)) {
          this.queryParams.push({
            value: value[valueKey],
            value_key: valueKey
          });
        }
      });
    },
    onShowDeleteDialog({uuid}){
      this.deletedUUID = uuid
      this.showConfirmDeleteLayout = true
    },
    async onDeleteLayout(){
      const uuid = this.deletedUUID
      try{
        this.deleteLayout = {
          show:true,
          uuid:uuid
        }
        await SMSservice.deleteLayout(uuid);
        this.layout = [];
        this.getLayoutList();

      }catch(e){
        this.setErrorNotification(e);
      }finally{
      this.deletedUUID = null
      this.showConfirmDeleteLayout = false
        this.deleteLayout = {
          show:false,
          uuid:uuid
        }
      }

},
    async onSendSMS() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.SMSErrors.length === 0) {
        try {
          this.checkValidations()
          this.showConfirmChangeStateDialog = false
          this.loading = true;
          this.findTemplateInSms();

          const formData = new FormData();
          formData.append("recipients", [`38${this.item.phone.replace(/[^+\d]/g, "")}`]);
          formData.append("text", this.smsQuery);
          if (this.queryParams.length) {
            this.queryParams.forEach(param => {
              formData.append(param.value_key, param.value);
            });
          }

          const message = await SMSservice.sendSMS(formData);
          if (message.response_code === 801) {
            console.warn(message)
            this.setSuccessNotification(this.language.SUCCESS_MESSAGE_ACCEPTED);
            this.$v.$reset();
            this.smsQuery = "";
            this.loading = false;
          } else {
            this.setErrorNotification(message.response_status);
            this.queryParams = [];
            this.loading = false;
          }
        } catch (e) {
          this.setErrorNotification(e);
          this.queryParams = [];
          this.loading = false;
        }
      }
    },
    onAddNewLayout() {
      this.layoutDialogType = "add";
      this.visibleLayout = true;
    },
    onChooseLayout(item) {
      this.$v.$touch();
      this.chosenLayout = item;
      this.smsQuery = item.template;
    }
  }
};
</script>

<style lang="scss" scoped>
.active {
  background: rgba(0, 81, 255, 0.103) !important;
  z-index: 0;
}
.template-list {
  max-height: 210px;
  height:100%;
  min-height: 210px;
  overflow: scroll;
}
</style>
