<template>
  <div class="orderDialog__availableProducts mt-2">
    <v-row class="pa-0 ma-0" justify="space-between" align="center">
      <!-- <v-col cols="2" class="pt-0 pl-0">
        <VBtn class="br-10 text-transform-none" color="primary" plain @click="$emit('back')">
          <VIcon left>
            mdi-arrow-left
          </VIcon>
          {{ $t("btn.back") }}
        </VBtn>
      </v-col> -->
      <v-col cols="6" class="pt-0 " xl="4" lg="4" md="4" sm="6">
        <VTextField
          prepend-inner-icon="mdi-magnify"
          dense
          :label="$t('form.search')"
          v-model="searchString"
          @input="onSearchProduct"
          hide-details="true"
          clearable
          ref="searchField"
          solo
          :loading="isLoadingSearch"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0" justify="space-between" align="center">
      <v-col cols="auto" class="py-0 my-0">
        <chip-tab
          :items="chipTabsItems"
          @onClickChipTab="onClickChipTab"
          :loadingChips="loadingChips || Boolean(searchString)"
          :resetChip="Boolean(searchString)"
        />
      </v-col>
    </v-row>

    <VSimpleTable
      fixed-header
      class="my-table supply"
      style="min-height:300px"
      dense
      v-if="products.length && !isloadingProducts"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="x-sm"></th>
            <th>
              <v-row
                align="center"
                justify="start"
                class="cursor-pointer"
                @click="nameFilterA = !nameFilterA"
                >Назва
                <div class="d-flex flex-column" align="center" no-gutters>
                  <VIcon dense style="line-height:0.4" :color="getColorForUp">
                    mdi-menu-up
                  </VIcon>
                  <VIcon dense style="line-height:0.4" :color="getColorForDown">
                    mdi-menu-down
                  </VIcon>
                </div>
              </v-row>
            </th>
            <th>{{ $t("table.bar_code") }}</th>
            <!-- <th>{{ $t("table.option") }}</th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.uuid">
            <td class="x-sm">
              <img
                v-if="!product.product_details.path_img_thumb && isImgLoaded"
                :src="require('@/assets/images/supply-item.png')"
                class="image-placeholder"
              />
              <v-img
                v-else
                :src="product.product_details.path_img_thumb"
                class="image "
                v-show="isImgLoaded"
                @load="showImg"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                    style="min-height:60px"
                  >
                    <v-progress-circular
                      indeterminate
                      :size="20"
                      :width="2"
                      color="green lighten-1"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </td>
            <td>{{ product.product_details.name }}</td>
            <td>{{ product.product_details.bar_code }}</td>
            <!-- <td class="lg">
              <VSelect
                v-if="typeof product.options_details !== 'undefined'"
                :items="product.options_details"
                item-text="name"
                item-value="uuid"
                :placeholder="$t('supply.choose_option')"
                v-model="checkOption"
                hide-details
                dense
                outlined
              >
                <template slot="item" slot-scope="{ item }">
                  {{ item.name }}
                </template>
                <template slot="selection" slot-scope="{ item }">
                  {{ item.name }}
                </template>
              </VSelect>
              <span v-else> --- </span>
            </td> -->
            <td>
              <VBtn
                icon
                v-if="typeof product.options_details !== 'undefined'"
                @click="setProductOption($event, product, 'addProduct')"
              >
                <VIcon>mdi-send</VIcon>
              </VBtn>
              <VBtn icon v-else @click="setProduct($event, product, 'addProduct')">
                <VIcon>mdi-send</VIcon>
              </VBtn>
            </td>
          </tr>
          <tr>
            <div
              v-observer="{ nextPage: nextPage }"
              class="observer my-3"
              v-if="!loadMoreDisabled"
            ></div>
          </tr>
        </tbody>
      </template>
    </VSimpleTable>
    <div v-if="isloadingNextProducts">
      <v-progress-linear indeterminate />
    </div>
    <div class="text-center mt-1" v-if="!products.length && !isloadingProducts">
      {{ $t("table.noData") }}
    </div>
    <div class="products-loader__wrapper" v-if="isloadingProducts">
      <div class="products-loader__inner"></div>
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import language from "../../../../../../mixins/language";
import notifications from "../../../../../../mixins/notifications";
import chipTab from "@/components/common/chipTab.vue";
import depotService from "@/services/request/depot/depotService";
import user from "@/mixins/user";
import EventBus from "@/events/EventBus";

export default {
  name: "crmFormListProduct",
  props: {},
  data: () => ({
    checkOption: "",
    searchString: "",
    isLoadingSearch: false,
    loadMoreDisabled: false,
    loadingChips: false,
    isImgLoaded: false,
    isloadingNextProducts: false,
    isloadingProducts: true,
    tableAvaibleProductsFilter: {},
    chipTabsItems: {},
    products: [],
    nameFilterA: null,
    page: 1,
    perPage: 10
  }),
  methods: {
    async onClickChipTab(e) {
      this.categoryChipTab = e;
      this.isloadingProducts = true;
      this.loadingChips = true;
      this.tableAvaibleProductsFilter = {};
      console.log(e);

      if (e === this.$t("btn.all")) {
        if (this.searchString) {
          this.searchString = "";
        }
        await this.getProducts();
      } else {
        if (this.searchString) {
          this.searchString = "";
        }
        await this.getProducts({
          product_details__name: e,
          uselike: true
        });
      }
      this.nameFilterA = null;
      this.isloadingProducts = false;
      this.loadingChips = false;
    },
    onSearchProduct: _.debounce(async function() {
      if (!this.searchString) {
        this.categoryChipTab = null;
      }
      if (this.searchString) {
        this.isLoadingSearch = true;
        await this.getProducts({
          product_details__name: this.searchString,
          uselike: true
        });
        this.oldSerach = this.searchString;
        setTimeout(() => {
          this.isLoadingSearch = false;
        }, 300);
      } else {
        await this.getProducts();
      }
      this.$refs.searchField.focus();
    }, 500),
    async nextPage() {
      this.page += 1;
      const params = {};
      if (this.categoryChipTab || this.searchString !== "") {
        if (this.categoryChipTab && this.categoryChipTab !== this.$t("btn.all")) {
          params.product_details__name = this.categoryChipTab;
          params.uselike = true;
        }
        if (this.searchString !== "") {
          params.product_details__name = this.searchString;
          params.uselike = true;
        }
      }
      await this.getProducts(params, true);
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    async getChipTabsItems() {
      try {
        const params = {};
        if (!this.permissions.client) {
          params.uuid_user = this.item.uuid_user;
        }
        const chipItems = await depotService.getItemsChipList(params);
        this.chipTabsItems = chipItems;
        if (this.chipTabsItems) {
          this.chipTabsItems = this.chipTabsItems.sort((a, b) => b.count - a.count);
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async getProducts(params = {}, next = false) {
      console.log("this.getUser", this.getUser);
      try {
        if (!next) {
          this.page = 1;
        }
        console.log("tableAvaibleProductsFilter", this.tableAvaibleProductsFilter);
        if (next && this.tableAvaibleProductsFilter) {
          params.order = this.tableAvaibleProductsFilter.order;
          params.orderby = this.tableAvaibleProductsFilter.orderby;
        }

        if (next) {
          this.isloadingNextProducts = true;
        } else {
          this.isloadingProducts = true;
        }
        const newItems = await depotService.getMyProducts({
          groupby: "uuid_product",
          // groupby: 'product_details.bar_code',
          id_state: 810,
          ...params,
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          uuid_user: this.getUser.uuid
        });

        if (next) {
          this.products.push(...newItems);
        } else {
          this.products = newItems;
        }

        // // // orderDialog('products', this.products);
        // this.products.forEach(product => {
        //   // eslint-disable-next-line no-param-reassign
        //   product.amount = product.count;
        //   // eslint-disable-next-line no-param-reassign
        //   product.add_uuid_option = null;
        //   if (product.count < 1) {
        //     // eslint-disable-next-line no-param-reassign
        //     product.count = 0;
        //   } else {
        //     // eslint-disable-next-line no-param-reassign
        //     product.count = 1;
        //   }
        // });

        this.loadMoreDisabled = newItems.length < this.perPage;

        if (next) {
          this.isloadingNextProducts = false;
        } else {
          this.isloadingProducts = false;
        }
      } catch (e) {
        if (next) {
          this.isloadingNextProducts = false;
        } else {
          this.isloadingProducts = false;
        }
        this.isloadingProducts = false;
        this.setErrorNotification(e);
      }
    },
    setProduct(event, product, type) {
      EventBus.$emit("crm-form-setProduct", product);
      EventBus.$emit("on-tab-list-product", false);
    },
    setProductOption(event, product, type) {
      const productOption = product.options_details.filter(item => item.uuid === this.checkOption);
        product.uuid_option = this.checkOption;
        EventBus.$emit("crm-form-setProduct", product);
        EventBus.$emit("on-tab-list-product", false);
      // if (productOption.length) {
      // } else {
      //   this.setErrorNotification(this.$t("supply.choose_option"));
      // }
    }
  },
  mounted() {
    this.getProducts();
    this.getChipTabsItems();
  },
  computed: {
    getColorForUp() {
      if (this.nameFilterA !== null) {
        if (this.nameFilterA === true) {
          return "grey darken-2";
        } else {
          return "grey lighten-1";
        }
      } else {
        return "grey lighten-1";
      }
    },
    getColorForDown() {
      if (this.nameFilterA !== null) {
        if (this.nameFilterA === true) {
          return "grey lighten-1";
        } else {
          return "grey darken-2";
        }
      } else {
        return "grey lighten-1";
      }
    }
  },
  validations: {},
  mixins: [validationMixin, language, notifications, user],
  components: {
    chipTab
  }
};
</script>
<style lang="scss"></style>
