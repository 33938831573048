<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <v-menu
          ref="menuFirstDate"
          v-model="menuFirstDate"
          :close-on-content-click="false"
          :return-value.sync="filterData.firstDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
          
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterData.firstDate"
              :label="$t('crm.firstDate')"
              prepend-inner-icon="mdi-calendar"
              readonly
              filled
              v-bind="attrs"
              v-on="on"
              hide-details="auto"
              
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterData.firstDate"
            scrollable
            :locale="currentLanguage.key"
            min="2021"
            :max="new Date().toISOString().substr(0, 10)"
          >
            <v-spacer />
            <v-btn text color="primary" @click="menuFirstDate = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menuFirstDate.save(filterData.firstDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <v-menu
          ref="menuLastDate"
          v-model="menuLastDate"
          :close-on-content-click="false"
          :return-value.sync="filterData.lastDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterData.lastDate"
              :label="$t('crm.lastDate')"
              prepend-inner-icon="mdi-calendar"
              readonly
              filled
              v-bind="attrs"
              v-on="on"
              hide-details="auto"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterData.lastDate"
            scrollable
            :locale="currentLanguage.key"
            min="2021"
            :max="new Date().toISOString().substr(0, 10)"
          >
            <v-spacer />
            <v-btn text color="primary" @click="menuLastDate = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menuLastDate.save(filterData.lastDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row align="center" justify="space-between">
      <v-col cols="4">
        <span class="text-subtitle-2"> {{ $t("crm.state") }}: </span>
      </v-col>
      <v-col cols="7">
        <v-select
          :items="states"
          item-text="name"
          item-value="uuid"
          v-model="filterData.chosenState"
          hide-details="auto"
          :error-messages="stateError"
          dense
          outlined
          :placeholder="$t('crm.choose_state')"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template slot="item" slot-scope="{ item }">
            <div class="user-state">
              {{ item?.name }}
              <span :style="`background-color: ${item.color};`"></span>
            </div>
          </template>
          <template slot="selection" slot-scope="{ item }">
            <div class="user-state">
              {{ item?.name }}
              <span :style="`background-color: ${item.color};`"></span>
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row
      align="center"
      v-for="utm in filterData.utm_filters"
      :key="utm.text"
      justify="space-between"
    >
      <v-col cols="4">
        <span class="text-subtitle-2">{{ utm.text }}: </span>
      </v-col>
      <v-col cols="7" class="d-flex align-center">
        <v-switch inset v-model="utm.show" hide-details="auto" @change="getFiltereUTM(utm.text)" :disabled="utm.loading"></v-switch>
        <v-autocomplete 
            v-model="utm.value"
            :items="utm.values"
            outlined
            hide-details
            dense
            :disabled="!utm.show"
            :loading="utm.loading"
          ></v-autocomplete>
        <!-- <v-text-field v-model="utm.value" hide-details="auto" dense outlined > -->

        <!-- </v-text-field> -->
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <v-btn color="primary" @click="onSave" :disabled="!isChanged">{{ $t("btn.apply_filters") }}</v-btn>
      </v-col>
      <v-slide-x-reverse-transition>
        <v-col v-if="isChanged" cols="auto">
          <v-btn color="grey" @click="onResetFilter" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-slide-x-reverse-transition>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import notifications from "@/mixins/notifications";
import crmService from "@/services/request/crm/crmService";

export default {
  mixins:[notifications,validationMixin],
  computed: {
    ...mapGetters(["currentLanguage"]),
    stateError() {
      const errors = [];
      if (!this.$v.filterData.chosenState.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.filterData.chosenState.required && errors.push(this.$t("form.errors.StatusRequired"));
      return errors;
    }
  },
  props: {
    states: {
      require: true
    },
    uuid_board:{
      require:true
    },
    resetFilters: {
      require: true
    }
  },
  mounted() {
    this.copyFilter = _.cloneDeep(this.filterData);
  },
  methods: {
   async getFiltereUTM(utmValue){
      try{
        this.filterData.utm_filters.forEach(async utm => {
          if(utm.text === utmValue){
            if(utm.values.length)return
            utm.loading = true
            utm.values = await crmService.crmUTMlist({
              uuid_board:this.uuid_board,
              utm_parameter:utm.text
            })
            utm.loading = false
          }
        })
      }catch(e){
        this.setErrorNotification(e);
      }
    },
    onSave() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const params = {
        date_start: this.filterData.firstDate,
        date_end: this.filterData.lastDate,
        uuid_user_state: this.filterData.chosenState
      };
      this.filterData.utm_filters.forEach(utm => {
        if (utm.value && utm.show) {
          params[utm.text] = utm.value;
        }
      });
      this.$emit("onSave", params);
    //   this.isChanged = !this.isChanged
    },
    onResetFilter() {
      this.$v.$reset();
      this.$emit('resetChart')
      this.filterData = {
        firstDate: new Date().toISOString().substr(0, 10),
        lastDate: new Date().toISOString().substr(0, 10),
        chosenState: "",
        utm_filters: [
          {
            show: false,
            value: "",
            text: "utm_source",
            values:"utm_sources"
          },
          {
            show: false,
            value: "",
            text: "utm_medium",
            values:"utm_medium"
          },
          {
            show: false,
            value: "",
            text: "utm_term",
            values:"utm_terms"
          },
          {
            show: false,
            value: "",
            text: "utm_content",
            values:"utm_contents"
          },
          {
            show: false,
            value: "",
            text: "utm_campaign",
            values:"utm_campaigns"
          }
        ]
      };
    }
  },
  validations: {
    filterData: {
      chosenState: { required }
    }
  },
  watch: {
    filterData: {
      deep: true,
      handler() {
        console.warn(this.filterData);
        this.isChanged = !_.isEqual(this.filterData, this.copyFilter);
      }
    },
    resetFilters() {
      this.onResetFilter();
    }
  },
  data: () => ({
    utm_sources:[],
    utm_mediums:[],
    utm_terms:[],
    utm_contents:[],
    utm_campaigns:[],
    menuFirstDate: false,
    menuLastDate: false,
    isChanged: false,
    filterData: {
      firstDate: new Date().toISOString().substr(0, 10),
      lastDate: new Date().toISOString().substr(0, 10),
      chosenState: "",
      utm_filters: [
          {
            show: false,
            value: "",
            text: "utm_source",
            values:[],
            loading:false,
          },
          {
            show: false,
            value: "",
            text: "utm_medium",
            values:[],
            loading:false,
          },
          {
            show: false,
            value: "",
            text: "utm_term",
            values:[],
            loading:false,
          },
          {
            show: false,
            value: "",
            text: "utm_content",
            values:[],
            loading:false,
          },
          {
            show: false,
            value: "",
            text: "utm_campaign",
            values:[],
            loading:false,
          }
        ]
    }
  })
};
</script>

<style></style>
