<template>
  <VRow class="pa-0 ma-0">
    <VCol cols="12" >
      <div class="crmDialogItem__utm">
        <div class="crmDialogItem__utm-item">
          <div class="name">utm_source</div>
          <img src="/img/icom_utm.svg" alt="">
          <div class="value">{{ data.utm_source || '--' }}</div>
        </div>
        <div class="crmDialogItem__utm-item">
          <div class="name">utm_medium</div>
          <img src="/img/icom_utm.svg" alt="">
          <div class="value">{{ data.utm_medium || '--' }}</div>
        </div>
        <div class="crmDialogItem__utm-item">
          <div class="name">utm_term</div>
          <img src="/img/icom_utm.svg" alt="">
          <div class="value">{{ data.utm_term || '--' }}</div>
        </div>
        <div class="crmDialogItem__utm-item">
          <div class="name">utm_content</div>
          <img src="/img/icom_utm.svg" alt="">
          <div class="value">{{ data.utm_content || '--' }}</div>
        </div>
        <div class="crmDialogItem__utm-item">
          <div class="name">utm_campaign</div>
          <img src="/img/icom_utm.svg" alt="">
          <div class="value">{{ data.utm_campaign || '--' }}</div>
        </div>
      </div>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'infoDialogCrm',
  data: () => ({
  }),
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style scoped lang="scss">
.crmDialogItem__utm{
    &-item{
      display: flex;
      margin-bottom: 12px;
      .name{
        display: block;
        width: 130px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #676767;
      }
      .value{
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #676767;
      }
      img{
        margin-right: 24px;
      }
    }
  }
</style>
