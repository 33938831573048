<template>
  <div>
    <VSheet>
      <div class="chat__wrapper" :class="{ sm: $vuetify.breakpoint.smAndDown }">
        <VRow no-gutters align="center" class="chat__title">
          <VCol :cols="permissions.client ? 12 : 10">
            <VSubheader>
              <span v-if="permissions.client">
               {{$t('chat.manager_communication')}}
              </span>
              <span v-else>
               {{$t('chat.client_communication')}}
                <b>{{ `${this.item.user_details.name} ${this.item.user_details.surname}` }} </b>
              </span>
            </VSubheader>
          </VCol>
          <!-- <VCol cols="2" class="text-right pr-3" v-if="!permissions.client">
            <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <VBadge bordered color="warning" icon="mdi-message-text" overlap>
                  <VBtn icon small>
                    <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </VBtn>
                </VBadge>
              </template>
              <VCard max-width="300">
                <VCardText>
                  текст повідомлення про причину блокування
                </VCardText>
              </VCard>
            </v-menu>
          </VCol> -->
        </VRow>
        <VDivider />
        <div class="chat__content" :class="{ sm: $vuetify.breakpoint.smAndDown }">
          <div
            class="chat__msgcontent"
            ref="msgContent"
            v-if="!loading"
            :class="{ sm: $vuetify.breakpoint.smAndDown }"
          >
            <div
              v-for="item in items"
              :key="item.uuids"
              class="chat__msg"
              :class="{ 'not-client': getUserProperty('uuid') !== item.uuid_user }"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="avatar"
                    v-if="getUserProperty('uuid') !== item.uuid_user"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img :src="item.user_details.path_img" alt="" />
                  </div>
                </template>
                <span>{{ `${item.user_details.name} ${item.user_details.surname}` }}</span>
              </v-tooltip>
              <div class="chat__msg-content" :class="{ sm: $vuetify.breakpoint.smAndDown }">
                <p class="text">{{ item.message }}</p>
                <i class="time">{{ moment(item.time).format("LT") }}</i>
              </div>
            </div>
          </div>
          <div
            v-else
            class="w-100 bg-white d-flex justify-content-center align-center chat__content"
          >
            <transition name="fade">
              <div>
                <VRow no-gutters align="center" justify="center">
                  <VProgressCircular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                  ></VProgressCircular>
                </VRow>
              </div>
            </transition>
          </div>
          <VRow class="chat__footer" no-gutters>
            <VCol cols="12">
              <v-textarea
                filled
                :label="$t('form.msg')"
                :rows="2"
                hide-details="auto"
                v-model="inputMessage"
                class="send-input"
                @keyup="keyup"
                ref="inputMsg"
              >
                <template v-slot:append>
                  <v-scroll-x-transition>
                    <VBtn
                      fab
                      color="white"
                      small
                      elevation="1"
                      :disabled="inputMessage.length === 0"
                      class="send-btn"
                      @click="onSendMsg"
                    >
                      <v-icon color="primary" :size="20" transition="scale-transition">
                        mdi-send
                      </v-icon>
                    </VBtn>
                  </v-scroll-x-transition>
                </template>
              </v-textarea>
            </VCol>
          </VRow>
        </div>
        <VRow no-gutters> </VRow>
      </div>
    </VSheet>
  </div>
</template>

<script>
import supplyService from "../../services/request/supply/supplyService";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import moment from "moment";
import Vue from "vue";

Vue.prototype.moment = moment;

export default {
  mixins: [notifications, user],
  props: {
    item: {
      require: true,
      type: Object
    },
    tab: {
      require: false,
      type: String
    }
  },
  data: () => ({
    inputMessage: "",
    items: [],
    loading: false
  }),
  async mounted() {
    // console.log(this.item);
    try {
      this.loading = true;
      const newItem = await supplyService.chatLog(this.item.uuid);
      this.items = newItem.reverse();
      this.loading = false;
    } catch (e) {
      this.setErrorNotification(e);
    }
    setTimeout(() => {
      this.scrollToEnd();
      this.$refs.inputMsg.$refs.input.focus();
    }, 200);
    this.intervalFetchData = setInterval(async () => {
      this.getMsg();
    }, 5000);
  },
  destroyed() {
    clearInterval(this.intervalFetchData);
  },
  methods: {
    keyup(event) {
      if ((event.keyCode == 91 && event.composed) || (event.keyCode == 86 && event.ctrlKey)) {
        // console.log(event);
        // this.onSendMsg();
      }
    },
    scrollToEnd() {
      const container = this.$el.querySelector(".chat__msgcontent");
      container.scrollTop = container.scrollHeight + 16;
    },
    async getMsg() {
      try {
        const newItem = await supplyService.chatLog(this.item.uuid);
        this.items = newItem.reverse();
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async onSendMsg() {
      try {
        const formData = new FormData();
        formData.append("uuid_object", this.item.uuid);
        formData.append("message", this.inputMessage);
        this.inputMessage = "";
        await supplyService.chatMessage(formData);
        this.getMsg();
        setTimeout(() => {
          this.scrollToEnd();
        }, 500);
      } catch (e) {
        this.setErrorNotification(e);
      }
    }
  }
};
</script>

<style lang="scss">
.chat {
  &__wrapper {
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    width: 100%;
    height: auto;
    min-height: 50vh;
    &.sm {
      border-radius: 5px 5px 0 0px;
    }
    &__title {
      &-time {
        height: 48px;
        font-weight: 500;
      }
    }
  }
  &__content {
    max-width: 80%;
    margin: auto;
    height: auto;
    min-height: inherit;
    position: relative;
    padding-top: 10px;
    display: flex;
    flex-direction: column-reverse;
    &.sm {
      max-width: 100%;
    }
    .avatar {
      width: 30px;
      height: 30px;
      margin-right: 5px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  &__msgcontent {
    max-height: 40vh;
    height: auto;
    overflow-y: scroll;
    margin-bottom: 80px;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 8px !important;
      height: 12px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(113, 113, 113,.3);
      border: thin solid rgb(245, 245, 244);
      border-radius: 9em;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: darken(rgba(113, 113, 113,.5), 4);
    }
    &.sm {
      padding: 0 10px;
    }
  }
  &__footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1000;
  }
  &__msg {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:last-child {
      .chat__msg-content {
        border-radius: 10px 15px 0 15px;
      }
    }
    &-content {
      flex: 0 1 auto;
      width: auto;
      padding: 8px 8px 6px;
      background: #5d90c1;
      border-radius: 10px 15px 10px 15px;
      display: flex;
      color: #ffff;
      max-width: 40%;
      &.sm {
        max-width: 60%;
      }

      .text {
        margin: 0;
        word-break: break-word;
        line-height: 1.3125;
        text-align: initial;
        display: flow-root;
        unicode-bidi: plaintext;
        font-weight: 500;
      }
      .time {
        position: relative;
        bottom: auto !important;
        margin-left: 0.4375rem;
        margin-right: 0.2rem;
        font-size: 12px;
      }
    }
    &.not-client {
      &.chat__msg {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }

      .chat__msg-content {
        display: flex;
        background: #f4f5f3;
        border-radius: 15px 10px 15px 10px;
        color: #000000;

        .text {
          font-weight: 400;
        }
      }
    }
  }
}
.send-btn {
  transition: 0.4s ease;
  &:hover {
    background: #2f92e2 !important;
    i {
      color: #ffff !important;
    }
  }
}
</style>
