<template>
  <div class="filter__wrapper">
    <div class="filter__inner">
      <VCol cols="12">
        <VMenu :close-on-content-click="false" :offset-y="true" v-model="isFilterMenu">
          <template v-slot:activator="{ on, attrs }">
            <VBtn color="green" outlined v-bind="attrs" v-on="on">
              <VIcon left>mdi-filter-variant</VIcon>
              {{ $t("supply.filters") }}
            </VBtn>
          </template>
          <VCard>
            <VCardTitle>
              {{ $t("supply.searched_filters") }}
              <VDivider class="ml-5" />
            </VCardTitle>
            <VCardText>
              <VRow>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VTextField
                    dense
                    hide-details
                    outlined
                    label="#"
                    class="text-caption"
                    v-model="filterProps.number"
                    color="green"
                    inputmode="numeric"
                  />
                </VCol>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VTextField
                    dense
                    hide-details
                    outlined
                    label="ТТН"
                    class="text-caption"
                    color="green"
                    v-model="filterProps.ttn"
                    inputmode="numeric"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VTextField
                    dense
                    hide-details
                    outlined
                    :label="$t('form.name')"
                    class="text-caption"
                    color="green"
                    v-model="filterProps.delivery_address_details__name"
                  />
                </VCol>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VTextField
                    dense
                    hide-details
                    outlined
                    :label="$t('form.surname')"
                    class="text-caption"
                    color="green"
                    v-model="filterProps.delivery_address_details__surname"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VTextField
                    dense
                    hide-details
                    outlined
                    :label="$t('table.phone')"
                    class="text-caption"
                    color="green"
                    v-model="filterProps.delivery_address_details__phone"
                    inputmode="numeric"
                  />
                </VCol>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VMenu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="-103"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="text-caption"
                  >
                    <template v-slot:activator="{ on, attrs }">
                        <VTextField
                          class="bg-white"
                          v-model="filterProps.time_created"
                          readonly
                          append-icon="mdi-calendar"
                          outlined
                          dense
                          :label="$t('form.time_created')"
                          hide-details
                          v-bind="attrs"
                          v-on="on"
                        />
                    </template>
                    <v-date-picker
                      :locale="currentLanguage.key"
                      no-title
                      :max="NextYear"
                      min="2021"
                      v-model="filterProps.time_created"
                      @input="dateMenu = false"
                    ></v-date-picker>
                  </VMenu>
                </VCol>
              </VRow>
            </VCardText>
            <VDivider class="mx-1" />
            <VCardActions>
              <!-- <VBtn black text block>
                Скасувати
              </VBtn> -->
              <VBtn class="success-bg white--text" block @click="onApply">
                {{ $t("supply.applyBtn") }}
              </VBtn>
            </VCardActions>
          </VCard>
        </VMenu>
        <VBtn
          class="ml-1"
          color="error darken-2"
          outlined
          v-if="Object.keys(chipItems).length > 1"
          @click="onClearFilter"
        >
          <VIcon>
            mdi-filter-variant-remove
          </VIcon>
        </VBtn>
      </VCol>
      <VCol class="py-0">
        <VRow no-gutters justify="center">
          <div v-for="(item, key) in chipItems" :key="key">
            <VChip
              v-if="item"
              class="ma-1"
              small
              outlined
              color="green darken-1"
              close
              @click:close="onRemoveFilter(key)"
            >
              <b>{{ FILTER_ITEMS[key] }}</b> : {{ item }}
            </VChip>
          </div>
        </VRow>
      </VCol>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user";
import { mapGetters } from "vuex";
import StatusCircle from "@/components/move-list/StatusCircle.vue";

export default {
  mixins: [user],
  data: () => ({
    filterProps: {},
    chipItems: {},
    dateMenu: false,
    isFilterMenu: false
  }),
  components: {
    StatusCircle
  },
  methods: {
    onClearFilter() {
      this.chipItems = {};
      this.filterProps = {};
      this.setChipsValue();
      this.onApply();
    },
    onRemoveFilter(e) {
      this.filterProps[e] = "";
      this.setChipsValue();
      this.onApply();
    },
    setChipsValue() {
      this.chipItems = {};
      Object.keys(this.filterProps).forEach(item => {
        if (this.filterProps[item] !== "") {
          this.chipItems[item] = this.filterProps[item];
        }
      });
    },
    onApply() {
      this.setChipsValue();
      this.isFilterMenu = false;
      this.$emit("onApplyFilter", this.filterProps);
    }
  },
  mounted() {
    this.filterProps = {
      ...this.filterPropsExact
    };
    this.setChipsValue();
  },
  props: {
    filterPropsExact: {
      require: true
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    NextYear() {
      const event = new Date().toISOString().substr(0, 12);
      return event;
    },
    FILTER_ITEMS() {
      return {
        number: "#",
        ttn: "TTН",
        delivery_address_details__name: this.$t("form.name"),
        delivery_address_details__surname: this.$t("form.surname"),
        delivery_address_details__phone: this.$t("form.phone_number"),
        time_created: this.$t("form.time_created"),
        state: this.$t("form.status")
      };
    }
  }
};
</script>

<style lang="scss">
.filter {
  &__wrapper {
    margin: 0 8px;
    height: auto;
    border: 1px solid #dedede;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
</style>
