<template>
  <div>
    <v-data-table
      fixed-header
      :headers="headers"
      :items="items"
      item-key="uuid"
      hide-default-footer
      :items-per-page="items.length"
      dense
      locale="uk"
      class="my-table supply"
      :no-data-text="$t('table.noData')"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.icon="{ item }">
        <div class="w-0">
          <VBtn
            color="red"
            small
            :disabled="blockedItem"
            @click="$emit('onClickDelete', item.uuid)"
            icon
          >
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.image="{ item }">
        <img
          v-if="!item.product_details.path_img_thumb"
          :src="require('@/assets/images/supply-item.png')"
          class="image-placeholder  sm "
        />
        <v-img
          v-else
          :src="item.product_details.path_img_thumb"
          class="image  sm "
          @click="showImgDialog(item.product_details.path_img)"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                :size="20"
                :width="2"
                color="green lighten-1"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.options="{ item }">
        {{ item.option_details.name || "--" }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.items_amount="{ item }">
        <div v-if="isChangeAmount">
          <ns-counter
            class="my-2 mx-auto"
            v-model="item.items_amount"
            :min="1"
            :max="settingDialog == 'supply' ? getMax(item) : 9999"
            @change="onChangeCounter(item)"
          />
        </div>
        <div v-else>
          {{ item.items_amount || 0 }}
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.items_available="{ item }">
        <div
          :class="{
            'error--text font-weight-bold':
              isChangeAmount && item.items_available < item.items_amount && settingDialog == 'crm'
          }"
        >
          {{ item.items_available || 0 }}
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.additional_sell_amount="{ item }">
        <div class="w-0">
          {{ item.additional_sell_amount }}
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.item_price="{ item }">
        <div v-if="isChangeAmount">
          <div
            class=" dashed-preview"
            @click="
              editPriceinput.visible = true;
              editPriceinput.uuid = item.uuid;
            "
            v-if="!editPriceinput.visible || editPriceinput.uuid != item.uuid"
          >
            {{ item.item_price || "0.00" }}
          </div>
          <v-row
            v-if="editPriceinput.visible && editPriceinput.uuid === item.uuid"
            justify="center"
            style="width:100px"
            class="mx-auto mx-1"
          >
            <v-text-field
              dense
              autofocus
              class="dashed-input"
              :min="0"
              hide-details="auto"
              elevation="0"
              v-model.number="item.item_price"
              @keypress="onKeyChangePrice($event, item)"
              ref="inputName"
              :disabled="editPriceinput.loading"
              @blur="onCloseByBlur(item.uuid)"
            >
              <template v-slot:append>
                <VBtn
                  icon
                  dense
                  :disabled="editPriceinput.loading"
                  color="yellow darken-2"
                  @click="onClosePriceEdit(item.uuid)"
                >
                  <VIcon dense>mdi-check-circle-outline</VIcon>
                </VBtn>
              </template>
            </v-text-field>
          </v-row>
        </div>
        <div v-else>
          {{ item.item_price || "0.00" }}
        </div>
      </template>
      <template v-slot:footer v-if="this.items.length && settingDialog === 'crm'">
        <hr />
        <div
          class="d-flex w-100"
          style="justify-content: flex-end; height:auto; background:#FAFAFA"
        >
          {{ getTotalPrice() }}
          <br>
          {{ getInitPrice() }}
          <br>
          {{ getProfit() }}
        </div>
      </template>
    </v-data-table>
    <img-dialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
    <ConfirmDialog
      :visible="confirmDialog"
      @close="confirmDialog = false"
      @onConfirm="
        onChangePrice(chosenUUIDPrice);
        confirmDialog = false;
      "
      @onCancel="
        stopBlur = false;
        confirmDialog = false;
      "
      :typeAlert="'warning'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.editPrice.title") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.editPrice.desc") }}
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import _ from "lodash";
import ConfirmDialog from "../../../dialog/ConfirmDialog.vue";
import imgDialog from "../../../products/imgDialog.vue";

export default {
  components: { imgDialog, ConfirmDialog },
  data: () => ({
    imgpath: "",
    imgDialog: false,
    headers: [],
    editPriceinput: {
      visible: false,
      uuid: null,
      loading: false
    },
    chosenUUIDPrice: "",
    confirmDialog: false,
    stopBlur: false
  }),
  props: {
    isAccessDeportInfo: {
      require: false
    },
    totalChosenProducts: {
      require: false
    },
    resetEditPriceTable: {
      require: false
    },
    resetCopyItems: {
      require: false
    },
    settingDialog: {
      require: false
    },
    items: {
      require: true,
      default: []
    },
    type: {
      require: true
    },
    loadingItems: {
      require: true
    },
    isImgLoaded: {
      require: true
    },
    blockedItem: {
      require: true
    },
    watchDelivery: {
      require: true
    },
    isChangeAmount: {
      require: false,
      default: false
    }
  },
  methods: {
    getTotalPrice() {
      if (this.items.length && this.settingDialog === "crm") {
        const str = this.totalChosenProducts.split(",")[1];
        const text = str.split(":")[0].replace(/ /g, "");
        const number = str.split(":")[1];

        return `${this.$t(`table.${text}`)} - ${number} ${this.$t("paymentPlan.uah")}`;
      } else {
        return "--";
      }
    },
    getInitPrice() {
      if (this.items.length && this.settingDialog === "crm") {
        const str = this.totalChosenProducts.split(",")[2];

        const text = str.split(":")[0].replace(/ /g, "");
        const number = str.split(":")[1];

        return `${this.$t(`table.${text}`)} - ${number} ${this.$t("paymentPlan.uah")}`;

      } else {
        return "--";
      }
    },
    getProfit() {
      if (this.items.length && this.settingDialog === "crm") {
        const str = this.totalChosenProducts.split(",")[3];

        const text = str.split(":")[0].replace(/ /g, "");
        const number = str.split(":")[1];

        return `${this.$t(`table.${text}`)} - ${number} ${this.$t("paymentPlan.uah")}`;
      } else {
        return "--";
      }
    },
    setCopyItems() {
      this.copyItems = JSON.parse(JSON.stringify(this.items));
    },
    // eslint-disable-next-line
    getMax({ product_details: { name }, uuid_product, items_amount, items_available }) {
      // eslint-disable-next-line
      const item = this.copyItems.find(copyItem => copyItem.product_details.name === name);
      if (item) {
        return +item.items_amount + +item.items_available;
      } else {
        // eslint-disable-next-line
        return +items_amount + +items_available;
      }
    },

    onCloseByBlur(uuid) {
      if (!this.stopBlur) {
        this.onClosePriceEdit(uuid);
      }
    },
    onClosePriceEdit(uuid) {
      this.stopBlur = true;
      const priceBefore = this.copyItems.find(copyItem => copyItem.uuid === uuid);
      const changedItem = this.items.find(copyItem => copyItem.uuid === uuid);

      if (priceBefore?.item_price === changedItem.item_price) {
        this.editPriceinput = {
          visible: false,
          uuid: null,
          loading: false
        };
        this.stopBlur = false;
        return false;
      }
      this.confirmDialog = true;
      this.chosenUUIDPrice = uuid;
      // this.onChangePrice(uuid);
    },
    onKeyChangePrice($event, item) {
      this.$checkNumberFloat(item.item_price, $event);
      if ($event.keyCode === 13) {
        this.onClosePriceEdit(item.uuid);
      }
    },
    async onChangePrice(uuid) {
      this.editPriceinput.loading = true;
      this.priceBeforeChange = this.copyItems.find(copyItem => copyItem.uuid === uuid);
      const changedItem = this.items.find(copyItem => copyItem.uuid === uuid);
      const params = {
        item: changedItem
      };
      if (this.priceBeforeChange) {
        params.priceBeforeChange = this.priceBeforeChange?.item_price;
      }
      this.$emit("onChangePrice", params);
    },
    onChangeCounter(item) {
      this.amountBeforeChange = this.copyItems.find(copyItem => copyItem.uuid === item.uuid);
      const params = {
        item: item
      };
      if (this.amountBeforeChange) {
        params.amountBeforeChange = this.amountBeforeChange?.items_amount;
      }
      this.$emit("onChangeCounter", params);
    },
    showImgDialog(image) {
      this.imgpath = image;
      this.imgDialog = true;
    }
  },
  mounted() {
    this.setCopyItems();
    this.headers = [
      {
        text: this.$t("table.photo"),
        align: "center",
        sortable: false,
        value: "image"
      },
      {
        text: this.$t("table.name"),
        align: "start",
        value: "product_details.name"
      },

      {
        text: this.$t("table.option"),
        align: "start",
        sortable: false,
        value: "options"
      },
      {
        text: this.$t("table.amount"),
        align: "center",
        value: "items_amount"
      }
    ];
    if (this.isAccessDeportInfo || this.settingDialog !== "crm") {
      this.headers[this.headers.length] = {
        text: this.$t("supply.available"),
        align: "center",
        value: "items_available",
        width: "5px",
        sortable: false
      };
    }

    if (this.watchDelivery) {
      this.headers.unshift({
        text: this.$t("table.action"),
        align: "center",
        sortable: false,
        value: "icon"
      });
    }
    if (this.settingDialog === "supply") {
      this.headers.splice(4, 0, {
        text: this.$t("table.bar_code"),
        align: "start",
        sortable: false,
        value: "product_details.bar_code"
      });
    }
    if (this.settingDialog === "crm") {
      this.headers.push({
        text: this.$t("table.additional_sell"),
        align: "center",
        sortable: false,
        value: "additional_sell_amount"
      });

      this.headers.push({
        text: this.$t("table.price"),
        align: "center",
        sortable: false,
        value: "item_price"
      });
      this.headers.push({
        text: this.$t("table.sum"),
        align: "center",
        sortable: false,
        value: "total_price"
      });
    }
  },
  watch: {
    items: {
      deep: true,
      handler(e) {
        console.log(e);
      }
    },
    watchDelivery: {
      deep: true,
      handler(e) {
        if (this.watchDelivery) {
          this.headers.unshift({
            text: this.$t("table.action"),
            align: "center",
            sortable: false,
            value: "icon"
          });
        } else {
          this.headers = this.headers.filter(header => header.value !== "icon");
        }
      }
    },
    "editPriceinput.uuid": {
      deep: true,
      handler(newUUID, oldUUID) {
        if (oldUUID) {
          // this.onClosePriceEdit(oldUUID)
        }
      }
    },
    resetCopyItems: {
      deep: true,
      handler(e) {
        this.setCopyItems();
      }
    },
    resetEditPriceTable: {
      deep: true,
      handler(e) {
        this.editPriceinput = {
          visible: false,
          uuid: null,
          loading: false
        };
        this.stopBlur = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dashed {
  &-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #e3e3e3;
    min-width: 100px;
    height: 30px;
    border-radius: 5px;
    cursor: text;
    &:hover {
      border: 2px dashed #c4c1c1;
    }
  }
  &-input {
    min-width: 100px;
    height: 30px;
  }
}
</style>
