<template>
  <VDialog v-model="visibility" max-width="1180px" content-class="cross__dialog order-dialog" scrollable>
    <slot> </slot>
  </VDialog>
</template>

<script>
import EventBus from "@/events/EventBus";

export default {
  props: {
    visible: Boolean,
    settingDialog: String,
    typeDialog: String
  },
  methods: {},
  mounted() {
    EventBus.$on("close-dialog", () => {
      this.$emit("close");
    });
  },
  computed: {
    visibility: {
      get() {
        if (this.settingDialog === "crm") {
          //   this.fetchData();
        }
        return this.visible;
      },
      set() {
        this.$emit("close");
        if (this.typeDialog === "add") {
          // this.dataReset();
        }
        // this.$destroy();
      }
    }
  }
};
</script>

<style lang="scss">
@import "orderDialog";
</style>
