<template>
  <v-card class="chart__wrapper"  :loading="isLoading" :disabled="isLoading">
    <v-card-text>
      <v-chart
        class="chart-container"
        :option="option"
        style="width:100%!important;height:400%"
        autoresize
        :loading="isLoading"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import { BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent
} from "echarts/components";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent
]);

export default {
  components: {
    VChart
  },
  data: () => ({
    option: {
      animationDuration: 1000,

      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [],
        splitLine: {
          show: false
        }
      },

      yAxis: {
        axisLine: { onZero: false },

        type: "value",
        splitLine: {
          show: true
        }
      },
      title: {
        left: "center",
        top: "center"
      },
      dataZoom: [
        {
          type: "inside"
        }
      ],
      tooltip: {
        axisPointer: {
          type: "line"
        },
        trigger: "axis"
      },
      grid: {
        top: "3%",
        bottom: "6%",
        left: "3%",
        right: "3%"
      },
      series: [
        {
          data: [],
          type: "line",
          smooth: true,
          symbol: "circle",
          symbolSize: 10,
          color: "#3874CB",

          lineStyle: {
            width: 4
          },
          itemStyle: {
            borderRadius: [5, 5, 0, 0]
          },
          areaStyle: {
            color: "#3873cb78"
          }
        }
      ]
    }
  }),
  props: {
    chartData: {
      require: true
    },
    isLoading: {
      require: true,
      type: Boolean
    }
  },
  mounted() {
    this.option.title.text = this.$t("crm.choseFilter");
  },
  watch: {
    chartData: {
      deep: true,
      handler(dataset) {
        console.log(dataset);
        if (dataset) {
          const data = dataset;
          const labels = [];
          data.forEach(item => {
            labels.push(item.date.substr(5));
          });
          this.option.xAxis.data = [...labels];
          this.option.series[0].data = data.map(e => e.amount);
          if (!data.length) {
            this.option.title.text = this.$t("table.noData");
          } else {
            this.option.title.text = "";
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.chart {
  &__wrapper {
    border: thin solid #e4e4e4;
    border-radius: 5px;
  }
}
</style>
