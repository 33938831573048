export default {
  0: 'Невідомий статус',
  300: 'Нове постачання',
  301: 'Доставляеться до складу',
  302: 'Доставлено до складу',
  308: 'Повернено клієнту',
  310: 'Готово для перевірки',
  311: 'Перевіряється',
  312: 'Перевірено',
  320: 'Очікує підтвердження',
  321: 'Підтвердженно клієнтом',
  322: 'Відмінено клієнтом',
  330: 'Готово до відправки',
  350: 'Доставляється на склад',
  351: 'Доставлено на склад',
  360: 'Виконано',
  400: 'Нове',
  401: 'Принято',
  402: 'В процесі',
  403: 'Відправлено клієнту',
  404: 'Доставлено',
  405: 'Відхилено',
  500: 'Новий',
  600: 'Новий',
  700: 'Нова',
  800: 'Нова',
  900: 'Нове',
  901: 'В процесі',
  902: 'На утриманні',
  903: 'Помилка',
  904: 'Виконано',
};
