<template>
  <v-dialog
    v-model="visibility"
    width="500"
    @click:outside="onClickNoChange"
    content-class="cross__dialog"
    scrollable
  >
    <v-card>
      <div class="cross__inner">
        <v-btn
          fab
          small
          color="white"
          elevation="0"
          @click="onClickNoChange()"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title>
        {{$t('crm.dismiss_callcenter')}}
      </v-card-title>
      <v-card-subtitle>
        {{$t('crm.dismiss_callcenter_desc')}}
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              :items="mapedListSates"
              item-text="name"
              item-value="uuid"
              :placeholder="$t('form.choose')"
              v-model="state"
              hide-details="auto"
              dense
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              :error-messages="StateErrors"
            >
              <template slot="item" slot-scope="{ item }">
                <div class="user-state">
                  {{ item?.name }}
                  <span :style="`background-color: ${item.color};`"></span>
                </div>
              </template>
              <template slot="selection" slot-scope="{ item }">
                <div class="user-state">
                  {{ item?.name }}
                  <span :style="`background-color: ${item.color};`"></span>
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <VTextarea
              :label="$t('form.msg')"
              :rows="$vuetify.breakpoint.smAndDown ? 3 : 5"
              hide-details="auto"
              v-model="inputMessage"
              class="send-input"
              solo
              counter="200"
              maxlength="200"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" plain @click="onClickNoChange()">{{$t('crm.dont_change')}}</v-btn>
        <v-spacer />
        <v-btn color="success" @click="changeState()">{{$t('crm.change')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required,  } from 'vuelidate/lib/validators';

export default {
  props: {
    visible: {
      require: true
    },
    list_states: {
      require: true
    },
    uuid_state: {
      type: String,
      require: true
    }
  },
  watch: {
    uuid_state: "fetchData"
  },
  data: () => ({
    state: "",
    inputMessage: ""
  }),
  validations: {
    state: {
      required
    },
  },
    methods: {
      onClickNoChange() {
        this.$emit("noChange",this.inputMessage);
        this.sate = "";
      },
      fetchData() {
        this.state = this.uuid_state;
      },
      changeState() {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.$emit("changeState", this.state, this.inputMessage);
        this.sate = "";
        this.inputMessage = "";
        this.$emit("close");
      }
    },
    computed: {
      visibility: {
        get() {
          return this.visible;
        },
        set() {
          return this.$emit("close");
        }
      },
      StateErrors() {
        const errors = [];
        if (!this.$v.state.$dirty) {
          return errors;
        }
        // eslint-disable-next-line no-unused-expressions
        !this.$v.state.required && errors.push(this.$t("form.errors.StatusRequired"));
        return errors;
      },
      mapedListSates() {
        return this.list_states.filter(
          state => state.is_call_state === "1" || state.uuid === this.uuid_state
        );
      }
    }
};
</script>

<style lang="scss">
.user-state {
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #eeeeee;
  }
}
</style>
