<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <VBtn
          class="text-white  btn-settingBoard  text-transform-none br-10"
          color="primary"
          @click="statsVisible = !statsVisible"
          v-bind="attrs"
          v-on="on"
          :disabled="!uuid_board"
        >
          <VIcon>mdi-chart-box-plus-outline</VIcon>
        </VBtn>
      </template>
      <span>{{ $t("crm.statsTitle") }}</span>
    </v-tooltip>
    <VDialog
      v-model="statsVisible"
      max-width="700px"
      content-class="cross__dialog dialog-order"
      fullscreen
    >
      <VCard>
        <VCardTitle style="background:#FAFAFA">
          <v-row>
            <v-col>
              <span>
                {{ $t("crm.statsTitle") }}
              </span>
            </v-col>
            <v-col cols="auto">
              <VIcon @click="onClose">mdi-close</VIcon>
            </v-col>
          </v-row>
        </VCardTitle>
        <VDivider class="mx-3"></VDivider>
        <VCardText class="py-4">
          <v-row>
            <v-col cols="5">
              <StatsFilter :uuid_board="uuid_board" @onSave="onSave" :states="states" :resetFilters="resetFilters" @resetChart="chartData = []" />
            </v-col>
            <v-col cols="7">
              <StatsChart :chartData="chartData" :isLoading="loadingChart" />
            </v-col>
          </v-row>
        </VCardText>
        <VCardActions> </VCardActions>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import StatsFilter from "./StatsFilter.vue";
import StatsChart from "./StatsChart.vue";
import notifications from "@/mixins/notifications";
import crmService from '../../../services/request/crm/crmService';

export default {
  data: () => ({
    chartData: [],
    statsVisible: false,
    resetFilters:false,
    loadingChart:false
  }),
  props:{
    uuid_board:{
      require:true
    },
    boardUuid:{
        require:true
    },
    states:{
        require:true
    }
  },
  methods: {
    async onSave(data) {
      try {
        this.loadingChart = true
        const params = {
            ...data,
            uuid_board:this.boardUuid.uuid
        }
        const res = await crmService.crmStats(params)
        this.chartData = res
      } catch (e) {
        this.setErrorNotification(e);
      }finally{
        this.loadingChart = false
      }
    },
    onClose() {
      this.statsVisible = false;
      this.chartData = []
      this.resetFilters = !this.resetFilters
    }
  },
  components: {
    StatsFilter,
    StatsChart
  },
  mixins: [notifications]
};
</script>

<style></style>
