<template>
  <VDialog v-model="visibity" max-width="600">
    <VCard>
      <VCardTitle>
        {{$t('crm.user_settings')}}
      </VCardTitle>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-row>
              <v-col cols="12">
                <VTextField
                  v-model="itemCopy.utm_source"
                  outlined
                  dense
                  color="green"
                  label="utm_source"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="itemCopy.utm_medium"
                  outlined
                  dense
                  color="green"
                  label="utm_medium"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="itemCopy.utm_term"
                  outlined
                  dense
                  color="green"
                  label="utm_term"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="itemCopy.utm_content"
                  outlined
                  dense
                  color="green"
                  label="utm_content"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="itemCopy.utm_campaign"
                  outlined
                  dense
                  color="green"
                  label="utm_campaign"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-checkbox :label="$t('crm.access_deport')" v-model="itemCopy.accessDeport"></v-checkbox>
            <v-checkbox :label="$t('crm.change_status')" v-model="itemCopy.changeStatus"></v-checkbox>
            <v-checkbox :label="$t('crm.allow_send')" v-model="itemCopy.allowSendOrder"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn plain @click="$emit('close')">
          {{ $t("btn.cancel") }}
        </v-btn>
        <v-spacer />

        <v-btn
          class="success-bg"
          color="white--text"
          @click="onUpdate"
          :disabled="loadingSharedUpdate || !isChanged"
          :loading="loadingSharedUpdate"
        >
          {{ $t("btn.update") }}
        </v-btn>
      </v-card-actions>
    </VCard>
  </VDialog>
</template>

<script>
import _ from "lodash";
import EventBus from "@/events/EventBus";

export default {
  props: {
    visible: {
      require: true
    },
    item: {
      require: true
    },
    loadingSharedUpdate: {
      require: true
    }
  },
  data: () => ({
    itemCopy: {},
    isChanged: false
  }),
  mounted() {

    this.itemCopy = { ...this.item };
    console.log(this.itemCopy)
    console.log(this.item)
  },
  methods: {
    resetData() {
      this.itemCopy = {};
      this.accessDeport = false;
      this.changeStatus = false;
      this.allowSendOrder = false;
    },
    onUpdate() {

      const accessParams = {
        is_access_deport_info: this.itemCopy.accessDeport,
        is_allow_state_edit: this.itemCopy.changeStatus,
        is_allow_send_order: this.itemCopy.allowSendOrder
      };
      const result = { ...this.itemCopy, accessParams };
      this.$emit("update_user", result);
    }
  },
  watch: {
    itemCopy: {
      deep: true,
      handler() {
        this.isChanged = !_.isEqual(this.itemCopy, this.item);
      }
    }
  },
  computed: {
    visibity: {
      get() {
        return this.visible;
      },
      set() {
        this.resetData();
        return this.$emit("close");
      }
    }
  }
};
</script>

<style></style>
