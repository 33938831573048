<template>
  <VDialog
    content-class="cross__dialog"
    scrollable
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-model="visibility"
    max-width="1300"
  >
    <VCard class="pa-5">
      <VCardTitle class="pa-5">
        <VRow>
          Створення Декларації Нової Пошти
          <VSpacer />
          <VBtn fab small color="white" elevation="0" @click="$emit('close')">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VRow>
      </VCardTitle>
      <VCardText>
        <VRow class="my-4 mx-5">
          <VCol cols="7">
            <!--  -->
            <VRow align="center">
              <VCol cols="4">
                <strong class="green--text text--darken-1"
                  >Технологія відправлення</strong
                >
              </VCol>
              <VCol> <VDivider color="#57A965 " /></VCol>
            </VRow>
            <!--  -->
            <VRow>
              <VCol>
                <VSelect
                  :items="serviceTypes"
                  :item-text="'name'"
                  :item-value="'value'"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  label="Технологія доставки"
                  outlined
                  v-model="delivery.ServiceType"
                  hide-details
                />
              </VCol>
            </VRow>
            <!--  -->
            <VRow align="center">
              <VCol cols="2">
                <strong class="green--text text--darken-1">Відправник</strong>
              </VCol>
              <VCol> <VDivider color="#57A965 " /></VCol>
            </VRow>
            <!--  -->
            <VRow>
              <!--              <VCol>-->
              <!--                <VSelect dense label="Назва кабінету" outlined hide-details="" />-->
              <!--              </VCol>-->
              <VCol>
                <VSelect
                  dense
                  label="Контрагент"
                  outlined
                  hide-details=""
                  :items="counterpartyData"
                  v-model="counterparty"
                  item-text="FirstName"
                  @change="getDataCounterpartyContacts"
                  item-value="Ref"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VSelect
                  dense
                  label="Відправник контрагента"
                  outlined
                  hide-details=""
                  :items="counterpartyContactsData"
                  v-model="counterpartyContacts"
                  @change="getDateCounterAgent"
                  item-text="FirstName"
                  item-value="Ref"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VAutocomplete
                  :items="activeCities"
                  :loading="regionLoading"
                  outlined
                  dense
                  label="Місто отримувача"
                  item-text="Description"
                  item-value="Ref"
                  hide-details="auto"
                  @change="onSelectCity"
                  v-model="delivery.city"
                  :error-messages="cityErrors"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VAutocomplete
                  :items="warehouses"
                  outlined
                  dense
                  label="Відділення"
                  item-text="Description"
                  item-value="Ref"
                  v-model="delivery.warehouse"
                  :hide-details="!warehouseErrors.length"
                  :error-messages="warehouseErrors"
                />
              </VCol>
            </VRow>
            <!--  -->
            <VRow align="center">
              <VCol cols="2">
                <strong class="green--text text--darken-1">Одержувач</strong>
              </VCol>
              <VCol> <VDivider color="#57A965 " /></VCol>
            </VRow>
            <!--  -->
            <VRow>
              <VCol>
                <VTextField
                  dense
                  label="Контактна особа"
                  outlined
                  hide-details=""
                />
              </VCol>
              <VCol>
                <VTextField v-model="delivery.RecipientsPhone" dense label="Телефон" outlined hide-details="" />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VAutocomplete
                  dense
                  label="Населений пункт"
                  outlined
                  hide-details=""
                />
              </VCol>
              <VCol>
                <VSelect
                  dense
                  label="Адреса або Віділення"
                  outlined
                  hide-details=""
                />
              </VCol>
            </VRow>
            <VRow align="center">
              <VCol cols="4">
                <strong class="green--text text--darken-1"
                  >Параметри відправлення</strong
                >
              </VCol>
              <VCol> <VDivider color="#57A965 " /></VCol>
            </VRow>
            <!--  -->
            <VRow>
              <VCol>
                <VSelect
                  dense
                  label="Платник відправлення"
                  outlined
                  v-model="delivery.PayerType"
                  :items="payerTypes"
                  :item-text="'name'"
                  :item-value="'value'"
                  :menu-props="{ bottom: true, offsetY: true }"
                  hide-details
                />
              </VCol>
              <VCol>
                <VSelect
                  dense
                  label="Форма оплати"
                  :items="paymentMethods"
                  :item-text="'name'"
                  :item-value="'value'"
                  :menu-props="{ bottom: true, offsetY: true }"
                  outlined
                  v-model="delivery.PaymentMethod"
                  hide-details
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  locale="uk-UA"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <VTextField
                      v-model="date"
                      label="Дата відправки"
                      readonly
                      outlined
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></VTextField>
                  </template>
                  <v-date-picker
                    locale="uk-UA"
                    v-model="date"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </VCol>
              <VCol>
                <VSelect
                  dense
                  label="Тип вантажу"
                  :items="cargoTypes"
                  :item-text="'name'"
                  :item-value="'value'"
                  :menu-props="{ bottom: true, offsetY: true }"
                  outlined
                  v-model="delivery.CargoType"
                  hide-details=""
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VSelect
                  dense
                  label="Опис Відправлення"
                  outlined
                  v-model="delivery.Description"
                  hide-details=""
                />
              </VCol>
              <VCol>
                <VTextField
                  dense
                  prepend-inner-icon="mdi-information-outline"
                  label="Додаток до опису"
                  outlined
                  hide-details=""
                />
              </VCol>
            </VRow>
          </VCol>
          <!--  -->
          <VCol cols="5">
            <!--  -->
            <VRow align="center">
              <VCol cols="6">
                <strong class="green--text text--darken-1"
                  >Налаштування місця відправлення</strong
                >
              </VCol>
              <v-switch color="success" hide-details />
              <VCol> <VDivider color="#57A965 " /></VCol>
            </VRow>
            <!--  -->
            <VRow>
              <VCol cols="4">
                <VTextField
                  suffix="см"
                  dense
                  type="number"
                  label="Ширина"
                  outlined
                  hide-details
                />
              </VCol>
              <VCol cols="4">
                <VTextField
                  suffix="см"
                  dense
                  type="number"
                  label="Висота"
                  outlined
                  hide-details
                />
              </VCol>
              <VCol cols="4">
                <VTextField
                  suffix="см"
                  dense
                  type="number"
                  label="Довжина"
                  outlined
                  hide-details
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VTextField
                  suffix="кг."
                  dense
                  type="number"
                  label="Загальна вага"
                  outlined
                  hide-details
                />
              </VCol>
              <VCol>
                <VTextField
                  suffix="шт."
                  dense
                  type="number"
                  label="Кількість місць"
                  outlined
                  hide-details
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VTextField
                  suffix="кг."
                  dense
                  type="number"
                  label="Вага об'ємна"
                  outlined
                  hide-details
                />
              </VCol>
              <VCol>
                <VTextField
                  suffix="мᶟ."
                  dense
                  type="number"
                  label="Загальний об'єм"
                  outlined
                  hide-details
                >
                </VTextField>
              </VCol>
            </VRow>
            <!--  -->
            <VRow align="center">
              <VCol cols="5">
                <strong class="green--text text--darken-1"
                  >Відправлення не в коробці</strong
                >
              </VCol>
              <v-switch color="success" hide-details />
              <VCol> <VDivider color="#57A965 " /></VCol>
            </VRow>
            <!--  -->
            <VRow>
              <VCol>
                <v-textarea
                  counter
                  maxlength="100"
                  prepend-icon="mdi-information-outline"
                  outlined
                  label="Додаткова інформація"
                  auto-grow
                ></v-textarea>
              </VCol>
            </VRow>
            <!--  -->
            <VRow align="center">
              <VCol cols="4">
                <strong class="green--text text--darken-1"
                  >Зворотня доставка</strong
                >
              </VCol>
              <v-switch v-model="backwardDeliveryDataCheck" color="success" hide-details />
              <VCol> <VDivider color="#57A965 " /></VCol>
            </VRow>
            <!--  -->
            <VRow>
              <VCol>
                <VSelect
                  dense
                  :disabled="!backwardDeliveryDataCheck"
                  label="Зворотня доставка"
                  outlined
                  hide-details=""
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VSelect
                  dense
                  :disabled="!backwardDeliveryDataCheck"
                  label="Платник зворотної доставки"
                  :items="payerTypes"
                  :item-text="'name'"
                  :item-value="'value'"
                  :menu-props="{ bottom: true, offsetY: true }"
                  outlined
                  v-model="delivery.BackwardDeliveryData[0].PayerType"
                  hide-details
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VTextField
                :disabled="!backwardDeliveryDataCheck"
                  dense
                  suffix="грн."
                  label="Сума зворотної доставки"
                  outlined
                  hide-details=""
                  type="number"
                />
              </VCol>
            </VRow>
            <!--  -->
            <VRow align="center">
              <VCol cols="4">
                <strong class="green--text text--darken-1"
                  >Контроль оплати</strong
                >
              </VCol>
              <v-switch color="success" hide-details />
              <VCol> <VDivider color="#57A965 " /></VCol>
            </VRow>
            <!--  -->
          </VCol>
        </VRow>
      </VCardText>
      <VCardActions class="pb-5 px-5">
        <VSpacer />
        <VBtn color="green darken-1 white--text" @click="createNovaPochtaTTN"
          ><VIcon left>mdi-plus</VIcon>Створити декларацію</VBtn
        >
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import novaPochtaService from "../../services/request/novaPochta/novaPochtaService";
import novaPochta from "../../mixins/novaPochta";
import language from "../../mixins/language";
import user from "../../mixins/user";
import EventBus from "../../events/EventBus";

export default {
  data: () => ({
    dateMenu: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    counterpartyData: [],
    counterparty: null,
    counterpartyContactsData: [],
    counterpartyContacts: null,
    phone: null,
    delivery: {
      city: "",
      city_name: "",
      warehouse: "",
      warehouse_name: "",
      ServiceType: '',
      PayerType: '',
      CargoType: '',
      PaymentMethod: '',
      Description: '',
      RecipientsPhone: '',
      BackwardDeliveryData: [
        {
          PayerType: ''
        }
      ]
    },
    backwardDeliveryDataCheck: false,
    serviceTypes: [
      {
        name: "Відділення-відділення",
        value: "WarehouseWarehouse",
      },
      {
        name: "Відділення-Aдреса",
        value: "WarehouseDoors",
      },
      {
        name: "Адреса-відділення",
        value: "DoorsWarehouse",
      },
      {
        name: "Адреса-адреса",
        value: "DoorsDoors",
      },
    ],
    payerTypes: [
      {
        name: "Відправник",
        value: "Sender",
      },
      {
        name: "Отримувач",
        value: "Receiver",
      },
    ],
    paymentMethods: [
      {
        name: "Готівкою",
        value: "Cash",
      },
      {
        name: "Картою",
        value: "NonCash",
      },
    ],
    cargoTypes: [
      {
        name: "Посилка",
        value: "Parcel",
      },
      {
        name: "Документи",
        value: "Documents",
      },
    ],
  }),
  methods: {
    async getDataCounterparty() {
      try {
        this.loadingCreateTTN = true;
        const formData = {
          apiKey: this.getUser.np_apikey,
          modelName: "Counterparty",
          calledMethod: "getCounterparties",
          methodProperties: {
            CounterpartyProperty: "Sender",
            Page: "1",
            FindByString: "Приватна особа",
          },
        };
        const response = await novaPochtaService.postCreateTTN(formData);
        // eslint-disable-next-line prefer-destructuring
        this.counterpartyData = response.data.data;
        // orderDialog('this.counterpartyData', this.counterpartyData);
        this.loadingCreateTTN = false;
      } catch (e) {
        console.error(e);
        this.loadingCreateTTN = false;
      }
    },
    async getDataCounterpartyContacts() {
      try {
        this.loadingCreateTTN = true;
        const formData = {
          apiKey: this.getUser.np_apikey,
          modelName: "Counterparty",
          calledMethod: "getCounterpartyContactPersons",
          methodProperties: {
            Ref: this.counterparty,
            Page: "1",
          },
        };
        const response = await novaPochtaService.postCreateTTN(formData);
        // eslint-disable-next-line prefer-destructuring
        this.counterpartyContactsData = response.data.data;
        // orderDialog('this.counterpartyContactsData', this.counterpartyContactsData);
        this.loadingCreateTTN = false;
      } catch (e) {
        console.error(e);
        this.loadingCreateTTN = false;
      }
    },
    getDateCounterAgent() {
      // orderDialog('this.counterpartyContacts', this.counterpartyContacts);
      const dateContr = this.counterpartyContactsData.filter(
        (item) => item.Ref === this.counterpartyContacts
      );
      this.phone = dateContr[0].Phones;
      // orderDialog('phone', this.phone);
    },
    async createNovaPochtaTTN() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loadingCreateTTN = true;

          console.log(this.delivery)
          // orderDialog('createTTN');
          // const formData = {
          //   apiKey: this.getUser.np_apikey,
          //   modelName: "InternetDocument",
          //   calledMethod: "save",
          //   methodProperties: {
          //     NewAddress: "1",
          //     PayerType: "Sender",
          //     PaymentMethod: "Cash",
          //     CargoType: "Cargo",
          //     VolumeGeneral: "0.1",
          //     Weight: "10",
          //     ServiceType: "WarehouseWarehouse",
          //     SeatsAmount: "1",
          //     Description: "Тест Тест Тест",
          //     Cost: "500",
          //     CitySender: this.delivery.city,
          //     SenderAddress: this.delivery.warehouse,
          //     Sender: this.counterparty,
          //     ContactSender: this.counterpartyContacts,
          //     SendersPhone: this.phone,
          //     RecipientCityName: "Киев",
          //     RecipientArea: "",
          //     RecipientAreaRegions: "",
          //     RecipientAddressName: "1",
          //     RecipientHouse: "",
          //     RecipientFlat: "",
          //     RecipientName: "Тест Тест Тест",
          //     RecipientType: "PrivatePerson",
          //     RecipientsPhone: "380991234567",
          //     DateTime: "23.02.2022",
          //   },
          // };

          // const response = await novaPochtaService.postCreateTTN(formData);
          // orderDialog('createNovaPochtaTTN', response);
          // orderDialog('success', response.data.success);
          // if (response.data.success) {
          //   EventBus.$emit("new-ttn", response.data.data[0]);
          //   this.$emit("close");
          //   this.setSuccessNotification("Успішно створено!");
          // }
          this.loadingCreateTTN = false;
        } catch (e) {
          console.error(e);
          this.loadingCreateTTN = false;
        }
      }
    },
  },
  async mounted() {
    try {
      await this.fetchNovaPochtaCities();
      this.activeCities = this.cities;
      this.getDataCounterparty();
    } catch (e) {
      this.setErrorNotification(e);
    }
  },
  computed: {
    cityErrors() {
      const errors = [];
      if (!this.$v.delivery.city.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.delivery.city.required &&
        errors.push(this.language.isRequired("Місто"));
      return errors;
    },
    warehouseErrors() {
      const errors = [];
      if (!this.$v.delivery.warehouse.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.delivery.warehouse.required &&
        errors.push(this.language.isRequired("Відділення"));
      return errors;
    },
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      },
    },
  },
  validations: {
    delivery: {
      warehouse: { required },
      city: { required },
    },
  },
  mixins: [user, novaPochta, validationMixin, language],
  props: {
    visible: {
      require: true,
    },
  },
};
</script>

<style></style>
