<template>
  <v-tabs-items v-model="tabStep" :touchless="true">
    <v-tab-item>
      <div class="crm-form-items__wrapper">
        <v-row class="mt-1 pb-5">
          <v-col cols="12">
            <div class="title d-flex mb-1 justify-content-space-between align-center">
              <!-- <v-row class="pb-3">
                <v-col cols="6">
                  <VBtn class="br-10 text-transform-none" color="primary" plain @click="backModal">
                    <VIcon left>
                      mdi-arrow-left
                    </VIcon>
                    {{ $t("btn.back") }}
                  </VBtn>
                </v-col>
              </v-row> -->
              <v-row></v-row>
              <VBtn
                class="ml-2 btn-settingBoard success-bg text-white text-transform-none br-10"
                @click="openEditProduct('add')"
              >
                <VIcon>mdi-plus</VIcon>
              </VBtn>
            </div>
            <v-data-table
              :headers="listProductsHeader"
              :items="listProducts"
              item-key="email"
              hide-default-footer
              class="my-table supply crmForms__listDialog-table"
              locale="uk"
              dense
              loader-height="1"
              :items-per-page="listProducts.length"
              no-data-text="Список порожній"
            >
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.name="{ item }">
                <div>
                  <VBtn
                    class="btn-edit sm mr-3"
                    color="primary"
                    dense
                    small
                    @click="openEditProduct('edit', item)"
                  >
                    <VIcon dense>
                      mdi-pencil
                    </VIcon>
                  </VBtn>
                  {{ item.name }}
                </div>
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.path_img="{ item }">
                <div class="mx-auto">
                  <img
                    v-if="!item.path_img_thumb"
                    :src="require('@/assets/images/supply-item.png')"
                    class="image-placeholder expanded-image sm"
                  />
                  <v-img
                    v-else
                    :src="item.path_img_thumb"
                    class="image expanded-image sm"
                    @click="showImgDialog(item.path_img)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                        style="min-height:60px"
                      >
                        <v-progress-circular
                          indeterminate
                          :size="10"
                          :width="1"
                          color="green lighten-1"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </template>
               <!-- eslint-disable-next-line  -->
              <template v-slot:item.is_top_sell="{ item }">
                <div>
                  {{ +item.is_top_sell ? 'Так' : 'Ні' }}
                </div>
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.btn="{ item }">
                <VBtn
                  icon
                  class="sm"
                  color="error"
                  depressed
                  dense
                  small
                  plain
                  @click="delayItemProducts(item.uuid)"
                >
                  <VIcon small>
                    mdi-close
                  </VIcon>
                </VBtn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-tab-item>
    <v-tab-item>
      <CrmFormProduct
        ref="productForm"
        :dataProduct="dataProduct"
        :type="this.typeProductForm"
        :uuid_form="dataForm.uuid"
        @back="backFormProduct()"
        @editProduct="onEditProduct"
      ></CrmFormProduct>
    </v-tab-item>
    <v-tab-item>
      <v-row>
        <v-col cols="12">
          <CrmFormListProduct @back="backFormProduct()"></CrmFormListProduct>
        </v-col>
      </v-row>
    </v-tab-item>

    <ConfirmDialogSlots
      :visible="confirmDialog"
      @close="confirmDialog = false"
      @onConfirm="
        confirmDeleteProduct();
        confirmDialog = false;
      "
      @onCancel="confirmDialog = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.accept_deleteTitle") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.accept_deleteDesc") }}
      </template>
    </ConfirmDialogSlots>
    <imgDialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
  </v-tabs-items>
</template>

<script>
import EventBus from "@/events/EventBus";
import crmForms from "@/services/request/crm/crmForms";
import ConfirmDialogSlots from "../../../../dialog/ConfirmDialog.vue";
import CrmFormProduct from "./productTabs/crmFormProduct.vue";
import CrmFormListProduct from "./productTabs/crmFormListProduct.vue";
import notifications from "@/mixins/notifications";
import imgDialog from "@/components/products/imgDialog.vue";

export default {
  data: () => ({
    changed: false,
    tabStep: 0,
    listProducts: [],
    confirmDialog: false,
    visibleConfirm: false,
    deleteUUID: null,
    typeProductForm: "add",
    dataProduct: {},
    isEqualFormData: false,
    listProductsHeader: [
      {
        text: "Фото",
        align: "left",
        sortable: false,
        value: "path_img",
        width: 10
      },
      {
        text: "Назва",
        align: "left",
        sortable: false,
        value: "name"
      },
      {
        text: "Рейтинг (1…5)",
        align: "left",
        sortable: false,
        value: "rating"
      },
      {
        text: "Знижка (%)",
        align: "left",
        sortable: false,
        value: "discount"
      },
      {
        text: "Ціна (грн)",
        align: "left",
        sortable: false,
        value: "price"
      },
      {
        text: "Хіт продажу",
        align: "left",
        sortable: false,
        value: "is_top_sell"
      },
      {
        text: "",
        align: "center",
        sortable: false,
        value: "btn"
      }
    ],
    imgpath: null,
    imgDialog: false
  }),
  components: {
    ConfirmDialogSlots,
    CrmFormProduct,
    CrmFormListProduct,
    imgDialog
  },
  methods: {
    showImgDialog(product) {
      this.imgpath = product;
      // orderDialog(this.imgpath);
      this.imgDialog = true;
    },
    onEditProduct() {
      this.tabStep = 2;
      EventBus.$emit("on-tab-list-product", true);
    },
    delayItemProducts(uuid) {
      this.deleteUUID = uuid;
      this.confirmDialog = true;
    },
    async getProductList() {
      try {
        if (this.dataForm.uuid) {
          this.listProducts = await crmForms.getProductList(this.dataForm.uuid);
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    backFormProduct() {
      this.getProductList();
      this.tabStep = 0;
    },

    openEditProduct(type, item) {
      this.tabStep = 1;
      this.typeProductForm = type;
      if (type === "add") {
        this.dataProduct = {
          uuid: null,
          uuid_form: null,
          name: "Новий товар",
          description: "",
          path_img: null,
          price: null,
          uuid_product: null,
          uuid_option: null,
          uuid_user: null,
          time_created: ""
        };
      } else if (type === "edit") {
        this.dataProduct = item;
      }
      EventBus.$emit("on-tab-product", true);
    },
    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    backModal() {
      this.$emit("back");
    },
    async confirmDeleteProduct() {
      const uuid = this.deleteUUID;
      const respons = await crmForms.deleteProductItem(uuid);
      // console.log("respons", respons);
      if (respons?.status === "success") {
        this.listProducts = this.listProducts.filter(item => item.uuid !== uuid);
      }
    }
  },
  props: {
    onBackFormProduct: {
      required: true
    },
    onBackFromProductList: {
      required: true
    },
    dataForm: {
      required: true,
      type: Object
    },
    resetTabs: {
      required: true
    }
  },
  mounted() {
    EventBus.$on("crm-form-setProduct", product => {
      this.tabStep = 1;
    });
    EventBus.$on("onResetTab", () => {
      this.tabStep = 0;
      this.getProductList();
      EventBus.$emit("on-tab-product", false);
    });
    this.getProductList();
  },
  mixins: [notifications],
  watch: {
    resetTabs: {
      deep: true,
      handler(e) {
        console.log(e);
        if (e) {
          this.tabStep = 0;
        }
      }
    },
    onBackFormProduct: {
      deep: true,
      handler(e) {
        this.backFormProduct();
      }
    },
    onBackFromProductList: {
      deep: true,
      handler(e) {
        this.tabStep = 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
