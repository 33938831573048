<template>
  <VDialog
    v-model="visibility"
    max-width="700px"
    content-class="cross__dialog dialog-order"
    @click:outside="typeDialog = 0"
  >
    <VCard class="boardSettingDialog" :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
      <div class="cross__inner">
        <VBtn fab small color="white" elevation="0" @click="closeDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VRow class="ma-0 align-center justify-space-between boardSettingDialog__header">
        <VCol cols="12" md="6" class="boardSettingDialog__header-title">
          {{ getDialogTitle }}
        </VCol>
        <VCol cols="12" md="6" class="boardSettingDialog__header-nav">
          <VAutocomplete
            :items="boardList"
            item-text="name"
            item-value="uuid"
            placeholder="Оберіть"
            v-model="boardItem"
            :hide-details="true"
            @change="changeBoard"
            dense
            outlined
            return-object
            :disabled="typeDialog != 0"
            :menu-props="{offsetY:true}"
          >
            <template slot="item" slot-scope="{ item }">
              {{ item.name }}
            </template>
            <template slot='selection' slot-scope='{ item }'>
              {{ item.name }}
            </template>
          </VAutocomplete>
          <VBtn class="ml-2 btn-settingBoard text-white text-transform-none br-10"
            :class="`${typeDialog ==  0 ? ' success-bg' : 'error-bg'}`"
            @click="changeTypeDialog"
            :disabled="!boardList.length"
          >
            <VIcon v-if="typeDialog">mdi-close</VIcon>
            <VIcon v-else>mdi-plus</VIcon>
          </VBtn>
        </VCol>
      </VRow>
      <VCardText>
        <VTabsItems v-model="typeDialog">
          <VTabItem>
            <VRow class="my-0 py-0">
              <VCol cols="12">
                <VTextField
                  v-model="editBoard.name"
                  outlined
                  dense
                  color="green"
                  class="br-10"
                  :label="$t('form.title')"
                  :hide-details="!nameEditErrors.length"
                  :error-messages="nameEditErrors"
                />
              </VCol>
              <VCol cols="12">
                <VTextarea
                  height="104"
                  no-resize
                  rows="3"
                  color="green"
                  class="br-10"
                  :placeholder="$t('form.addComment')"
                  v-model="editBoard.description"
                  hide-details
                  outlined
                  dense
                ></VTextarea>
              </VCol>
              <VCol cols="12" class="d-flex">
                <VTextField
                  v-model="editBoard.uuid"
                  outlined
                  dense
                  color="green"
                  class="br-10"
                  label="UUID"
                  hide-details
                  disabled
                />
                <VBtn class="ml-2 btn-settingBoard success-bg text-white text-transform-none br-10"
                  @click="copyToClipboard(editBoard.uuid)"
                >
                  <VIcon>mdi-content-copy</VIcon>
                </VBtn>
              </VCol>
            </VRow>
          </VTabItem>
          <VTabItem>
            <VRow class="my-0 py-0">
              <VCol cols="12">
                <VTextField
                  v-model="newBoard.name"
                  outlined
                  dense
                  color="green"
                  class="br-10"
                  :placeholder="$t('form.title')"
                  :hide-details="!nameAddErrors.length"
                  :error-messages="nameAddErrors"
                />
              </VCol>
              <VCol cols="12">
                <VTextarea
                  height="104"
                  no-resize
                  rows="3"
                  color="green"
                  class="br-10"
                  :placeholder="$t('form.addComment')"
                  v-model="newBoard.description"
                  hide-details
                  outlined
                  dense
                ></VTextarea>
              </VCol>
            </VRow>
          </VTabItem>
       
        </VTabsItems>
      </VCardText>
      <VCardActions>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                v-if="typeDialog === 0"
                class="w-100 br-10 text-transform-none"
                dark
                @click="$emit('close')"
              >
                {{$t('btn.cancel')}}
              </VBtn>
            </VCol>
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                v-if="typeDialog === 0"
                @click="updateBoardItem"
                :disabled="isChanged"
              >
                  {{$t('btn.update')}}
              </VBtn>
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                v-else-if="typeDialog === 1"
                @click="createNewBoard"
              >
                   {{$t('btn.create')}}
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required ,email} from 'vuelidate/lib/validators';
import * as getterTypes from '@/store/modules/crm/types/getters';
import language from '../../../mixins/language';
import user from '../../../mixins/user';
import notifications from '../../../mixins/notifications';
import crmService from '../../../services/request/crm/crmService';
import EventBus from '../../../events/EventBus';
import {mapGetters} from "vuex";
import _ from 'lodash'

export default {
  name: 'SettingBoardDialog',
  components: {
},
  mixins: [validationMixin, language, notifications,user],
  data: () => ({
    loading: false,
    boardItem: null,
    typeDialog: 0,
    editBoard: {
      name: '',
      description: '',
      uuid: '',
    },
    newBoard: {
      name: '',
      description: '',
    },


  }),
  mounted() { 
    this.fetchData() 
  },
  methods: {
    async copyToClipboard(uuid) {
      try {
        await navigator.clipboard.writeText(uuid);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    fetchData() {
      const boardItem = this.boardList.filter((item) => item.uuid === this.uuid_board)
      console.log(boardItem)
      if(!this.boardList.length){
      this.typeDialog = 1
    }
      this.boardItem = boardItem.length ? boardItem[0] : null;
      // console.log('boardItem', this.boardItem)
 
      this.getDataItemBoard();
    },
    changeBoard() {
      // orderDialog('boardItem', this.boardItem);
      // console.log(this.isBoardCreator)
      if(this.typeDialog === 2 && this.isBoardCreator){
        this.fetchSharedUsers()
      }else{
        this.typeDialog = 0
      }
      this.getDataItemBoard();
    },
    changeTypeDialog() {
      if(this.typeDialog === 2){
        this.typeDialog = 0
        return
      }
      this.typeDialog = this.typeDialog === 1 ? 0 : 1;
    },
    async getDataItemBoard() {
      try {
        if (this.boardItem) {
          await crmService.getCrmBoardsItemInfo(this.boardItem.uuid)
            .then((request) => {
              // // orderDialog('request', request);
              this.editBoard = {
                ...this.editBoard,
                ...request,
              };
            });
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    },

    clearNewBoard() {
      this.newBoard = {
        name: '',
        description: '',
      };
    },
    async createNewBoard() {
      this.$v.newBoard.$touch();
      if (!this.$v.newBoard.$invalid) {
        try {
          const formData = new FormData();
          formData.append('name', this.newBoard.name);
          formData.append('description', this.newBoard.description);

          const request = await crmService.addCrmBoardsItem(formData);

          this.typeDialog = 0;
          this.visibility = false;
          EventBus.$emit('crm-board-items-reset', request.data.object);
          this.setSuccessNotification('Успішно створено!');
          this.clearNewBoard();
        } catch (e) {
          this.setErrorNotification(e);
        }
      }
    },
    async updateBoardItem() {
      this.$v.editBoard.$touch();
      if (!this.$v.editBoard.$invalid) {
        try {
          const formData = new FormData();
          formData.append('uuid', this.editBoard.uuid);
          const changedData = this.$getChangedData(this.editBoard, this.boardItem);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });

          await crmService.updateCrmBoardsItem(formData);

          EventBus.$emit('crm-board-items-reset');
          this.setSuccessNotification('Успішно оновлено!');
          this.$emit('close');
        } catch (e) {
          this.setErrorNotification(e);
        }
      }
    },
    onClose() {
      this.$v.$reset();
      this.$emit('close');
    },
    closeDialog() {
        this.typeDialog = 0
        this.onClose();
    },
  },
  computed: {
    ...mapGetters('crm', {
      uuid_board: getterTypes.GET_UUID_BOARD
    }),

    isChanged() {
      return _.isEqual(this.editBoard, this.boardItem)
    },

    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
        this.$v.$reset()
      },
    },
    getDialogTitle(){
      let value = ''
      switch (this.typeDialog){
        case (0):
        value = this.$t('crm.settingtsTitle')
        break
        case (1):
         value = this.$t('crm.creationTitle')
         break
        default:
          return
      }
      return value
    },
    nameAddErrors() {
      const errors = [];
      if (!this.$v.newBoard.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.newBoard.name.required
      && errors.push(this.$t('form.errors.TitleRequierd'));
      return errors;
    },

    nameEditErrors() {
      const errors = [];
      if (!this.$v.editBoard.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.editBoard.name.required
      && errors.push(this.$t('form.errors.TitleRequierd'));
      return errors;
    },
  },
  props: {
    boardList: {
      required: true,
    },
    visible: {
      required: true,
      type: Boolean,
    },
  },
  validations: {
    newBoard: {
      name: { required },
    },
    editBoard: {
      name: { required },
    },
  },
  watch: {
    visibility: {
      handler() {
        if (!this.boardList.length) {
           this.typeDialog = 1
        }
      }
    },
  },
};
</script>

<style lang="scss">
.boardSettingDialog{
  &__header{
    &-title{
      padding: 32px 24px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #3D3D3D;
    }
    &-nav{
      display: flex;
      padding: 12px 24px;
    }
  }
}
</style>
