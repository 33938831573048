var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"my-table supply",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.items,"item-key":"uuid","hide-default-footer":"","items-per-page":_vm.items.length,"dense":"","locale":"uk","no-data-text":_vm.$t('table.noData')},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [_c('div',{staticClass:"w-0"},[_c('VBtn',{attrs:{"color":"red","small":"","disabled":_vm.blockedItem,"icon":""},on:{"click":function($event){return _vm.$emit('onClickDelete', item.uuid)}}},[_c('VIcon',[_vm._v("mdi-close")])],1)],1)]}},{key:"item.image",fn:function({ item }){return [(!item.product_details.path_img_thumb)?_c('img',{staticClass:"image-placeholder sm",attrs:{"src":require('@/assets/images/supply-item.png')}}):_c('v-img',{staticClass:"image sm",attrs:{"src":item.product_details.path_img_thumb},on:{"click":function($event){return _vm.showImgDialog(item.product_details.path_img)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"width":2,"color":"green lighten-1"}})],1)]},proxy:true}],null,true)})]}},{key:"item.options",fn:function({ item }){return [_vm._v(" "+_vm._s(item.option_details.name || "--")+" ")]}},{key:"item.items_amount",fn:function({ item }){return [(_vm.isChangeAmount)?_c('div',[_c('ns-counter',{staticClass:"my-2 mx-auto",attrs:{"min":1,"max":_vm.settingDialog == 'supply' ? _vm.getMax(item) : 9999},on:{"change":function($event){return _vm.onChangeCounter(item)}},model:{value:(item.items_amount),callback:function ($$v) {_vm.$set(item, "items_amount", $$v)},expression:"item.items_amount"}})],1):_c('div',[_vm._v(" "+_vm._s(item.items_amount || 0)+" ")])]}},{key:"item.items_available",fn:function({ item }){return [_c('div',{class:{
            'error--text font-weight-bold':
              _vm.isChangeAmount && item.items_available < item.items_amount && _vm.settingDialog == 'crm'
          }},[_vm._v(" "+_vm._s(item.items_available || 0)+" ")])]}},{key:"item.additional_sell_amount",fn:function({ item }){return [_c('div',{staticClass:"w-0"},[_vm._v(" "+_vm._s(item.additional_sell_amount)+" ")])]}},{key:"item.item_price",fn:function({ item }){return [(_vm.isChangeAmount)?_c('div',[(!_vm.editPriceinput.visible || _vm.editPriceinput.uuid != item.uuid)?_c('div',{staticClass:"dashed-preview",on:{"click":function($event){_vm.editPriceinput.visible = true;
              _vm.editPriceinput.uuid = item.uuid;}}},[_vm._v(" "+_vm._s(item.item_price || "0.00")+" ")]):_vm._e(),(_vm.editPriceinput.visible && _vm.editPriceinput.uuid === item.uuid)?_c('v-row',{staticClass:"mx-auto mx-1",staticStyle:{"width":"100px"},attrs:{"justify":"center"}},[_c('v-text-field',{ref:"inputName",staticClass:"dashed-input",attrs:{"dense":"","autofocus":"","min":0,"hide-details":"auto","elevation":"0","disabled":_vm.editPriceinput.loading},on:{"keypress":function($event){return _vm.onKeyChangePrice($event, item)},"blur":function($event){return _vm.onCloseByBlur(item.uuid)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('VBtn',{attrs:{"icon":"","dense":"","disabled":_vm.editPriceinput.loading,"color":"yellow darken-2"},on:{"click":function($event){return _vm.onClosePriceEdit(item.uuid)}}},[_c('VIcon',{attrs:{"dense":""}},[_vm._v("mdi-check-circle-outline")])],1)]},proxy:true}],null,true),model:{value:(item.item_price),callback:function ($$v) {_vm.$set(item, "item_price", _vm._n($$v))},expression:"item.item_price"}})],1):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(item.item_price || "0.00")+" ")])]}},(this.items.length && _vm.settingDialog === 'crm')?{key:"footer",fn:function(){return [_c('hr'),_c('div',{staticClass:"d-flex w-100",staticStyle:{"justify-content":"flex-end","height":"auto","background":"#FAFAFA"}},[_vm._v(" "+_vm._s(_vm.getTotalPrice())+" "),_c('br'),_vm._v(" "+_vm._s(_vm.getInitPrice())+" "),_c('br'),_vm._v(" "+_vm._s(_vm.getProfit())+" ")])]},proxy:true}:null],null,true)}),_c('img-dialog',{attrs:{"visible":_vm.imgDialog,"imgpath":_vm.imgpath},on:{"close":function($event){_vm.imgDialog = false}}}),_c('ConfirmDialog',{attrs:{"visible":_vm.confirmDialog,"typeAlert":'warning'},on:{"close":function($event){_vm.confirmDialog = false},"onConfirm":function($event){_vm.onChangePrice(_vm.chosenUUIDPrice);
        _vm.confirmDialog = false;},"onCancel":function($event){_vm.stopBlur = false;
        _vm.confirmDialog = false;}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("confirmDialog.editPrice.title"))+" ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("confirmDialog.editPrice.desc"))+" ")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }