<template>
  <VDialog v-model="visibility" width="600">
    <VCard>
      <VCardTitle>
        {{$t('supply.return_supply')}}
      </VCardTitle>
      <VDivider class="mx-3 mb-6" />
      <VCardText>
        <VRow>
          <VCol cols="12" md="6">
            <VTextField
              v-model="delivery.name"
              outlined
              dense
              class="br-10"
              :label="$t('form.name_receiver')"
              hide-details="auto"
            />
          </VCol>
          <VCol cols="12" md="6">
            <VTextField
              v-model="delivery.description"
              outlined
              dense
              class="br-10"
              :label="$t('form.description')"
              hide-details="auto"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12">
            <VTextField
              v-model="delivery.ttn"
              outlined
              dense
              class="br-10"
                :label="$t('form.ttn')"
              hide-details="auto"
              :error-messages="TtnErrors"
            />
          </VCol>
        </VRow>
      </VCardText>
      <VDivider class="mx-3" />
      <VCardActions>
        <VCol>
          <VBtn
            class="text-white text-transform-none br-10 w-100"
            color=""
            block
            dark
            @click="onCancel"
          >
            {{$t('btn.cancel')}}
          </VBtn>
        </VCol>
        <VCol>
          <VBtn
            class="white--text text-transform-none br-10 w-100 success-bg"
            block
            @click="onConfirm"
          >
             {{$t('btn.confirm')}}
          </VBtn>
        </VCol>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import supplyService from "../../../../services/request/supply/supplyService";
import { validationMixin } from "vuelidate";
import { required } from 'vuelidate/lib/validators';
import language from "../../../../mixins/language";
import notifications from "../../../../mixins/notifications";
import EventBus from "../../../../events/EventBus";
import nsCodeMask from "@/validators/nsCodeMask";

export default {
  data: () => ({
    delivery: {}
  }),
  methods: {
    async onConfirm() {
      try {
        console.log(this.uuid_parent)
        this.$v.$touch()
        if(this.$v.$invalid) return
        console.log(this.uuid_parent);
        const formData = new FormData();
        if (this.delivery.name) {
          formData.append("name", this.delivery.name);
        }
        if (this.delivery.description) {
          formData.append("description", this.delivery.description);
        }
        if (this.delivery.ns_code) {
          formData.append("ns_code", this.delivery.ns_code);
        }
        if (this.delivery.ttn) {
          formData.append("ttn", this.delivery.ttn);
        }
        formData.append("uuid_parent", this.uuid_parent);
        await supplyService.returnSupply(formData);
        EventBus.$emit("on-returned");
      } catch (e) {
        this.setErrorNotification(e.error.description);
      }
    },
    onCancel() {
      this.$v.$reset()
      this.delivery = {};
      this.$emit("close");
    }
  },
  props: {
    visible: {
      require: true
    },
    uuid_parent: {
      require: true
    }
  },
  validations: {
    delivery: {
      ttn: { required }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    TtnErrors() {
      const errors = [];
      if (!this.$v.delivery.ttn.$dirty) {
        return errors;
      }
      if (!this.$v.delivery.ttn.required) {
       errors.push(this.language.isRequired('ТТН'));
        return errors;
      }
      return errors;
    }
  },
  mixins: [language, notifications, validationMixin]
};
</script>

<style></style>
