<template>
  <div class="section-crm">
    <VRow class="align-center" justify="space-between">
      <!-- <VCol cols="12"   v-if="boardSetting.list.length && $vuetify.breakpoint.smAndDown">
        <VAutocomplete
          dense
          :items="boardSetting.list"
          outlined
          :item-value="'uuid'"
          :item-text="'name'"
          v-model="boardsItem"
          return-object
          :hide-details="true"
          @change="changeBoard"
          :menu-props="{bottom:true,offsetY:true}"
        />
      </VCol> -->
      <VCol cols="12" sm="6" md="3" class="d-flex">
        <VAutocomplete
          dense
          :items="boardSetting.list"
          outlined
          :item-value="'uuid'"
          :item-text="'name'"
          v-model="boardsItem"
          return-object
          :hide-details="true"
          @change="changeBoard"
          :menu-props="{ bottom: true, offsetY: true }"
          :label="$t('crm.crmBoard')"
          :no-data-text="$t('table.noData')"
        >
        <template slot="item" slot-scope="{ item }">
          <VRow no-gutters justify="space-between" align="center">
              {{ item.name }}
              <VIcon v-if="item.uuid_user_created != getUser.uuid" color="deep-purple" small>mdi-share-variant</VIcon>
          </VRow>
        </template>
        <template slot="selection" slot-scope="{ item }">
          <div class="mr-1">
            {{ item.name }}
          </div>
          <VIcon v-if="item.uuid_user_created != getUser.uuid" color="deep-purple" small>mdi-share-variant</VIcon>
        </template>
        </VAutocomplete>
      </VCol>
      <VCol cols="12" sm="6" md="5">
        <VRow :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <VBtn
              class="ml-2 btn-settingBoard success-bg text-white text-transform-none br-10"
              @click="boardSetting.visible = !boardSetting.visible"
              v-bind="attrs"
              v-on="on"
            >
              <VIcon>mdi-cog</VIcon>
            </VBtn>
          </template>
          <span>{{ $t("crm.settingtsTitle") }}</span>
        </v-tooltip>
        <CrmForms
          class="ml-2"
          :boardList="boardSetting.list"
          :boardUuid="boardsItem"
          :stateUuid="tab[activeTab]"
        ></CrmForms>
        <StatsComponent class="ml-2" :boardUuid="boardsItem" :states="tab" :uuid_board="this.boardsItem.uuid "></StatsComponent>
        <v-slide-x-reverse-transition>
          <ShareUser
            class="ml-2"
            :boardList="boardSetting.list"
            :boardUuid="boardsItem"
            :stateUuid="tab[activeTab]"
            v-if="isBoardCreator"
          ></ShareUser>
        </v-slide-x-reverse-transition>
        </VRow>
      </VCol>
      <VCol cols="12" sm="12" md="4" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-right'">
        <v-tooltip bottom v-if="hasBoards === true">
          <template v-slot:activator="{ on, attrs }">
            <VBtn
              v-bind="attrs"
              v-on="on"
              class="btn-edit mr-2 br-10"
              :color="chosenFilter ? 'primary' : 'secondary lighten-5'"
              v-model="chosenFilter"
              @click="chosenFilter = !chosenFilter"
            >
              <VIcon>
                mdi-truck-fast-outline
              </VIcon>
            </VBtn>
          </template>
          Готово до відправки
        </v-tooltip>
        <VBtn
          class="success-bg text-white text-transform-none br-10"
          @click="openItemDialog('add', {})"
          :disabled="hasBoards === false"
        >
          <VIcon left>mdi-plus</VIcon>
          {{ $t("btn.addOrder") }}
        </VBtn>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="pt-0">
        <VTabs
          v-if="tab.length"
          class="crm-tab"
          active-class="active"
          background-color="transparent"
          slider-color="transparent"
          v-model="activeTab"
          show-arrows
          :style="
            tab[activeTab].color !== '#FFF' ? `border-bottom-color: ${tab[activeTab].color};` : ''
          "
        >
          <Draggable v-model="tab" class="crm-tabDraggable" @change="moveTab" draggable=".item">
            <VTab
              @click="updateItems(item.uuid, item.noEdit)"
              v-for="(item, index) in tab"
              :key="index"
              :class="{
                white: item.color === '#FFF',
                item: item.uuid !== '00000000-0000-0000-0000-000000000000'
              }"
              :ripple="false"
            >
              <div
                class="tab-text"
                :style="`background: ${item.color};`"
                @dblclick="dblClick(item)"
                v-if="item.uuid_user"
              >
                {{ item.name }}
              </div>
              <div
                class="tab-edit"
                v-if="!item.uuid_user && !item.noEdit"
                v-click-outside="outSideEditTab"
              >
                <input
                  v-model="item.name"
                  v-on:keyup.enter="onUpdateTab(item), (item.uuid_user = true)"
                  :style="`width: ${item.name.length + 4}ch`"
                />
                <VMenu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="tab-edit-color" v-bind="attrs" v-on="on">
                      <span :style="`background: ${item.color}`"></span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z"
                          fill="#676767"
                        />
                      </svg>
                    </div>
                  </template>
                  <ColorPicker v-model="item.color"></ColorPicker>
                </VMenu>
                <VIcon
                  dense
                  @click="updateItemCallState(item)"
                  :color="item.is_call_state === '1' ? 'primary' : 'grey'"
                >
                  {{ item.is_call_state === "1" ? "mdi-phone" : "mdi-phone-off" }}
                </VIcon>
                <VIcon @click="item.uuid_user = true">
                  mdi-close
                </VIcon>
              </div>
            </VTab>
          </Draggable>
          <div v-if="!dialogNewTab" @click="dialogNewTab = !dialogNewTab" class="crm-tab__add">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.07776 2.1665V13.8332"
                stroke="#676767"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M2.16666 8.07764H13.8333"
                stroke="#676767"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div v-else class="crm-tab__addInput" v-click-outside="outSideCreateTab">
            <input v-model="nameNewTab" v-on:keyup.enter="onAddTab" />
            <VMenu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <div class="crm-tab__addInput-color" v-bind="attrs" v-on="on">
                  <span :style="`background: ${colorNewTab}`"></span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z"
                      fill="#676767"
                    />
                  </svg>
                </div>
              </template>
              <ColorPicker v-model="colorNewTab"></ColorPicker>
            </VMenu>
            <VIcon @click="dialogNewTab = !dialogNewTab">
              mdi-close
            </VIcon>
          </div>
        </VTabs>
        <div class="section-crm__content">
          <CrmRowList
            class="crm-order"
            v-if="boardsItem.uuid"
            :uuid_filter="uuid_filter"
            :uuid_board="boardsItem.uuid"
            :colors="tab"
            :chosenFilter="chosenFilter"
          >
          </CrmRowList>
        </div>
      </VCol>
    </VRow>
    <SettingBoardDialog
      :visible="boardSetting.visible"
      :boardList="boardSetting.list"
      @close="boardSetting.visible = false"
      v-if="boardSetting.visible"
    ></SettingBoardDialog>
    <DialogWrapper
      v-if="boardsItem.uuid"
      :visible="itemDialog.visible"
      :typeDialog="itemDialog.type"
      @close="itemDialog.visible = false"
      settingDialog="crm"
    >
      <OrderDialog
        v-if="itemDialog.visible"
        :user_states="itemDialog.user_states || []"
        :uuidItem="itemDialog.item.uuid"
        :typeDialog="itemDialog.type"
        settingDialog="crm"
        :isAccessDeportInfo="isAccessDeportInfo"
        :isAllowSendOrder="isAllowSendOrder"
      ></OrderDialog>
    </DialogWrapper>

    <!--    :item="itemDialog.item"-->
  </div>
</template>

<script>
import _ from 'lodash';
import Draggable from "vuedraggable";
import ClickOutside from "vue-click-outside";
import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/crm/types/actions";
import * as getterTypes from "@/store/modules/crm/types/getters";
import notifications from "../../mixins/notifications";
import loader from "../../mixins/loader";
import crmService from "../../services/request/crm/crmService";
import user from "../../mixins/user";
import CrmRowList from "./ordersList/CrmRowList.vue";
import OrderDialog from "../orderDialog/OrderDialog.vue";
import DialogWrapper from "../orderDialog/DialogWrapper.vue";
import SettingBoardDialog from "./ordersList/SettingBoardDialog.vue";
import ColorPicker from "../common/ColorPicker.vue";
import CrmForms from "./crmForms/crmForms.vue";
import EventBus from "../../events/EventBus";
import ShareUser from "@/components/crm/ordersList/shareUser.vue";
import StatsComponent from "@/components/crm/stats/StatsComponent.vue";

export default {
  name: "CrmComponent",
  components: {
    CrmRowList,
    OrderDialog,
    ColorPicker,
    SettingBoardDialog,
    Draggable,
    CrmForms,
    DialogWrapper,
    ShareUser,
    StatsComponent
  },
  mixins: [notifications, loader, user],
  data: () => ({
    itemDialog: {
      visible: false,
      type: "add",
      item: {},
      user_states: []
    },
    boardSetting: {
      visible: false,
      list: []
    },
    boardsItem: {},
    createPermanentDialog: false,
    activeTab: 0,
    dialogNewTab: false,
    nameNewTab: "",
    uuid_filter: null,
    colorNewTab: "#EEFDFF",
    tab: [],
    chosenFilter: false,
    hasBoards: null,
    editedTab: null,
  }),
  directives: {
    ClickOutside
  },
  watch: {},
  async mounted() {
    // console.log("User",this.getUser)
    EventBus.$on("crm-item-edit", item => {
      this.openItemDialog("edit", item);
    });
    EventBus.$on("crm-board-items-reset", async respons => {
      this.boardSetting.list = await crmService.getCrmBoardsList();
      if (!this.boardsItem.length) {
        // orderDialog('respons', respons);
        if (respons) {
          this.boardsItem = respons;
          this.setUuidBoard(respons.uuid);
        } else {
          this.boardsItem = this.boardSetting.list[0];
        }
        this.getStateList();
      }
    });

    try {
      this.setLoading(true);
      await crmService.getCrmBoardsList().then(response => {
        this.boardSetting.list = response;

        // eslint-disable-next-line prefer-destructuring
        if (response.length) {
          if (this.uuid_board.length) {
            // // orderDialog('uuid_board', this.uuid_board);
            this.boardsItem = {
              uuid: this.uuid_board
            };
          } else {
            this.setUuidBoard(response[0].uuid);
            // eslint-disable-next-line prefer-destructuring
            this.boardsItem = response[0];
          }
          this.getStateList().then(() => {
            if (this.uuid_tab.length) {
              this.uuid_filter = this.uuid_tab;
              this.tab.forEach((item, index) => {
                if (this.uuid_filter === item.uuid) {
                  this.activeTab = index;
                }
              });
            }
          });
          this.hasBoards = Boolean(response.length);
          this.getDataItemBoard();
        } else {
          this.boardSetting.visible = true
          this.hasBoards = Boolean(response.length);
        }
      });
      this.setLoading(false);
    } catch (e) {
      console.warn(e);
      this.setErrorNotification(e);
      this.setLoading(false);
    }
  },
  computed: {
    ...mapGetters("crm", {
      uuid_board: getterTypes.GET_UUID_BOARD,
      uuid_tab: getterTypes.GET_UUID_TAB
    }),
    isBoardCreator() {
      console.log(this.boardsItem);
      if (this.boardsItem) {
        return this.boardsItem.uuid_user_created === this.getUser.uuid;
      }
      return false;
    },
    isAllowEditState() {
      console.log(this.boardsItem);
      if ("access" in this.boardsItem) {
        console.log("is_allow_state_edit", this.boardsItem?.access.is_allow_state_edit === "1");
        return this.boardsItem?.access.is_allow_state_edit === "1";
      } else {
        return true;
      }
    },
    isAccessDeportInfo() {
      if ("access" in this.boardsItem) {
        console.log("is_access_deport_info", this.boardsItem?.access.is_access_deport_info === "1");
        return this.boardsItem.access.is_access_deport_info === "1";
      } else {
        return true;
      }
    },
    isAllowSendOrder() {
      if ("access" in this.boardsItem) {
        console.log(this.boardsItem)
        console.log("is_allow_send_order", this.boardsItem?.access.is_allow_send_order === "1");

        return this.boardsItem.access.is_allow_send_order === "1";
      } else {
        return true;
      }
    }
  },
  methods: {
    ...mapActions("crm", {
      setUuidBoard: actionTypes.SET_UUID_BOARD,
      setUuidTab: actionTypes.SET_UUID_TAB
    }),
    dblClick(item) {
      if (this.isAllowEditState) {
        console.log(this.isAllowEditState);
        item.uuid_user = this.openEditTab(item.noEdit);
        this.editedTab = {...item}
      }
    },
    async getDataItemBoard() {
      try {
        await crmService.getCrmBoardsItemInfo(this.uuid_board).then(request => {
          // // orderDialog('request', request);
          console.log(this.boardsItem)
          this.boardsItem = {
            ...this.boardsItem,
            ...request
          };
          console.log(this.boardsItem)
        });
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    changeBoard() {
      // orderDialog('changeBoard');
      this.setUuidBoard(this.boardsItem.uuid || "");
      this.getStateList();
      this.activeTab = 0;
      this.uuid_filter = null;
    },
    updateItemCallState(item) {
      if (item.is_call_state === "0") {
        item.is_call_state = "1";
      } else {
        item.is_call_state = "0";
      }
      // this.outSideEditTab()
    },
    async getStateList() {
      try {
        this.setLoading(true);
        const stateList = await crmService.getCrmUserStateList(this.boardsItem.uuid);

        const startTab = {
          name: this.$t("btn.all"),
          uuid_user: true,
          uuid: "00000000-0000-0000-0000-000000000000",
          position: 0,
          noEdit: true,
          color: "#FFF"
        };
        this.tab = [startTab, ...stateList];
        this.itemDialog.user_states = this.tab;
        this.setLoading(false);
      } catch (e) {
        this.setErrorNotification(e);
        this.setLoading(false);
      }
    },
    openEditTab(val) {
      if (val) {
        return true;
      }
      return false;
    },
    outSideEditTab() {
      // orderDialog('asdasd');
      Object.keys(this.tab).forEach(key => {
        if (!this.tab[key].uuid_user && !this.tab[key].noEdit) {
          // orderDialog(this.tab[key]);
          this.tab[key].uuid_user = true;
          this.editedTab.uuid_user = true
          this.onUpdateTab(this.tab[key]);
        }
      });
    },
    openItemDialog(type, item) {
      this.itemDialog.type = type;
      this.itemDialog.item = item;
      this.itemDialog.visible = true;
    },
    moveTab(val) {
      // orderDialog('testttt', val);
      this.activeTab = Number(val.moved.newIndex);
      // eslint-disable-next-line no-plusplus
      if (Number(val.moved.newIndex) < Number(val.moved.oldIndex)) {
        // orderDialog('right');
        for (let i = Number(val.moved.newIndex); i <= Number(val.moved.oldIndex); i++) {
          this.onUpdatePositionTab({
            ...this.tab[i],
            position: i
          });
        }
      } else {
        // orderDialog('left');
        // eslint-disable-next-line no-plusplus
        for (let i = Number(val.moved.oldIndex); i <= Number(val.moved.newIndex); i++) {
          this.onUpdatePositionTab({
            ...this.tab[i],
            position: i
          });
        }
      }

      this.updateItems(val.moved.element.uuid, val.moved.element.noEdit);
    },
    outSideCreateTab() {
      this.onAddTab();
    },
    async onAddTab() {
      if (this.nameNewTab.length) {
        try {
          const formData = new FormData();
          formData.append("name", this.nameNewTab);
          formData.append("uuid_board", this.boardsItem.uuid);
          formData.append("color", this.colorNewTab || "#FFF");

          const newState = await crmService.addCrmUserState(formData);
          // orderDialog('newState', newState);

          this.tab.push(newState);
          this.nameNewTab = "";
          this.dialogNewTab = false;
        } catch (e) {
          this.setErrorNotification(e);
        }
      }
    },
    async onUpdatePositionTab(data) {
      try {
        const formData = new FormData();
        formData.append("uuid", data.uuid);
        formData.append("position", data.position);
        formData.append("name", data.name);

        await crmService.updateCrmUserState(formData);
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async onUpdateTab(data) {
      if (!_.isEqual(this.editedTab, data)) {
        try {
          const formData = new FormData();
          formData.append("uuid", data.uuid);
          formData.append("name", data.name);
          // formData.append('uuid_board', this.boardsItem.uuid);
          formData.append("color", data.color);
          formData.append("position", data.position);
          formData.append("is_call_state", data.is_call_state);

          await crmService.updateCrmUserState(formData);
          // // orderDialog('newState', updateState);
          EventBus.$emit("crm-items-reset");
        } catch (e) {
          this.setErrorNotification(e);
        }
      }
    },
    updateItems(uuid, status) {
      // if('access' in this.boardsItem && this.boardsItem.access.is_allow_state_edit === '0')return
      if (this.dialogNewTab) {
        this.dialogNewTab = false
      }
      this.chosenFilter = false;
      if (!status) {
        this.setUuidTab(uuid);
        this.uuid_filter = uuid;
      } else {
        this.uuid_filter = null;
        this.setUuidTab("");
      }
    }
  }
};
</script>

<style lang="scss">
.select-shared-board__item {
 
  align-items: center;
  .v-icon {
    margin-right: auto;
    width: 15px;
    height: 15px;
  }
}
.btn-settingBoard {
  min-width: 42px !important;
  padding: 0 !important;
}
.crm-tabDraggable {
  display: flex;
}
.section-crm {
  &__content {
  }
  .crm-tab {
    margin-bottom: 0px;
    border-bottom: 2px solid #eeeeee;
    padding: 0 9px;
    &__add {
      border: 1px solid #eeeded;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      cursor: pointer;
      margin: 2px 5px 0;
    }
    &__addInput {
      margin: 2px 5px 0;
      background: #ffffff;
      display: flex;
      align-items: center;
      border: 1px solid #eeeded;
      box-shadow: 1px 2px 10px rgba(154, 154, 154, 0.15);
      border-radius: 4px;
      height: 30px;
      input {
        outline: 0;
        padding: 0 10px;
        width: 90px;
      }
      &-color {
        display: flex;
        align-items: center;
        span {
          width: 14px;
          height: 14px;
          border: 1px solid #676767;
          border-radius: 50%;
          display: block;
        }
      }
    }
    .v-tabs-bar {
      height: auto;
    }
    .v-tab {
      padding: 0 0 2px !important;
      margin: 0 1.5px;
      min-width: auto;

      border: 2px solid transparent;
      border-radius: 8px 8px 0 0;

      &:before {
        display: none !important;
      }

      .tab-edit {
        margin: 0 5px;
        background: #ffffff;
        display: flex;
        align-items: center;
        border: 1px solid #eeeded;
        box-shadow: 1px 2px 10px rgba(154, 154, 154, 0.15);
        border-radius: 4px;
        height: 30px;
        input {
          outline: 0;
          padding: 0 10px;
          width: 90px;
        }
        &-color {
          display: flex;
          align-items: center;
          span {
            width: 14px;
            height: 14px;
            border: 1px solid #676767;
            border-radius: 50%;
            display: block;
          }
        }
      }
      .tab-text {
        border: 1px solid #eeeeee;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #676767;
        text-transform: none;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        span {
          padding-left: 10px;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
        }
      }
      &.active {
        padding: 0 !important;
        border: 0;
        &.white {
          border: 2px solid #eeeeee !important;
          border-bottom-color: transparent;
          .tab-text {
            padding: 5px 17px 7px !important;
          }
        }
        .tab-text {
          padding: 7px 19px 9px !important;
          border-radius: 8px 8px 0 0;
          border: 1px solid transparent;
          color: #272727;
        }
      }
    }
  }
}
</style>
