<template>
  <div class="table_wrapper">
    <v-data-table
      class=" my-table supply"
      :loading="loadingItems"
      :headers="processedDataHeaders"
      :items="processedData"
      :expanded.sync="expanded"
      item-key="uuid"
      show-expand
      hide-default-footer
      :no-data-text="$t('table.noData')"
      loader-height="1"
      :items-per-page="processedData.length"
      dense
    >
    <!-- eslint-disable-next-line -->
      <template v-slot:item.image="{ item }">
        <img
          v-if="!item.image"
          :src="require('@/assets/images/supply-item.png')"
          class="image-placeholder sm"
        />
        <v-img v-else :src="item.image" class="image  sm" @click="showImgDialog(item.image)">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                :size="20"
                :width="2"
                color="green lighten-1"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.bar_code="{ item }"> bar: {{ item.bar_code }} </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.options="{ item }">
        <v-row
          no-gutters
          v-for="(option, key) in item.all_options"
          :key="key"
          justify="space-between"
          align="center"
        >
            <span cols="3" class="grey--text text--darken-1  ">
              <div v-if="key === 'null'">--</div>
              <div v-else>{{ `${key} ` || "--" }}</div>
            </span>
          <v-col cols="auto">
            –
          </v-col>

          <span cols="auto" class="success--text"> ({{ option || 0 }})</span>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            class=" my-table supply expanded-table"
            :headers="expandedDataHeaders"
            :items="item.children"
            item-key="uuid"
            hide-default-footer
            hide-default-header
            no-data-text="Список порожній"
            :items-per-page="item.children.length"
            dense
          >
          <!-- eslint-disable-next-line -->
            <template v-slot:item.image="{ item }">
              <img
                v-if="!item.image"
                :src="require('@/assets/images/supply-item.png')"
                class="image-placeholder  sm"
              />
              <v-img
                v-else
                :src="item.image"
                class="image  sm"
                @click="showImgDialog(item.image)"
                v-show="isImgLoaded"
                @load="showImg"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                    style="min-height:60px"
                  >
                    <v-progress-circular
                      indeterminate
                      :size="20"
                      :width="2"
                      color="green lighten-1"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.ns_code="{ item }"> ns: {{ item.ns_code || "--" }} </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.count="{ item }">
              <div v-if="type === 'supply-returned-items'" class="text-center">
                <VIcon color="success" v-if="item.available">mdi-check</VIcon>
                <VIcon color="error" v-else>mdi-close</VIcon>
              </div>
              <div v-else class="text-center">
                {{ item.amount || 1 }}
              </div>
            </template>
          </v-data-table>

          <!-- <v-simple-table class="my-table supply light expanded-table" dense>
            <tbody>
              <tr v-for="childrenItem in item.children" :key="childrenItem.uuid">
                <td v-if="watchDelivery && type === 'supply-chosenItems'">
                  <VBtn
                    color="red"
                    :disabled="blockedItem"
                    @click="$emit('onClickDelete', item.uuid)"
                    icon
                  >
                    <VIcon>mdi-close</VIcon>
                  </VBtn>
                </td>
                <td>
                  <img
                    v-if="!childrenItem.image && isImgLoaded"
                    :src="require('@/assets/images/supply-item.png')"
                    class="image-placeholder sm"
                  />
                  <v-img
                    v-else
                    :src="childrenItem.image"
                    class="image sm"
                    @click="showImgDialog(childrenItem.image)"
                    v-show="isImgLoaded"
                    @load="showImg"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                        style="min-height:60px"
                      >
                        <v-progress-circular
                          indeterminate
                          :size="20"
                          :width="2"
                          color="green lighten-1"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </td>
                <td class="md">{{ childrenItem.name }}</td>
                <td>ns: {{ childrenItem.ns_code }}</td>
                <td>{{ childrenItem.options }}</td>
                <td v-if="type === 'supply-returned-items'" class="text-center">
                  <VIcon color="success" v-if="childrenItem.available">mdi-check</VIcon>
                  <VIcon color="error" v-else>mdi-close</VIcon>
                </td>
                <td v-else class="text-center">
                  {{ childrenItem.amount || 1 }}
                </td>
              </tr>
            </tbody>
          </v-simple-table> -->
        </td>
      </template>
    </v-data-table>
    <v-row justify="end" v-if="processedData.length">
      <v-col cols="auto">
        <v-subheader> {{ $t("table.amount") }}: {{ allAmount }} </v-subheader>
      </v-col>
    </v-row>
    <img-dialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
  </div>
</template>

<script>
import imgDialog from "@/components/products/imgDialog.vue";

export default {
  data: () => ({
    isImgLoaded: false,
    imgpath: "",
    expanded: [],
    imgDialog: false,
    allAmount: 0,
    processedData: [],
    expandedDataHeaders: [
      {
        align: "start",
        sortable: false,
        value: "image"
      },
      {
        align: "start",
        sortable: false,
        value: "name"
      },
      {
        align: "start",
        sortable: false,
        value: "ns_code"
      },
      {
        align: "start",
        sortable: false,
        value: "options"
      },
      {
        align: "center",
        sortable: false,
        value: "count"
      }
    ],
    processedDataHeaders: []
  }),
  props: {
    items: {
      require: true
    },
    type: {
      require: true
    },
    loadingItems: {
      require: true
    },
    blockedItem: {
      require: true
    },
    watchDelivery: {
      require: true
    }
  },
  mounted() {
    console.warn(this.items)
    this.processedDataHeaders = [
      {
        text: this.$t("table.photo"),
        align: "center",
        sortable: false,
        value: "image"
      },
      {
        text: this.$t("table.name"),
        align: "start",
        sortable: false,
        value: "name"
      },
      {
        text: this.$t("table.code"),
        align: "start",
        sortable: false,
        value: "bar_code"
      },
      {
        text: this.$t("table.options"),
        align: "start",
        sortable: false,
        value: "options"
      },
      {
        text: this.$t("table.amount"),
        align: "center",
        sortable: false,
        value: "amount"
      }
    ];
    if (this.items.length) {
      this.setData();
    }
  },
  methods: {
    setData() {
      const UniqueItemBarCode = [...new Set(this.items.map(item => item.product_details.bar_code))];
      const UniqueItems = [];
      let childrenItems = [...this.items];
      let amount = 0;

      UniqueItemBarCode.forEach(barCode => {
        [...childrenItems]
          .filter(childrenItem => childrenItem.product_details.bar_code === barCode)
          .forEach(() => amount++);
        const uniqueItemChildrenData = childrenItems.find(
          item => item.product_details.bar_code === barCode
        );
        UniqueItems.push(this.convertToData(uniqueItemChildrenData, amount));
        amount = 0;
      });
      const allOptions = [];
      childrenItems.forEach(item => {
        allOptions.push({
          bar_code: item.product_details.bar_code,
          option_name: item.option_details.name
        });
      });

      childrenItems.forEach(item => {
        this.allAmount++;

        UniqueItems.forEach(uniqueItem => {
          if (uniqueItem.bar_code === item.product_details.bar_code) {
            let result = {};
            allOptions.forEach(function(option) {
              if (option.bar_code === uniqueItem.bar_code) {
                result[option.option_name] = result[option.option_name] + 1 || 1;
              }
            });
            uniqueItem.children.push(this.convertToData(item));

            uniqueItem.all_options = result;
            result = {};
          }
        });

        childrenItems = childrenItems.filter(childrenItem => childrenItem.uuid !== item.uuid);
      });

      this.processedData = UniqueItems;
      console.log(this.processedData);
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    showImgDialog(image) {
      this.imgpath = image;
      this.imgDialog = true;
    },
    convertToData(item, amount) {
      const newItem = {};
      newItem.uuid = item.uuid;
      newItem.image = item.product_details?.path_img_thumb || null;
      newItem.name = item.product_details?.name || "--";
      newItem.bar_code = item.product_details?.bar_code || "--";
      newItem.options = item.option_details?.name || "--";
      newItem.ns_code = item.ns_code || "--";
      newItem.children = [];
      newItem.all_options = [];
      if (this.type === "supply-returned-items") {
        newItem.available = item.available;
      }
      newItem.amount = amount;
      return newItem;
    }
  },
  watch: {
    items: {
      deep: true,
      handler(e) {
        console.log(e);
        if (e.length) {
          this.setData();
        }
      }
    }
  },
  components: {
    imgDialog
  }
};
</script>

<style lang="scss">
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  td:first-child {
    padding: 0;
  }
  box-shadow: inset 0px 4px 8px -8px rgba(60, 60, 60, 0.75),
    inset 0px -4px 8px -8px rgba(79, 79, 79, 0.75) !important;
  transition: 4s !important;
}
.expanded-table {
  display: flex;
  justify-content: end;
  .v-data-table__wrapper {
    width: 90%;
  }
}
.expanded-image {
  margin: 5px 0;
}
</style>
