<template>
  <v-col cols="12">
    <v-row justify="space-between">
      <v-col>
        <span
          class="font-20 text-uppercase font-weight-medium d-flex align-center py-0"
          :class="{ 'cursor-pointer': activeTab === 1 }"
          @click="activeTab = 0"
        >
          {{ typeDialog === "add" && !activeProduct ? $t("supply.create_supply_title") : $t("supply.update") }}
        </span>
      </v-col>

      <!-- <span v-if="tabStep === 1">
          <v-icon>mdi-chevron-right</v-icon>
          <span class="font-20 text-uppercase font-weight-regular">ТОВАРИ</span>
        </span> -->

      <v-col cols="2" class=" d-flex justify-end pt-0 align-center px-0">
        <div v-if="blocked" class="mr-0">
          <v-btn color="error darken-1" x-small fab elevation="0">
            <v-icon color="white">mdi-lock</v-icon>
          </v-btn>
        </div>

        <v-menu
          :close-on-content-click="false"
          offset-y
          transition="slide-y-transition"
          v-if="
            $vuetify.breakpoint.smAndDown ||
              (typeDialog === 'edit' && uuid_state < 430) ||
              (typeDialog === 'edit' && uuid_state == 400 && is_blocked == 0)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="pl-4">
              <v-icon v-bind="attrs" v-on="on" :disabled="loading">mdi-dots-vertical</v-icon>
            </div>
          </template>

          <v-card>
            <v-list :width="250">
              <v-list-item v-if="typeDialog === 'edit' && uuid_state < 430">
                <v-row>
                  <v-col cols="2">
                    <v-icon :dense="$vuetify.breakpoint.smAndDown" v-if="blocked">
                      mdi-lock
                    </v-icon>
                    <v-icon :dense="$vuetify.breakpoint.smAndDown" v-else>
                      mdi-lock-open
                    </v-icon>
                  </v-col>
                  <v-col cols="10" class="text-center">
                    <v-switch
                      class="ml-2"
                      color="success"
                      :label="labelBlocked"
                      :disabled="checkBlocked"
                      hide-details
                      @change="blockedSupplyDialog = true"
                      v-model="blocked"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-list-item>
              <v-divider
                v-if="uuid_state == 400 && typeDialog === 'edit' && is_blocked == 0"
                inset
                class="ml-14"
              />
              <v-list-item
                v-if="typeDialog === 'edit' && uuid_state == 400 && is_blocked == 0"
                @click="deleteSupplyDialog = true"
              >
                <v-row align="center">
                  <v-col cols="2">
                    <v-icon :dense="$vuetify.breakpoint.smAndDown">mdi-delete</v-icon>
                  </v-col>
                  <v-col cols="10" class="text-left font-weight-regular " style="color:#808080">
                    {{ $t("btn.delete") }}
                  </v-col>
                </v-row>
              </v-list-item>
              <v-divider
                v-if="$vuetify.breakpoint.smAndDown && typeDialog === 'edit' && uuid_state < 430"
                inset
                class="ml-14"
              />
              <v-list-item @click="$emit('showConfirmDialog')" v-if="$vuetify.breakpoint.smAndDown">
                <v-row align="center">
                  <v-col cols="2">
                    <v-icon :dense="$vuetify.breakpoint.smAndDown">mdi-close</v-icon>
                  </v-col>
                  <v-col cols="10" class="text-left font-weight-regular" style="color:#808080">
                    {{ $t("btn.close") }}
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row align="center" no-gutters>
      <v-col class="py-0 px-0" cols="auto" xl="auto" md="auto" sm="auto">
        <v-card-subtitle class="pt-0 pb-1 px-0 d-flex" v-if="typeDialog !== 'add' || activeProduct">
          {{ $t("supply.supply_number") }} –
          <div v-if="loading" class="ml-1">
            <v-progress-circular indeterminate size="10" width="1" color="primary" />
          </div>
          <div v-else class="copyTxt ml-1" @click="copyToClipboard(item.number)">
            {{ itemNumber }}
          </div>
        </v-card-subtitle>
      </v-col>
      <v-col
        cols="12"
        xl="auto"
        md="auto"
        sm="auto"
        class="ml-auto"
        v-if="typeDialog === 'edit' && list_states.length && uuid_state >= 401 && uuid_state != 440"
      >
        <v-row align="center" no-gutters>
          <v-col cols="12">
            <v-select
              :items="list_states"
              filled
              item-text="name"
              item-value="id"
              dense
              single-line
              placeholder="Статус"
              ref="stateChange"
              hide-details
              v-model="state"
              @change="setState($event)"
              return-object
              :menu-props="{ bottom: true, offsetY: true }"
            >
              <template slot="item" slot-scope="data">
                {{ data.item.uk_name || data.item.name || "" }}
              </template>
              <template slot="selection" slot-scope="data">
                {{ data.item.uk_name || data.item.name || "" }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col 
          cols="12"
          xl="auto"
          md="auto"
          sm="auto" v-if="(typeDialog === 'edit' && !list_states.length && !loading) || item.id_state == '440'" class="ml-auto">
          <div class="d-flex align-center justify-content-start v-card__subtitle pa-0">
              <StatusCircle :status-name="item.id_state" :type="'supply'" />
              <div class="elispsis">
                  {{ item.state_details.uk_name }}
              </div>
          </div>
        </v-col>
      <v-col
        cols="auto"
        xl="auto"
        md="auto"
        sm="auto"
        class="ml-3"
        v-if="
          uuid_state === 440 &&
            item.uuid_parent === '00000000-0000-0000-0000-000000000000' &&
            permissions.can_return_delivery
        "
      >
        <v-btn outlined icon small @click="$emit('onReturn')">
          <v-icon small>
            mdi-arrow-u-left-top-bold
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <ConfirmDialog
      :visible="deleteSupplyDialog"
      @close="deleteSupplyDialog = false"
      @onConfirm="onDelete"
      @onCancel="deleteSupplyDialog = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.accept_deleteTitle") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.confirmDeleteSupplyDesc") }}
      </template>
    </ConfirmDialog>
    <ConfirmDisableSupply
      @delete="confirmBlocked"
      :visible="blockedSupplyDialog"
      :statusBlocked="is_blocked"
      @close="closeConfirmBlocked"
    />
  </v-col>
</template>

<script>
import language from "../../../../mixins/language";
import ConfirmDisableSupply from "../../../dialog/ConfirmDisableSupply.vue";
import supplyService from "../../../../services/request/supply/supplyService";
import notifications from "../../../../mixins/notifications";
import loader from "../../../../mixins/loader";
import user from "../../../../mixins/user";
import ConfirmDialog from "../../../dialog/ConfirmDialog.vue";
import StatusCircle from "@/components/move-list/StatusCircle.vue";


export default {
  components: { ConfirmDisableSupply, ConfirmDialog, StatusCircle, },
  name: "headerDialogSupply",
  data: () => ({
    state: 400,
    deleteSupplyDialog: false,
    blockedSupplyDialog: false,
    blocked: false
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    confirmBlocked(e) {
      this.$emit("blocked", e);
      this.blockedSupplyDialog = false;
    },
    closeConfirmBlocked() {
      this.blocked = !this.blocked;
      this.blockedSupplyDialog = false;
    },
    async onDelete() {
      try {
        this.setLoading(true);
        await supplyService.deleteDeliverySuplly(this.itemUuid);
        this.deleteSupplyDialog = false;
        this.$emit("onDeleteSupply");
        this.setLoading(false);
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    fetchData() {
      console.log(this.cancelReturn);
      if (this.cancelReturn) {
        this.$emit("onCancelReturn", this.cancelReturn);
      }

      this.state = this.uuid_state.toString();
      this.blocked = this.is_blocked;
    },
    setState() {
      this.$emit("change", this.state);
    }
  },
  computed: {
    labelBlocked() {
      if (!this.blocked) {
        return this.$t("supply.block");
      } else {
        if (!this.permissions.client || !this.permissions.employees) {
          return this.$t("supply.unblock");
        }
        return this.$t("supply.blocked");
      }
    },
    checkBlocked() {
      if (this.blocked && (this.permissions.client || this.permissions.employees)) {
        return true;
      }
      return false;
    }
  },
  props: {
    typeDialog: String,
    activeTab: Number,
    uuid_state: {
      type: Number,
      require: true
    },
    cancelReturn: {
      type: Boolean,
      require: true
    },
    itemNumber: {
      type: String,
      require: true
    },
    itemUuid: {
      type: String,
      require: true
    },
    list_states: {
      type: Array,
      require: true
    },
    is_blocked: {
      require: true
    },
    loading: {
      type: Boolean,
      require: true
    },
    tabStep: {
      type: Number,
      require: true
    },
    activeProduct: {
      type: Boolean,
      require: true
    },
    item: {
      require: false
    }
  },
  watch: {
    uuid_state: "fetchData",
    is_blocked: "fetchData",
    cancelReturn: "fetchData",
    item: {
      deep: true,
      handler(e) {
        console.log(e);
      }
    }
  },
  mixins: [language, notifications, loader, user]
};
</script>
<style lang="scss" scoped>
.sm-del-suplly {
  margin-right: 10px;
}
</style>
