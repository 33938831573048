<template>
  <div>
    <v-simple-table
      class="my-table"
      :dense="!isLarge"
      v-if="!$vuetify.breakpoint.smAndDown"
      :height="getTableHeight"
      fixed-header
    >
      <thead>
        <tr>
          <th class="icon text-center"><VIcon dense>mdi-cogs</VIcon></th>
          <th>
            <div class="header">
              <span>№</span>
              <VTextField dense hide-details solo v-model="filterProps.number" clearable />
            </div>
          </th>
          <th>
            <div class="header">
              <span>{{ $t("table.ttn") }}</span>
              <VTextField dense hide-details solo v-model="filterProps.ttn" clearable />
            </div>
          </th>
          <th>
            <div class="header">
              <span>{{ $t("table.phone") }}</span>
              <VTextField
                dense
                hide-details
                solo
                v-model="filterProps.delivery_address_details__phone"
                clearable
              />
            </div>
          </th>
          <th>
            <div class="header">
              <span>{{ $t("table.name") }}</span>
              <VTextField
                dense
                hide-details
                solo
                v-model="filterProps.delivery_address_details__name"
                clearable
              />
            </div>
          </th>
          <th>
            <div class="header">
              <span>{{ $t("table.surname") }}</span>
              <VTextField
                dense
                hide-details
                solo
                v-model="filterProps.delivery_address_details__surname"
                clearable
              />
            </div>
          </th>
          <th>
            <div class="header">
              <span>{{ $t("table.sum") }}</span>
              <VTextField dense hide-details disabled v-model="filterProps.price" clearable />
            </div>
          </th>
          <th>
            <div class="header">
              <span>CRM {{ $t("table.status") }}</span>
              <VTextField dense hide-details disabled v-model="filterProps.product" clearable />
            </div>
          </th>

          <th>
            <div class="header mt-4">
              <span>{{ $t("table.createdData") }}</span>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-103"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="text-caption"
              >
                <template v-slot:activator="{ on, attrs }">
                  <VTextField
                    class="bg-white"
                    v-model="filterProps.time_created"
                    readonly
                    append-icon="mdi-calendar"
                    solo
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  />
                </template>
                <v-date-picker
                  :locale="currentLanguage.key"
                  no-title
                  :max="NextYear"
                  min="2021"
                  v-model="filterProps.time_created"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </th>
          <th class="icon text-center">
            <v-tooltip bottom min-width="200" max-width="200" LEFT content-class="text-center">
              <template v-slot:activator="{ on, attrs }">
                <VIcon
                  @click="onChangeCallRequired"
                  class="mr-1"
                  :color="filterProps.is_call_required === '1' ? 'primary' : 'grey'"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-phone
                </VIcon>
              </template>

              {{ filterProps.is_call_required === "1" ? "Потребує колл центру" : "Всі" }}
            </v-tooltip>
          </th>
          <th class="icon text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <VIcon
                  @click="onChangeType"
                  class="mr-1"
                  :color="filterProps.type === 'out' ? 'green' : 'grey'"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-truck-delivery
                </VIcon>
              </template>
              Відправлено
            </v-tooltip>
          </th>
          <th>
            <div class="header">
              <span>NS {{ $t("table.status") }}</span>
              <VSelect
                dense
                :placeholder="$t('btn.all')"
                :items="Object.values(states)"
                :item-text="getStatusText"
                :item-value="'id'"
                hide-details
                solo
                v-model="filterProps.id_state"
                clearable
                class="text-caption"
                :menu-props="{ bottom: true, offsetY: true }"
              >
                <template v-slot:item="{ item }">
                  <div class="d-flex align-center justify-content-start ">
                    <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
                    <div class="elispsis">
                      {{ getStatusText(item) }}
                    </div>
                  </div>
                </template>
              </VSelect>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <CrmItemRow
          v-for="item in items"
          :key="item.uuid"
          :item="item"
          :colors="colors"
          :uuid_board="uuid_board"
        />
        <tr v-if="!loadMoreDisabled && !loadingItems">
          <td
            colspan="10"
            v-observer="{ nextPage: nextPage }"
          >
            <v-progress-linear
              v-if="isLoadingFilter"
              indeterminate
              color="primary"
            ></v-progress-linear>
          </td>
        </tr>

        <tr v-if="!items.length">
          <td colspan="12" class="bg-white" id="no_data_td_crm">
            <div class="text-center">
              <h3>{{ $t("table.noData") }}</h3>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div v-else>
      <CrmFilter :filterPropsExact="filterProps" @onApplyFilter="onApplyFilter" />
      <crm-row-item-card
        v-for="crmItem in items"
        :key="crmItem.uuid"
        :supply-item="crmItem"
        :colors="colors"
        :uuid_board="uuid_board"
      />
      <div
        v-observer="{ nextPage: nextPage }"
        class="observer"
        v-if="!loadMoreDisabled && !loadingItems"
      ></div>
      <div v-if="!items.length && !loadingItems">
        <div class="text-center">
          <h3>{{ $t("table.noData") }}</h3>
        </div>
      </div>
    </div>

    <!-- <div class="text-center mt-3">
      <VBtn
        depressed
        color="blue"
        outlined
        dark
        v-if="!loadMoreDisabled"
        @click="nextPage"
        class="br-10"
      >
        Завантажити більше
      </VBtn>
    </div> -->
  </div>
</template>

<script>
import _ from "lodash";
import notifications from "../../../mixins/notifications";
import loader from "../../../mixins/loader";
import crmService from "../../../services/request/crm/crmService";
import CrmItemRow from "./CrmItemRow.vue";
import EventBus from "../../../events/EventBus";
import SizeUi from "../../../mixins/SizeUi";
import { mapGetters } from "vuex";
import CrmRowItemCard from "./sm/CrmRowItemCard.vue";
import CrmFilter from "./sm/CrmFilter.vue";
import depotService from "@/services/request/depot/depotService";
import StatusCircle from "@/components/move-list/StatusCircle.vue";

export default {
  name: "CrmRowList",
  components: {
    CrmItemRow,
    CrmRowItemCard,
    CrmFilter,
    StatusCircle
  },
  mixins: [loader, notifications, SizeUi],
  data: () => ({
    dateMenu: false,
    filterProps: {
      number: "",
      ttn: "",
      delivery_address_details__name: "",
      delivery_address_details__surname: "",
      delivery_address_details__phone: "",
      time_created: "",
      price: "",
      product: "",
      address: "",
      type: false,
      filter_ready_to_deliver: false,
      is_call_required: null,
      id_state:null
    },
    page: 1,
    perPageList: [5, 10, 15],
    firstNumber: "",
    secondNumber: "",
    total: 12,
    items: [],
    loadMoreDisabled: false,
    isLoadingFilter: false,
    loadingItems: true,
    windowHeight: document.documentElement.clientHeight,
    states: []
  }),
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowHeight);
  },
  async mounted() {
    window.addEventListener("resize", this.getWindowHeight);
    // // orderDialog('colors-row', this.colors);
    this.updateItems();
    this.states = await depotService.getAllStates({
      type: "DELIVERY_OUT"
    });
    EventBus.$on("crm-items-reset", () => {
      this.updateItems(true);
    });
  },
  methods: {
    getStatusText(item) {
      return item[`${this.currentLanguage.key}_name`];
    },
    getWindowHeight: _.debounce(function() {
      this.windowHeight = document.documentElement.clientHeight;
    }, 500),
    onChangeType() {
      if (this.filterProps.type === "out") {
        this.filterProps.type = undefined;
      } else {
        this.filterProps.type = "out";
      }
    },
    onChangeCallRequired() {
      if (this.filterProps.is_call_required === "1") {
        this.filterProps.is_call_required = null;
      } else {
        this.filterProps.is_call_required = "1";
      }
    },
    onApplyFilter(filterItems) {
      this.filterProps = {
        ...filterItems
      };
      this.updateItems();
    },
    bgItem(item, colors) {
      if (colors.lenght) {
        // orderDialog('item', item);
        // orderDialog('colors', colors);
        const color = colors.filter(colorItem => colorItem.uuid === item.uuid_user_state);
        // orderDialog('color', color);
        return color[0].color || "";
      }
      return "";
    },
    resetPagination() {
      this.page = 1;
    },
    async updateItems(updated) {
      if (!updated) {
        this.resetPagination();
      }
      try {
        const params = {};
        Object.keys(this.filterProps).forEach(key => {
          if (this.filterProps[key]) {
            params[key] = this.filterProps[key];
          }
        });

        if (this.uuid_filter !== null) {
          params.uuid_user_state = this.uuid_filter;
        }

        params.offset = 0;
        params.limit = updated ? this.largeLimit : this.perPage;
        const orderList = await crmService.getCrmOrderList({
          uuid_board: this.uuid_board,
          ...params
        });
        this.items = orderList;

        this.loadMoreDisabled = this.items.length < this.perPage;
        this.loadingItems = false;
      } catch (e) {
        this.loadingItems = false;
        this.setErrorNotification(e);
      }
    },
    getPaginationParams() {
      return {
        limit: this.perPage,
        offset: (this.page - 1) * this.perPage,
        uuid_board: this.uuid_board
      };
    },
    async emitPageChange() {
      try {
        this.loadingItems = true;
        const params = this.getPaginationParams();
        Object.keys(this.filterProps).forEach(key => {
          if (this.filterProps[key]) {
            params[key] = this.filterProps[key];
          }
        });
        if (this.uuid_filter !== null) {
          params.uuid_user_state = this.uuid_filter;
        }
        const orderList = await crmService.getCrmOrderList(params);
        this.items.push(...orderList);

        this.loadMoreDisabled = orderList.length < this.perPage;
      } catch (e) {
        this.setErrorNotification(e);
      } finally {
        this.loadingItems = false;
      }
    },
    async nextPage() {
      this.isLoadingFilter = true;
      this.page += 1;
      await this.emitPageChange();
      this.isLoadingFilter = false;
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    dragOptions() {
      return {
        disabled: false,
        ghostClass: "ghost",
        handle: ".handle"
      };
    },
    getTableHeight() {
      return this.windowHeight - 184 + "px";
    },
    largeLimit() {
      const pageLimit = this.perPage * this.page;
      if (pageLimit >= 200) {
        this.page = this.pageBeforeLimit - 1;
        return 200;
      }
      return this.perPage * this.page;
    },
    NextYear() {
      const event = new Date().toISOString().substr(0, 12);
      return event;
    },
    rowHeight() {
      if (this.isLarge) {
        return 48;
      } else {
        return 35;
      }
    },
    perPage() {
      return Math.ceil((window.innerHeight - 150) / this.rowHeight);
    }
  },
  props: {
    uuid_filter: String,
    uuid_board: String,
    colors: {
      required: true
    },
    chosenFilter: {
      required: false
    }
  },
  watch: {
    uuid_filter(e) {
      if (!this.filterProps.filter_ready_to_deliver) {
        this.updateItems();
      }
    },
    uuid_board: "updateItems",
    filterProps: {
      deep: true,
      handler(e) {
        this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
        this.updateItems();
      }
    },
    chosenFilter: {
      deep: true,
      handler(chosenFilter) {
        const readyToDeliverValue = "filter_ready_to_deliver";
        this.filterProps[readyToDeliverValue] = chosenFilter;
      }
    }
  }
};
</script>

<style lang="scss">
.v-date-picker-table {
  height: 100%;
}
#no_data_td_crm {
  border: none;
}
.ghost {
  opacity: 0.5;
  background: gray !important;
}
.crm-order {
  &__null {
    padding: 50px 0;
  }
  &__header {
    border-bottom: 1px solid #f2f2f2;
    .td {
      padding: 4px;
      text-align: center;
      input {
        border: 1px solid #eeeded;
        padding: 4px;
        &:disabled {
          background-color: #eeeded;
        }
      }
      span {
        font-family: Roboto;
        margin-bottom: 15px;
        display: block;
        color: #676767;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
  &__data {
    &-item {
      &:nth-child(2n - 1) {
        background: #f8f8f8;
      }
    }
  }
  .td {
    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 9%;
    }
    &:nth-child(4) {
      width: 9%;
    }
    &:nth-child(5) {
      width: 9%;
    }
    &:nth-child(6) {
      width: 9%;
    }
    &:nth-child(7) {
      width: 18%;
    }
    &:nth-child(8) {
      width: 18%;
    }
    &:nth-child(9) {
      width: 4%;
    }
  }
}
</style>
